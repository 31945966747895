import * as React from "react";
import Typography from "@mui/material/Typography";
import {Box, Button, Snackbar, Stack, TextField} from "@mui/material";
import {famtrailApi} from "../../services/FamtrailApi";
import {handleLogError} from "../../services/Helpers";
import {useTranslation} from "react-i18next";

function FeedbackContent(props) {

    const {t} = useTranslation();

    const [feedbackContent, setFeedbackContent] = React.useState('');

    const [feedbackSuccessfullySentAlertOpen, setFeedbackSuccessfullySentAlertOpen] = React.useState(false);

    const handleFeedbackSuccessfullySentAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFeedbackSuccessfullySentAlertOpen(false);
    };
    const handleInputChange = (value) => {
        setFeedbackContent(value);
    }

    const handleSendOnClick = async () => {
        const data = {feedbackText: feedbackContent};
        setFeedbackContent('');
        try {
            await famtrailApi.sendFeedback(props.user, data);
        } catch (error) {
            handleLogError(error)
        }
        setFeedbackSuccessfullySentAlertOpen(true);
    };

    return (
        <Box>
            <Typography variant="h5">{t('feedbackPage.title')}</Typography>
            <Typography paragraph mt={2}>
                {t('feedbackPage.description')}
            </Typography>
            {/*<Box>*/}
            {/*    <TextField*/}
            {/*        sx={{marginTop: '10px'}}*/}
            {/*        id="outlined-multiline-static"*/}
            {/*        fullWidth*/}
            {/*        multiline*/}
            {/*        rows={4}*/}
            {/*        //maxRows={20}*/}
            {/*        onChange = {(e) => {*/}
            {/*            handleInputChange(e.target.value);*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <Stack*/}
            {/*        direction="row"*/}
            {/*        mt={1}*/}
            {/*        spacing={{ xs: 2, sm: 2, md: 2 }}*/}
            {/*    >*/}
            {/*        <Button*/}
            {/*            type="supmit"*/}
            {/*            variant="contained"*/}
            {/*            disabled={feedbackContent.length <= 5}*/}
            {/*            size="medium"*/}
            {/*            onClick={handleSendOnClick}*/}
            {/*            sx={{ width: 'sx', textTransform: 'none', boxShadow: 'none'}}*/}
            {/*        >*/}
            {/*            {t('feedbackPage.sendButton')}*/}
            {/*        </Button>*/}
            {/*    </Stack>*/}
            {/*</Box>*/}
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={feedbackSuccessfullySentAlertOpen}
                    autoHideDuration={3000}
                    onClose={handleFeedbackSuccessfullySentAlertClose}
                    message={t('feedbackPage.toastSuccess')}
                    // action={action}
                />
            </Stack>
        </Box>
    );
}

export default FeedbackContent;