import * as React from "react";
import {
    Alert,
    Avatar, Badge,
    Box,
    Button,
    Container,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    Divider, InputAdornment, Link, OutlinedInput, Snackbar,
    Stack,
    Tab,
    TextField, Tooltip,
    Typography, useMediaQuery
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProfileBio from "./ProfileBio";
import ProfileGallery from "./ProfileGallery";
import MiniTree from "./MiniTree";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MuiAlert from '@mui/material/Alert';
import ListItemIcon from "@mui/material/ListItemIcon";
import AddFatherIcon from "./icons/AddFatherIcon";
import ListItemText from "@mui/material/ListItemText";
import AddMotherIcon from "./icons/AddMotherIcon";
import Drawer from "@mui/material/Drawer";
import AddSonIcon from "./icons/AddSonIcon";
import AddDaughterIcon from "./icons/AddDaughterIcon";
import AddPartnerIcon from "./icons/AddPartnerIcon";
import {famtrailApi} from "../../services/FamtrailApi";
import {useAuth} from "../context/AuthContext";
import {useEffect, useState} from "react";
import {handleLogError} from "../../services/Helpers";
import {theme} from "../../theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import EditIcon from "./icons/EditIcon";
import DeleteIcon from "./icons/DeleteIcon";
import { getLocaleDateString } from './../../Utils.js';
// https://github.com/iamkun/dayjs/tree/dev/src/locale
import "dayjs/locale/en";
import "dayjs/locale/bg";
import InviteIcon from "./icons/InviteIcon";
import AccessIcon from "./icons/AccessIcon";
import {ContentCopy} from "@mui/icons-material";
import BigViewIcon from "./icons/BigViewIcon";
import AccessSettingBox from "./AccessSettingBox";
import {LoadingButton} from "@mui/lab";
import BigSentIcon from "./icons/BigSentIcon";
import CookieConsent from "../CookieConsent";

function ProfileContent(props) {

    const { t, i18n } = useTranslation();

    // the id of the selected member
    const [selectedMember, setSelectedMember] = React.useState(props.selectedMember);
    const Auth = useAuth();
    const user = Auth.getUser();

    // other constants
    const startDate = new Date('1700-01-01');

    const parseDate = (dateStr) => {
        //1952-06-10 YYYY-MM-DD
        const parts = dateStr.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        const currentLanguage = i18n.language;
        const datePart = date.toLocaleString(currentLanguage).split(',');
        return datePart[0];
    }

    // selected member object
    const [userData, setUserData] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getMember(user, selectedMember);
                setUserData(response.data);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])


    const [miniTreeData, setMiniTreeData] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getMiniTree(user, selectedMember);
                setMiniTreeData(response.data);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    const [bioData, setBioData] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getBio(user, selectedMember);
                setBioData(response.data);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    const [photosData, setPhotosData] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getPhotos(user, selectedMember);
                setPhotosData(response.data);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    const [invitationLink, setInvitationLink] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getInvitationLink(user, selectedMember);
                setInvitationLink(response.data.invitationLink);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    const [accessRightsData, setAccessRightsData] = React.useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getAccessList(user, selectedMember);
                setAccessRightsData(response.data);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    const [accessRequest, setAccessRequest] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getAccessRequest(user, selectedMember);
                setAccessRequest(response.data);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    const height = 234;
    const [width, setWidth] = React.useState(300);

    const handleSetSelected = async (id) => {
        setSelectedMember(id);
        const memberResp = await famtrailApi.getMember(user, id);
        const miniTreeResp = await famtrailApi.getMiniTree(user, id);
        const photosResp = await famtrailApi.getPhotos(user, id);
        const bioResp = await famtrailApi.getBio(user, id);
        const accessRequestResp = await famtrailApi.getAccessRequest(user, id);
        if (memberResp.data != null && memberResp.data.accessLevel !== "PRIVATE") {
            const invitationLinkResp = await famtrailApi.getInvitationLink(user, id);
            setInvitationLink(invitationLinkResp.data.invitationLink);
        }
        if (memberResp.data != null && memberResp.data.accessLevel === "EDIT") {
            const accessResponse = await famtrailApi.getAccessList(user, id);
            setAccessRightsData(accessResponse.data);
        }
        setUserData(memberResp.data);
        setMiniTreeData(miniTreeResp.data);
        setPhotosData(photosResp.data);
        setBioData(bioResp.data);
        setAccessRequest(accessRequestResp.data);

        // hide loading of "Invite" button after everything is pulled from server
        setCreateInvitationLinkLoading(false);
    }


    const navigate = useNavigate();
    const [tabValue, setTabValue] = React.useState("0");
    // this is the menu that pops up from bottom and allows to add members
    const [actionBarOpen, setActionBarOpen] = React.useState(false);
    // this is the menu with EDIT, DELETE etc.
    const [actionBarManageMemberOpen, setActionBarManageMemberOpen] = React.useState(false);
    const [addMemberDrawerOpen, setAddMemberDrawerOpen] = React.useState(false);
    const [editMemberDrawerOpen, setEditMemberDrawerOpen] = React.useState(false);
    const [inviteMemberDrawerOpen, setInviteMemberDrawerOpen] = React.useState(false);
    const [accessSettingsDrawerOpen, setAccessSettingsDrawerOpen] = React.useState(false);
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = React.useState(false);
    const [deleteRejectionDialogOpen, setDeleteRejectionDialogOpen] = React.useState(false);
    const [isFirstNameMissing, setIsFirstNameMissing] = React.useState(true);
    const [isFirstNameMissingEdit, setIsFirstNameMissingEdit] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
    });
    const [memberSuccessfullyAddedAlertOpen, setMemberSuccessfullyAddedAlertOpen] = React.useState(false);
    const [memberSuccessfullyDeletedAlertOpen, setMemberSuccessfullyDeletedAlertOpen] = React.useState(false);
    const [memberSuccessfullyUpdatedAlertOpen, setMemberSuccessfullyUpdatedAlertOpen] = React.useState(false);
    const [linkCopiedAlertOpen, setLinkCopiedAlertOpen] = React.useState(false);
    const handleMemberSuccessfullyAddedAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMemberSuccessfullyAddedAlertOpen(false);
    };
    const handleMemberSuccessfullyDeletedAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMemberSuccessfullyDeletedAlertOpen(false);
    };
    const handleMemberSuccessfullyUpdatedAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMemberSuccessfullyUpdatedAlertOpen(false);
    };
    const handleLinkCopiedAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setLinkCopiedAlertOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const closeActionBar = () => {
        setActionBarOpen(false);
    }

    const closeActionBarManageMember = () => {
        setActionBarManageMemberOpen(false);
    }

    const closeAddMemberDrawer = () => {
        setAddMemberDrawerOpen(false);
    }

    const handleDeleteConfirmationDialogClickOpen = () => {
        if (userData != null && userData.deleteRejection != null ) {
            setDeleteRejectionDialogOpen(true);
        } else {
            setDeleteConfirmationDialogOpen(true);
        }
    };

    const handleDeleteConfirmationDialogClickClose = () => {
        setDeleteConfirmationDialogOpen(false);
    };

    const handleDeleteRejectionDialogClickClose = () => {
        setDeleteRejectionDialogOpen(false);
    };

    const handleClickCopyLink = async (link) => {
        await navigator.clipboard.writeText(link);
        setLinkCopiedAlertOpen(true);
    }

    const editMemberClicked = () => {
        handleInputChange("firstName", userData.firstName == null ? "" : userData.firstName);
        setIsFirstNameMissingEdit(userData.firstName == null || userData.firstName.length < 1);
        handleInputChange("middleName", userData.middleName == null ? "" : userData.middleName);
        handleInputChange("lastName", userData.lastName == null ? "" : userData.lastName);
        handleBirthDateChange(userData == null ? null : dayjs(userData.birthDate));
        handleDeathDateChange(userData == null ? null : dayjs(userData.deathDate));
        setEditMemberDrawerOpen(true);
    }

    const accessSettingsClicked = () => {
        setAccessSettingsDrawerOpen(true);
    }

    const inviteMemberClicked = () => {
        setInviteMemberDrawerOpen(true);
    }

    const closeEditMemberDrawer = () => {
        setEditMemberDrawerOpen(false);
    }

    const closeInviteMemberDrawer = () => {
        setInviteMemberDrawerOpen(false);
    }

    const closeAccessSettingsDrawer = () => {
        setAccessSettingsDrawerOpen(false);
    }

    const addFatherClicked = () => {
        setType(t('profile.father'));
        setAddMemberDrawerOpen(true);
    }

    const addMotherClicked = () => {
        setType(t('profile.mother'));
        setAddMemberDrawerOpen(true);
    }

    const addSonClicked = () => {
        setType(t('profile.son'));
        setAddMemberDrawerOpen(true);
    }

    const addDaughterClicked = () => {
        setType(t('profile.daughter'));
        setAddMemberDrawerOpen(true);
    }

    const addPartnerClicked = () => {
        setType(t('profile.partner'));
        setAddMemberDrawerOpen(true);
    }

    const[type, setType] = useState('');
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [deathDate, setDeathDate] = useState('')


    const handleInputChange = (name, value) => {
        if (name === 'firstName') {
            if (value.length < 1) {
                setIsFirstNameMissing(true);
                setIsFirstNameMissingEdit(true);
            } else {
                setIsFirstNameMissing(false);
                setIsFirstNameMissingEdit(false);
            }
            setFirstName(value)
        } else if (name === 'middleName') {
            setMiddleName(value)
        } else if (name === 'lastName') {
            setLastName(value)
        }
    }

    const handleBirthDateChange = (date) => {
        setBirthDate(date);
    };
    const handleDeathDateChange = (date) => {
        setDeathDate(date);
    };

    const handleAddMemberSubmit = async (e) => {
        e.preventDefault();
        setAddMemberDrawerOpen(false);

        let striprdBd = null;
        if (birthDate == null || birthDate === '' || isNaN(birthDate)) {
            striprdBd = null;
        } else {
            const bdate = new Date(birthDate);
            const offset = bdate.getTimezoneOffset();
            striprdBd = new Date(bdate.getTime() - (offset*60*1000));
            striprdBd = striprdBd.toISOString().split('T')[0];
        }

        let striprdDd = null;
        if (deathDate == null || deathDate === '' || isNaN(deathDate)) {
            striprdDd = null;
        } else {
            const ddate = new Date(deathDate);
            const offset = ddate.getTimezoneOffset();
            striprdDd = new Date(ddate.getTime() - (offset*60*1000));
            striprdDd = striprdDd.toISOString().split('T')[0];
        }

        const data = {firstName, middleName: middleName, lastName: lastName, birthDate: striprdBd, deathDate: striprdDd};
        switch (type) {
            case t('profile.father'):
                await famtrailApi.addFather(user, selectedMember, data);
                break;
            case t('profile.mother'):
                await famtrailApi.addMother(user, selectedMember, data);
                break;
            case t('profile.son'):
                await famtrailApi.addSon(user, selectedMember, data);
                break;
            case t('profile.daughter'):
                await famtrailApi.addDaughter(user, selectedMember, data);
                break;
            case t('profile.partner'):
                await famtrailApi.addPartner(user, selectedMember, data);
                break;
            default:
                console.log(`unknown type: ${type}.`);
        }
        const response = await famtrailApi.getMiniTree(user, selectedMember);
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setBirthDate('');
        setDeathDate('');
        setIsFirstNameMissing(true);
        setMiniTreeData(response.data);
        props.handleNavbarRefresh();
        setMemberSuccessfullyAddedAlertOpen(true);
    }

    const handleEditMemberSubmit = async (e) => {
        e.preventDefault();
        setEditMemberDrawerOpen(false);

        let striprdBd = null;
        if (birthDate == null || birthDate === '' || isNaN(birthDate)) {
            striprdBd = null;
        } else {
            const bdate = new Date(birthDate);
            const offset = bdate.getTimezoneOffset();
            striprdBd = new Date(bdate.getTime() - (offset*60*1000));
            striprdBd = striprdBd.toISOString().split('T')[0];
        }

        let striprdDd = null;
        if (deathDate == null || deathDate === '' || isNaN(deathDate)) {
            striprdDd = null;
        } else {
            const ddate = new Date(deathDate);
            const offset = ddate.getTimezoneOffset();
            striprdDd = new Date(ddate.getTime() - (offset*60*1000));
            striprdDd = striprdDd.toISOString().split('T')[0];
        }

        const data = {firstName, middleName: middleName, lastName: lastName, birthDate: striprdBd, deathDate: striprdDd};

        await famtrailApi.updateMember(user, selectedMember, data);
        //await famtrailApi.getMiniTree(user, selectedMember);
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setBirthDate('');
        setDeathDate('');
        //setMiniTreeData(response.data);
        handleSetSelected(selectedMember);
        props.handleNavbarRefresh();
        setMemberSuccessfullyUpdatedAlertOpen(true);
    }

    const handleDeleteMember = async () => {
        setDeleteConfirmationDialogOpen(false);
        try {
            const response = await famtrailApi.deleteMember(user, selectedMember);
            handleSetSelected(response.data.id);
            setMemberSuccessfullyDeletedAlertOpen(true);
        } catch (error) {
            handleLogError(error);
        } finally {

        }
        props.handleNavbarRefresh();
    }

    const [createInvitationLinkLoading, setCreateInvitationLinkLoading] = React.useState(false);
    const handleCreateInvitationLink = async () => {
        setCreateInvitationLinkLoading(true);
        try {
            const response = await famtrailApi.createInvitationLink(user, selectedMember);
            setInvitationLink(response.data.invitationLink);
            handleSetSelected(selectedMember);
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    }

    const handleRequestAccessOnClick = async () => {
        try {
            await famtrailApi.createAccessRequest(user, selectedMember);
            setAccessRequest({found: true, grantedOn: null});
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    }

    const actionBarList = (
        <Box
            role="presentation"
            onClick={() => setActionBarOpen(false)}
            onKeyDown={() => setActionBarOpen(false)}
        >
            <List>
                {miniTreeData != null && miniTreeData.father == null &&
                    <ListItem key="add-father" disablePadding
                              onClick={() => addFatherClicked()}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <AddFatherIcon style={{height: 20}}  />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.addFather')} />
                        </ListItemButton>
                    </ListItem>
                }
                {miniTreeData != null && miniTreeData.mother == null &&
                    <ListItem key="add-mother" disablePadding
                              onClick={() => addMotherClicked()}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <AddMotherIcon style={{height: 20}}  />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.addMother')} />
                        </ListItemButton>
                    </ListItem>
                }
                <ListItem key="add-son" disablePadding
                          onClick={() => addSonClicked()}
                >
                    <ListItemButton>
                        <ListItemIcon>
                            <AddSonIcon style={{height: 20}}  />
                        </ListItemIcon>
                        <ListItemText primary={t('profile.addSon')} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="add-daughter" disablePadding
                          onClick={() => addDaughterClicked()}
                >
                    <ListItemButton>
                        <ListItemIcon>
                            <AddDaughterIcon style={{height: 20}}  />
                        </ListItemIcon>
                        <ListItemText primary={t('profile.addDaughter')} />
                    </ListItemButton>
                </ListItem>
                {miniTreeData != null && miniTreeData.partner == null &&
                    <ListItem key="add-partner" disablePadding
                              onClick={() => addPartnerClicked()}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <AddPartnerIcon style={{height: 20}}  />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.addPartner')} />
                        </ListItemButton>
                    </ListItem>
                }
            </List>
            <Divider />
        </Box>
    );

    const actionBarManageMemberList = (
        <Box
            role="presentation"
            onClick={() => setActionBarManageMemberOpen(false)}
            onKeyDown={() => setActionBarManageMemberOpen(false)}
        >
            <List>
                {userData != null && userData.accessLevel === "EDIT" &&
                    <ListItem key="edit-member" disablePadding
                              onClick={() => editMemberClicked()}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <EditIcon style={{height: 20}}  />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.edit')} />
                        </ListItemButton>
                    </ListItem>
                }
                {userData != null && userData.famtrailUser === false && userData.accessLevel !== "PRIVATE" &&
                    <ListItem key="invite-member" disablePadding
                              onClick={() => inviteMemberClicked()}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <InviteIcon style={{height: 20}}  />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.invite')} />
                        </ListItemButton>
                    </ListItem>
                }
                {userData != null && userData.accessLevel === "EDIT" &&
                    <ListItem key="access-settings" disablePadding
                              onClick={() => accessSettingsClicked()}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <AccessIcon style={{height: 20}}  />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.profileAccessSettings')} />
                        </ListItemButton>
                    </ListItem>
                }
                {userData != null && userData.accessLevel === "EDIT" &&
                    <ListItem key="delete-memmber" disablePadding
                              onClick={() => handleDeleteConfirmationDialogClickOpen()}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <DeleteIcon style={{height: 20}}  />
                            </ListItemIcon>
                            <ListItemText primary={t('profile.delete')} />
                        </ListItemButton>
                    </ListItem>
                }
            </List>
            <Divider />
        </Box>
    );

    const addMemberDrawer = (
        <Box
            role="presentation"
            onClick={() => setActionBarOpen(false)}
            onKeyDown={() => setActionBarOpen(false)}
        >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" onSubmit={handleAddMemberSubmit} noValidate sx={{ mt: 1 }}>
                        <Typography variant='h6' mb={1}>
                            {t('profile.add')}
                            {type}
                            {t('profile.of')}
                            {userData != null && userData.firstName != null && userData.firstName + " "}
                            {userData != null && userData.lastName != null && userData.lastName}
                        </Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="first-name"
                            label={t('profile.firstName')}
                            required={true}
                            name="firstName"
                            autoFocus
                            inputProps={{ maxLength: 255 }}
                            //value={username}
                            onChange = {(e) => {
                                handleInputChange(e.target.name, e.target.value);
                            }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="middle-name"
                            label={t('profile.middleName')}
                            name="middleName"
                            //value={username}
                            inputProps={{ maxLength: 255 }}
                            onChange = {(e) => {
                                handleInputChange(e.target.name, e.target.value);
                            }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="last-name"
                            label={t('profile.lastName')}
                            name="lastName"
                            //value={username}
                            inputProps={{ maxLength: 255 }}
                            onChange = {(e) => {
                                handleInputChange(e.target.name, e.target.value);
                            }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n?.language}>
                            <DemoContainer components={['DatePicker']}>
                                <Box
                                    sx={{width: '100%', paddingY: '8px'}}
                                >
                                    <DatePicker
                                        label={t('profile.dateOfBirth')}
                                        minDate={dayjs(startDate)}
                                        maxDate={dayjs(new Date())}
                                        format={getLocaleDateString(i18n.language)}
                                        sx={{width: '100%'}}
                                        //openTo="year"
                                        clearable
                                        id="birth-date"
                                        name="birthDate"
                                        onChange = {handleBirthDateChange}
                                    />
                                </Box>
                            </DemoContainer>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n?.language}>
                            <DemoContainer components={['DatePicker']}>
                                <Box
                                    sx={{width: '100%', paddingY: '8px'}}
                                >
                                    <DatePicker
                                        label={t('profile.dateOfDeath')}
                                        minDate={dayjs(startDate)}
                                        maxDate={dayjs(new Date())}
                                        format={getLocaleDateString(i18n.language)}
                                        sx={{width: '100%'}}
                                        //openTo="year"
                                        clearable
                                        id="death-date"
                                        name="deathDate"
                                        onChange = {handleDeathDateChange}
                                    />
                                </Box>
                            </DemoContainer>
                        </LocalizationProvider>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={isFirstNameMissing}
                            size="large"
                            sx={{ mt: 3, mb: 1, textTransform: 'none', boxShadow: 'none'}}
                        >
                            {t('profile.buttonSave')}
                        </Button>
                        <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{ mt: 1, mb: 2, textTransform: 'none', boxShadow: 'none'}}
                            onClick={() => setAddMemberDrawerOpen(false)}
                        >
                            {t('profile.buttonCancel')}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    );

    const editMemberDrawer = (
        <Box
            role="presentation"
            onClick={() => setActionBarManageMemberOpen(false)}
            onKeyDown={() => setActionBarManageMemberOpen(false)}
        >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" onSubmit={handleEditMemberSubmit} noValidate sx={{ mt: 1 }}>
                        <Typography variant='h6' mb={1}>
                            {t('profile.editSpace')}
                            {userData != null && userData.firstName != null && userData.firstName + " "}
                            {userData != null && userData.lastName != null && userData.lastName}
                        </Typography>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            id="first-name"
                            label={t('profile.firstName')}
                            name="firstName"
                            inputProps={{ maxLength: 255 }}
                            //autoFocus
                            defaultValue={userData != null ? userData.firstName == null ? "" : userData.firstName : ""}
                            onChange = {(e) => {
                                handleInputChange(e.target.name, e.target.value);
                            }}
                        />
                        {isFirstNameMissingEdit && <Alert severity="error" variant="filled">{'First name is required'}</Alert>}
                        <TextField
                            margin="normal"
                            fullWidth
                            id="middle-name"
                            label={t('profile.middleName')}
                            name="middleName"
                            inputProps={{ maxLength: 255 }}
                            defaultValue={userData != null ? userData.middleName == null ? "" : userData.middleName : ""}
                            onChange = {(e) => {
                                handleInputChange(e.target.name, e.target.value);
                            }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="last-name"
                            label={t('profile.lastName')}
                            name="lastName"
                            inputProps={{ maxLength: 255 }}
                            defaultValue={userData != null ? userData.lastName == null ? "" : userData.lastName : ""}
                            onChange = {(e) => {
                                handleInputChange(e.target.name, e.target.value);
                            }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n?.language}>
                            <DemoContainer components={['DatePicker']}>
                                <Box
                                    sx={{width: '100%', paddingY: '8px'}}
                                >
                                    <DatePicker
                                        label={t('profile.dateOfBirth')}
                                        minDate={dayjs(startDate)}
                                        maxDate={dayjs(new Date())}
                                        format={getLocaleDateString(i18n.language)}
                                        sx={{width: '100%'}}
                                        //openTo="year"
                                        clearable
                                        id="birth-date"
                                        name="birthDate"
                                        defaultValue={userData != null ? userData.birthDate == null ? null : dayjs(userData.birthDate) : null}
                                        onChange = {handleBirthDateChange}
                                    />
                                </Box>
                            </DemoContainer>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n?.language}>
                            <DemoContainer components={['DatePicker']}>
                                <Box
                                    sx={{width: '100%', paddingY: '8px'}}
                                >
                                    <DatePicker
                                        label={t('profile.dateOfDeath')}
                                        minDate={dayjs(startDate)}
                                        maxDate={dayjs(new Date())}
                                        format={getLocaleDateString(i18n.language)}
                                        sx={{width: '100%'}}
                                        //openTo="year"
                                        clearable
                                        id="death-date"
                                        name="deathDate"
                                        defaultValue={userData != null ? userData.deathDate == null ? null : dayjs(userData.deathDate) : null}
                                        onChange = {handleDeathDateChange}
                                    />
                                </Box>
                            </DemoContainer>
                        </LocalizationProvider>
                        <Button
                            type="submit"
                            fullWidth
                            disabled={isFirstNameMissingEdit}
                            variant="contained"
                            size="large"
                            sx={{ mt: 3, mb: 1, textTransform: 'none', boxShadow: 'none'}}
                        >
                            {t('profile.buttonSave')}
                        </Button>
                        <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{ mt: 1, mb: 2, textTransform: 'none', boxShadow: 'none'}}
                            onClick={() => setEditMemberDrawerOpen(false)}
                        >
                            {t('profile.buttonCancel')}
                        </Button>

                    </Box>
                </Box>
            </Container>
        </Box>
    );

    const inviteMemberDrawer = (
        <Box
            role="presentation"
            onClick={() => setActionBarManageMemberOpen(false)}
            onKeyDown={() => setActionBarManageMemberOpen(false)}
        >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" onSubmit={handleEditMemberSubmit} noValidate sx={{ mt: 1 }}>
                        <Typography variant='h6' mb={1}>
                            {t('profile.inviteSpace')}
                            {userData != null && userData.firstName != null && userData.firstName}
                            {t('profile.toJoin')}
                        </Typography>
                        {userData != null && userData.invitationPending === false &&
                            <Box>
                                <Typography mt={2} mb={3}>
                                    {t('profile.inviteText')}
                                </Typography>
                                <LoadingButton
                                        type="button"
                                        fullWidth
                                        loading={createInvitationLinkLoading}
                                        variant="contained"
                                        size="large"
                                        sx={{ mt: 3, mb: 1, textTransform: 'none', boxShadow: 'none'}}
                                        onClick={() => handleCreateInvitationLink()}
                                >
                                    {t('profile.inviteButton')}
                                </LoadingButton>
                            </Box>
                        }
                        {userData != null && userData.invitationPending === true &&
                            <Box>
                                <Typography mt={2} mb={3}>
                                    {t('profile.copyAndSend')}
                                    {userData != null && userData.firstName != null && userData.firstName}
                                    {t('profile.emailOrMessenger')}
                                </Typography>
                                <Typography sx={{ fontWeight: 'medium' }}>
                                    {t('profile.invitationLink')}
                                </Typography>
                                <OutlinedInput
                                    margin="none"
                                    fullWidth
                                    id="invitation-link"
                                    type="text"
                                    defaultValue={invitationLink + "&lang=" + i18n.language}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickCopyLink(invitationLink + "&lang=" + i18n.language)}
                                                onMouseDown={() => handleClickCopyLink(invitationLink + "&lang=" + i18n.language)}
                                                edge="end"
                                            >
                                                <ContentCopy />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />

                            </Box>
                        }
                        <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{ mt: 1, mb: 2, textTransform: 'none', boxShadow: 'none'}}
                            onClick={() => setInviteMemberDrawerOpen(false)}
                        >
                            {t('profile.buttonClose')}
                        </Button>

                    </Box>
                </Box>
            </Container>
        </Box>
    );

    const accessSettingsDrawer = (
        <Box
            role="presentation"
            onClick={() => setActionBarManageMemberOpen(false)}
            onKeyDown={() => setActionBarManageMemberOpen(false)}
        >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography variant='h6' mb={1}>
                            {t('profile.profileAccessSettings')}
                        </Typography>
                        <Typography sx={{ mb: 4}}>
                            {t('profile.profileAccessText')}
                        </Typography>

                        {accessRightsData.length === 0 &&
                            <Typography>
                                {t('profile.noFamilyMembers')}
                            </Typography>
                        }

                        {accessRightsData.map((item) => (
                            <AccessSettingBox key={item.memberId} userMemberId={item.memberId} memberId={selectedMember} user={user} gender={item.gender} initials={item.initials} firstName={item.firstName} lastName={item.lastName} profilePicture={item.profilePicture} accessLevel={item.accessLevel} handleSetSelected={handleSetSelected} />
                        ))}

                        <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{ mt: 5, mb: 2, textTransform: 'none', boxShadow: 'none'}}
                            onClick={() => setAccessSettingsDrawerOpen(false)}
                        >
                            {t('profile.buttonClose')}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    );

    return (
        <Box>
            <CookieConsent />
            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'column', xl: 'row' }}
                spacing={{ xs: 2, sm: 2, md: 2 }}
            >
                <Box flex={4} p={0} minWidth={'xs'}>
                    <Stack direction={'column'} spacing={1}>
                        <Stack direction={'row'} spacing={2}>
                            <Box flex={2} p={0} maxWidth={'64px'} alignSelf="center">
                                {userData != null && userData.profilePicture != null && userData.invitationPending === true &&
                                    <Tooltip title={t('profile.tooltipInvitationPending')}>
                                        <Badge overlap="circular" badgeContent="~"
                                               sx={{
                                                   "& .MuiBadge-badge": {
                                                       color: "white",
                                                       backgroundColor: "#707070"
                                                   }
                                               }}
                                        >
                                            <Avatar
                                                alt={userData.firstName}
                                                sx={{ width: 64, height: 64 }}
                                                src={userData.profilePicture} />
                                        </Badge>
                                    </Tooltip>
                                }
                                {userData != null && userData.profilePicture != null && userData.famtrailUser === true &&
                                    <Tooltip title={t('profile.tooltipRegistered')}>
                                        <Badge overlap="circular" badgeContent="✓" color="primary">
                                            <Avatar
                                                alt={userData.firstName}
                                                sx={{ width: 64, height: 64 }}
                                                src={userData.profilePicture} />
                                        </Badge>
                                    </Tooltip>
                                }
                                {userData != null && userData.profilePicture != null && userData.famtrailUser === false && userData.invitationPending === false &&
                                    <Avatar
                                        alt={userData.firstName}
                                        sx={{ width: 64, height: 64 }}
                                        src={userData.profilePicture} />
                                }
                                {userData != null && userData.profilePicture == null && userData.invitationPending === true &&
                                    <Tooltip title={t('profile.tooltipInvitationPending')}>
                                        <Badge overlap="circular" badgeContent="~"
                                               sx={{
                                                   "& .MuiBadge-badge": {
                                                       color: "white",
                                                       backgroundColor: "#707070"
                                                   }
                                               }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 64,
                                                    height: 64,
                                                    ...(userData.gender === 'MALE' && {
                                                        bgcolor: '#2681A3',
                                                    }),
                                                    ...(userData.gender !== 'MALE' && {
                                                        bgcolor: '#D8416F',
                                                    })
                                                }}
                                            >
                                                {userData.initials}
                                            </Avatar>
                                        </Badge>
                                    </Tooltip>
                                }
                                {userData != null && userData.profilePicture == null && userData.famtrailUser === true &&
                                    <Tooltip title={t('profile.tooltipRegistered')}>
                                        <Badge overlap="circular" badgeContent="✓" color="primary">
                                            <Avatar
                                                sx={{
                                                    width: 64,
                                                    height: 64,
                                                    ...(userData.gender === 'MALE' && {
                                                        bgcolor: '#2681A3',
                                                    }),
                                                    ...(userData.gender !== 'MALE' && {
                                                        bgcolor: '#D8416F',
                                                    })
                                                }}
                                            >
                                                {userData.initials}
                                            </Avatar>
                                        </Badge>
                                    </Tooltip>
                                }
                                {userData != null && userData.profilePicture == null && userData.famtrailUser === false && userData.invitationPending === false &&
                                    <Avatar
                                        sx={{
                                            width: 64,
                                            height: 64,
                                            ...(userData.gender === 'MALE' && {
                                                bgcolor: '#2681A3',
                                            }),
                                            ...(userData.gender !== 'MALE' && {
                                                bgcolor: '#D8416F',
                                            })
                                        }}
                                    >
                                        {userData.initials}
                                    </Avatar>
                                }
                            </Box>
                            <Box flex={7} p={0} alignSelf="center">
                                <Stack direction={'column'} spacing={0}>
                                    <Typography variant="h6" lineHeight={'20px'}>
                                        {
                                            userData != null && userData.firstName != null && userData.firstName + " "
                                        }
                                        {
                                            userData != null && userData.middleName != null && userData.middleName + " "
                                        }
                                        {
                                            userData != null && userData.lastName != null && userData.lastName
                                        }
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Typography fontSize={12} mt={'4px'}>
                                            {userData != null && userData.birthDate != null && parseDate(userData.birthDate) + " "}
                                            {userData != null && userData.deathDate != null && "- "}
                                            {userData != null && userData.deathDate != null && parseDate(userData.deathDate)}
                                        </Typography>
                                    </LocalizationProvider>
                                    <Typography fontSize={12}>{userData != null && userData.age !== 0 && userData.age + t('profile.yrOld')}</Typography>
                                </Stack>
                            </Box>
                            {userData != null && userData.accessLevel !== "PRIVATE" &&
                                <Box flex={3} p={0} display={'flex'} justifyContent={'right'} alignSelf="center">
                                    <Tooltip title={t('profile.tooltipManageMember')}>
                                        <IconButton
                                            size="large"
                                            color="inherit"
                                            onClick={() => setActionBarManageMemberOpen(true)}
                                        >
                                            <MoreVertIcon style={{width: 25}}  />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                        </Stack>
                        {userData != null && userData.accessLevel === "EDIT" &&
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ width: 'sx', mb: 2, textTransform: 'none', boxShadow: 'none'}}
                                onClick={() => setActionBarOpen(true)}
                            >
                                {t('profile.addFamilyMemberButton')}
                            </Button>
                        }
                    </Stack>
                </Box>
                <Box flex={4} p={0}
                     display="flex"
                     justifyContent={'center'}
                     height={'76px'}
                     width={'xs'}
                >

                </Box>
                <Box flex={4} p={0} display={'flex'}
                     minHeight={height}
                     minWidth={'xs'}
                     justifyContent={'center'}
                     alignItems={'center'}
                     id="canvasDiv"
                >
                        <MiniTree width={width} height={height} data={miniTreeData} handleSetSelected={handleSetSelected} />
                </Box>
            </Stack>
            {userData != null && userData.accessLevel !== "PRIVATE" &&
                <TabContext value={tabValue}>
                    <Box sx={{ maxWidth: '87vw', borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="additional member info"
                        >
                            <Tab label={t('profile.biography')} sx={{color: '#0F0F0F'}} value="0" />
                            <Tab label={t('profile.photos')} sx={{color: '#0F0F0F'}} value="1" />
                            {/*<Tab label="Events" sx={{color: '#0F0F0F'}} value="2" />*/}
                            {/*<Tab label="Facts" sx={{color: '#0F0F0F'}} value="3" />*/}
                            {/*<Tab label="Contact" sx={{color: '#0F0F0F'}} value="4" />*/}
                            {/*<Tab label="Comments" sx={{color: '#0F0F0F'}} value="5" />*/}
                        </TabList>
                    </Box>
                    <TabPanel value="0" sx={{width:'100%', padding:'0px!important'}}><ProfileBio user={user} selectedMember={selectedMember} data={bioData} userData={userData} /></TabPanel>
                    <TabPanel value="1" sx={{width:'100%', padding:'0px!important'}}><ProfileGallery selectedMember={selectedMember} user={user} data={photosData} handleSetSelected={handleSetSelected} handleNavbarRefresh={props.handleNavbarRefresh} userData={userData} /></TabPanel>
                    {/*<TabPanel value="2">Not available in beta version</TabPanel>*/}
                    {/*<TabPanel value="3">Not available in beta version</TabPanel>*/}
                    {/*<TabPanel value="4">Not available in beta version</TabPanel>*/}
                    {/*<TabPanel value="5">Not available in beta version</TabPanel>*/}
                </TabContext>
            }
            {userData != null && userData.accessLevel === "PRIVATE" &&
                <Box>
                    <Divider style={{marginTop: '46px'}} />
                    {accessRequest != null && accessRequest.found === false &&
                        <Box>
                            <Box flex={3} p={0} display={'flex'} justifyContent={'center'} alignSelf="center">
                                <Typography paragraph mt={2}>
                                    {t('profile.requestViewPermissions')}
                                    {userData != null && userData.createdByName != null && " " + userData.createdByName}
                                </Typography>
                            </Box>
                            <Box flex={3} p={0} display={'flex'} justifyContent={'center'} alignSelf="center">
                                <Tooltip title={t('profile.requestAccessTooltip')}>
                                    <IconButton
                                        size="large"
                                        color="inherit"
                                        onClick={() => handleRequestAccessOnClick()}
                                    >
                                        <BigViewIcon style={{width: 40}}  />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    }
                    {accessRequest != null && accessRequest.found === true &&
                        <Box>
                            <Box flex={3} p={0} display={'flex'} justifyContent={'center'} alignSelf="center">
                                <Typography paragraph mt={2}>
                                    {t('profile.accessRequestSent')}
                                </Typography>
                            </Box>
                            <Box flex={3} p={0} display={'flex'} justifyContent={'center'} alignSelf="center">
                                <BigSentIcon style={{width: 40}}  />
                            </Box>
                        </Box>
                    }
                </Box>
            }
            <Drawer
                variant="temporary"
                anchor={"bottom"}
                open={actionBarOpen}
                onClose={closeActionBar}
            >
                {actionBarList}
            </Drawer>
            <Drawer
                variant="temporary"
                anchor={"bottom"}
                open={actionBarManageMemberOpen}
                onClose={closeActionBarManageMember}
            >
                {actionBarManageMemberList}
            </Drawer>
            <Drawer
                variant="temporary"
                anchor={"right"}
                open={addMemberDrawerOpen}
                onClose={closeAddMemberDrawer}
            >
                {addMemberDrawer}
            </Drawer>
            <Drawer
                variant="temporary"
                anchor={"right"}
                open={editMemberDrawerOpen}
                onClose={closeEditMemberDrawer}
            >
                {editMemberDrawer}
            </Drawer>
            <Drawer
                variant="temporary"
                anchor={"right"}
                open={inviteMemberDrawerOpen}
                onClose={closeInviteMemberDrawer}
            >
                {inviteMemberDrawer}
            </Drawer>
            <Drawer
                variant="temporary"
                anchor={"right"}
                open={accessSettingsDrawerOpen}
                onClose={closeAccessSettingsDrawer}
            >
                {accessSettingsDrawer}
            </Drawer>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={memberSuccessfullyAddedAlertOpen}
                    autoHideDuration={3000}
                    onClose={handleMemberSuccessfullyAddedAlertClose}
                    message={t('profile.familyMemberAdded')}
                    // action={action}
                />
            </Stack>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={memberSuccessfullyDeletedAlertOpen}
                    autoHideDuration={3000}
                    onClose={handleMemberSuccessfullyDeletedAlertClose}
                    message={t('profile.familyMemberDeleted')}
                    // action={action}
                />
            </Stack>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={memberSuccessfullyUpdatedAlertOpen}
                    autoHideDuration={3000}
                    onClose={handleMemberSuccessfullyUpdatedAlertClose}
                    message={t('profile.familyMemberUpdated')}
                    // action={action}
                />
            </Stack>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={linkCopiedAlertOpen}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    onClose={handleLinkCopiedAlertClose}
                    message={t('profile.linkCopied')}
                />
            </Stack>
            <Dialog
                open={deleteConfirmationDialogOpen}
                // fullScreen={fullScreen}
                onClose={handleDeleteConfirmationDialogClickClose}
                aria-labelledby="confirm-delete-alert-dialog-title"
                aria-describedby="confirm-delete-alert-dialog-description"
            >
                <DialogTitle id="confirm-delete-alert-dialog-title">
                    {t('profile.deleteMemberTitle')}
                    {userData != null && userData.firstName != null && userData.firstName + " "}
                    {userData != null && userData.lastName != null && userData.lastName}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-delete-alert-dialog-description">
                        {t('profile.areYouSureDelete')}
                        {userData != null && userData.firstName != null && userData.firstName + " "}
                        {userData != null && userData.lastName != null && userData.lastName + " "}
                        {t('profile.allDataConnected')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteConfirmationDialogClickClose}>
                        {t('profile.cancel')}
                    </Button>
                    <Button onClick={handleDeleteMember} autoFocus>
                        {t('profile.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteRejectionDialogOpen}
                // fullScreen={fullScreen}
                onClose={handleDeleteRejectionDialogClickClose}
                aria-labelledby="confirm-delete-rejection-alert-dialog-title"
                aria-describedby="confirm-delete-rejection-alert-dialog-description"
            >
                <DialogTitle id="confirm-delete-rejection-alert-dialog-title">
                    {t('profile.deleteMemberTitle')}
                    {userData != null && userData.firstName != null && userData.firstName + " "}
                    {userData != null && userData.lastName != null && userData.lastName}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-delete-rejection-alert-dialog-description">
                        {userData != null && userData.deleteRejection === "itsYou" &&
                            t('profile.ownProfile')
                        }
                        {userData != null && userData.deleteRejection === "registeredUser" &&
                            t('profile.deleteRegistered')
                        }
                        {userData != null && userData.deleteRejection === "invitationPending" &&
                            t('profile.deleteInvited')
                        }
                        {userData != null && userData.deleteRejection === "noPermission" &&
                            t('profile.noDeletePermissions')
                        }
                        {userData != null && userData.deleteRejection === "connections" &&
                            t('profile.deleteConnected')
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteRejectionDialogClickClose}>
                        {t('profile.buttonClose')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default ProfileContent;