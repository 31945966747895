import React, {useEffect} from 'react'
import {Box, Button, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useSearchParams} from "react-router-dom";
import {famtrailApi} from "../services/FamtrailApi";
import {handleLogError} from "../services/Helpers";
import {useTranslation} from "react-i18next";

const MailVerified = () => {

    const [searchParams] = useSearchParams();

    const {t} = useTranslation();

    const [verificationInfo, setVerificationInfo] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const data = {code: searchParams.get("c")};
                console.log(data);
                const response = await famtrailApi.verify(data);
                setVerificationInfo(response.data);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    return (
        <Container component="main" maxWidth="xs">
            {verificationInfo != null && verificationInfo.codeNotFound === true &&
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" mt="20px">
                        {t('verify.codeNotFoundTitle')}
                    </Typography>
                    <Box textAlign={"center"}>
                        <Typography mt="10px">
                            {t('verify.codeNotFoundText')}
                        </Typography>
                    </Box>
                </Box>
            }
            {verificationInfo != null && verificationInfo.alreadyUsed === true &&
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" mt="20px">
                        {t('verify.emailAlreadyVerifiedTitle')}
                    </Typography>
                    <Box textAlign={"center"}>
                        <Typography mt="10px">
                            {t('verify.emailAlreadyVerifiedText')}
                        </Typography>
                    </Box>
                    <Button href="/signin" variant="contained" size="large" sx={{ maxWidth: "160px", alignSelf: "center", textTransform: 'none', boxShadow: 'none', marginTop: '20px'}}>
                        {t('verify.signInButton')}
                    </Button>
                </Box>
            }
            {verificationInfo != null && verificationInfo.firstName != null &&
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" mt="20px">
                        {t('verify.hey')}
                        {verificationInfo.firstName}
                        {','}
                    </Typography>
                    <Box textAlign={"center"}>
                        <Typography mt="10px">
                            {t('verify.emailVerified')}
                        </Typography>
                    </Box>
                    <Button href="/signin" variant="contained" size="large" sx={{ maxWidth: "160px", alignSelf: "center", textTransform: 'none', boxShadow: 'none', marginTop: '20px'}}>
                        {t('verify.signInButton')}
                    </Button>
                </Box>
            }
        </Container>
    )
}

export default MailVerified