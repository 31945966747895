import React from 'react'
import {Box, Button, Typography} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";

const CookieConsent = () => {
    const {t} = useTranslation();

    const [cookies, setCookie] = useCookies(["cookieConsent"]);
    const giveCookieConsent = () => {
        setCookie("cookieConsent", true, { path: "/" });
        setCookieConsentDrawerOpen(false);
    };
    const [cookieConsentDrawerOpen, setCookieConsentDrawerOpen] = React.useState(!cookies.cookieConsent);

    const cookieConsentDrawer = (
        <Box
            role="presentation"
            padding={5}
        >
            <Typography mb={1}>
                {t('cookieConsent.description')}
                <a href={"/cookie-policy"} target={"_blank"}>{t('cookieConsent.cookiePolicy')}</a>
            </Typography>
            <Button variant={"outlined"} onClick={giveCookieConsent}>{t('cookieConsent.acceptButton')}</Button>
        </Box>
    );

    return (
        <Box>
            <Drawer
                variant="persistent"
                anchor={"bottom"}
                open={cookieConsentDrawerOpen}
                //onClose={giveCookieConsent}
            >
                {cookieConsentDrawer}
            </Drawer>
        </Box>
    )
}

export default CookieConsent