import {
    AppBar,
    Box,
    MenuItem,
    Toolbar,
    styled,
    FormControl, Select
} from '@mui/material'
import React, {useState} from 'react'
import Logo from "../Logo";
import {useTranslation} from "react-i18next";

const StyledToolbar = styled(Toolbar)({
    display: "flex",
    backgroundColor: "white",
    justifyContent: "space-between",
    color: "#0F0F0F"
})

const LanguageSelect = styled(Box)(({theme}) => ({
    display: 'none',
    alignItems: 'right',
    marginLeft: 'auto',
    minWidth: 120,
    gap: '20px',
    [theme.breakpoints.up("sm")]:{
        display: 'flex'
    }
}))

const Navbar = () => {

    const {i18n} = useTranslation();

    const [language, setLanguage] = useState(i18n.resolvedLanguage);
    const handleChange = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    return (
    <AppBar position='static' sx={{boxShadow: 'none'}}>
        <StyledToolbar>
            <Logo style={{display: "block", width: 130}}  />
            <LanguageSelect>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                        id="language-select"
                        value={language}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'Language' }}
                    >
                        <MenuItem value={'en'}>English</MenuItem>
                        <MenuItem value={'bg'}>български</MenuItem>
                    </Select>
                </FormControl>
            </LanguageSelect>
        </StyledToolbar>
    </AppBar>
    )
}

export default Navbar