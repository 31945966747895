import * as React from "react";
import Typography from "@mui/material/Typography";
import {Box, Card} from "@mui/material";
import {useEffect} from "react";
import {famtrailApi} from "../../services/FamtrailApi";
import {handleLogError} from "../../services/Helpers";
import {useAuth} from "../context/AuthContext";
import {useTranslation} from "react-i18next";

function HomeContent(props) {

    const Auth = useAuth();
    const user = Auth.getUser();

    const {t} = useTranslation();

    const [userData, setUserData] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getMember(user, user.data.member_id);
                setUserData(response.data);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    return (
        <Box>
            <Card
                variant="outlined"
                style={{backgroundColor: '#FDFCF5', maxWidth: '600px', padding: '15px', marginTop: '-5px'}}
            >
                <Typography variant="h5">{t('dashboard.hello')} {userData != null && userData.firstName},</Typography>
                <Typography paragraph mt={2}>
                    {t('dashboard.intro')}
                </Typography>
                <Typography variant="h6">
                    {t('dashboard.whatCanYouDo')}
                </Typography>
                <Typography mt={2}>
                    <Box fontWeight='fontWeightMedium' display='inline'>
                        {t('dashboard.familyTree')}
                    </Box>
                </Typography>
                <Typography>
                    {t('dashboard.familyTreeSub')}
                </Typography>
                <Typography mt={2}>
                    <Box fontWeight='fontWeightMedium' display='inline'>
                        {t('dashboard.pictures')}
                    </Box>
                </Typography>
                <Typography>
                    {t('dashboard.picturesSub')}
                </Typography>
                <Typography mt={2}>
                    <Box fontWeight='fontWeightMedium' display='inline'>
                        {t('dashboard.invite')}
                    </Box>
                </Typography>
                <Typography>
                    {t('dashboard.inviteSub')}
                </Typography>
                <Typography mt={2}>
                    <Box fontWeight='fontWeightMedium' display='inline'>
                        {t('dashboard.access')}
                    </Box>
                </Typography>
                <Typography>
                    {t('dashboard.accessSub')}
                </Typography>
                <Typography mt={4}>
                    {t('dashboard.thanks')} ❤️
                </Typography>
                <Box mt={3}>
                    <img alt="signature" height="68px" src="/assets/images/signature.svg"/>
                </Box>
                <Typography>
                    {t('dashboard.founder')}
                </Typography>
            </Card>
        </Box>
    );
}

export default HomeContent;