import React from 'react'
import Navbar from "./landingpage/Navbar";
import Footer from "./landingpage/Footer";
import {Box, Typography} from "@mui/material";

const PrivacyPolicyPage = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Navbar />
            <Box padding={3}>
                <Typography variant="h4" mb={2}>Famtrail Privacy Policy</Typography>
                <Typography variant="h6" mt={2}>Our Key Privacy Principles</Typography>
                <Typography>
                    Famtrail cares deeply about the privacy of its users and the privacy of their data and its top
                    priority is to honor their trust.<br/><br/>
                    Famtrail has never sold or licensed personal data (like customer names, email addresses, pictures
                    and family trees) and will never do so in the future.<br/><br/>
                    Famtrail will never provide data to insurance companies under any circumstances.<br/><br/>
                    Users can delete their data from Famtrail at any time. Deletion is permanent and irreversible.<br/><br/>
                    If anything material ever changes in this privacy policy, we will notify you by email.<br/><br/>
                    Capitalized terms used but not defined in this Privacy Policy shall have the meaning ascribed to
                    them in our <a href={"/terms-of-use"}>Terms and Conditions.</a>
                </Typography>
                <Typography variant="h6" mt={2}>What Information Do We Collect About You?</Typography>
                <Typography>
                    We collect the information that we need to provide our Service (as defined in
                    our <a href={"/terms-of-use"}>Terms and Conditions</a>), as described below:
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Information you share directly with us</Typography>
                <Typography>
                    For example, information that you enter when you sign up for the Service, create a family tree
                    member or fill in a questionnaire or survey on our Website, and so on.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Information we receive when you use our Service</Typography>
                <Typography>
                    We collect web-behavior information using automated means of data collection technologies
                    (like cookies) to enhance your experience of visiting the Website and understand how our Services
                    are being used. See our <a href={"/cookie-policy"}>Cookie Policy</a> for more information.
                </Typography>
                <Typography variant="h6" mt={2}>How Do We Use Your Personal Information?</Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>To provide our Service to you.</Typography>
                <Typography>
                    This includes displaying your family tree, displaying your pictures, and so on.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>To communicate with you about the Service.</Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>To market the Service to you</Typography>
                <Typography>
                    from which you can opt-out at any time.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>For internal business purposes</Typography>
                <Typography>
                    to improve and develop new products and services, perform internal data analysis, analyze the use
                    of the Website to improve the customer experience or assess our promotional campaigns.
                </Typography>
                <Typography variant="h6" mt={2}>Will Famtrail Disclose any of Your Personal Information to Third Parties?</Typography>
                <Typography>
                    PERSONAL INFORMATION PROVIDED BY YOU, WILL NEVER BE SOLD OR LICENSED BY US TO THIRD PARTIES,
                    INCLUDING INSURANCE COMPANIES, GOVERNMENT AGENCIES, OTHER CORPORATIONS OR EMPLOYERS.<br/><br/>
                    We will not provide information to law enforcement unless we are required by a valid court order.<br/><br/>
                    We will not disclose any of your personal information to any third party except in the following
                    very limited circumstances:<br/>
                    (a) with our service providers (e.g., payment platforms) under the protection of appropriate
                    agreements – to provide you with our Service; (b) when required of us by law or during legal
                    proceedings, or to prevent fraud and cybercrime; and (c) in connection with the acquisition of our business.
                </Typography>
                <Typography variant="h6" mt={2}>Security of Your Personal Information</Typography>
                <Typography>
                    We have implemented technical, physical and administrative security measures to protect against
                    the loss, misuse, unauthorized access, alteration or disclosure of users' personal information
                    under our control. For example: we periodically review and enhance our security and privacy
                    practices as necessary, we commission periodic penetration tests to test the robustness of the
                    security of our Service and only authorized personnel have access to personal information.
                </Typography>
                <Typography variant="h6" mt={2}>Managing Your Privacy</Typography>
                <Typography>
                    We give you the ability to share information in a variety of ways. You can choose when and with
                    whom you share your information within or outside of the Service.
                </Typography>
                <Typography>
                    <br/><br/><br/>
                    Last modified: May 30, 2024
                </Typography>
            </Box>
            <Footer />
        </Box>
    )
}

export default PrivacyPolicyPage