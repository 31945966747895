import React, {useState} from 'react'
import {Box, FormControl, InputBase, Link, MenuItem, Select, Stack, styled, Typography} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import {useTranslation} from "react-i18next";

const LanguageSelectMobile = styled(Box)(({theme}) => ({
    display: 'none',
    alignSelf: 'center',
    marginLeft: 'auto',
    minWidth: 120,
    paddingTop: '20px',
    paddingBottom: '50px',
    gap: '20px',
    [theme.breakpoints.down("sm")]:{
        display: 'flex'
    }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        backgroundColor: 'white',
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#26A399'
        },
    },
}));

const Footer = () => {

    const {t, i18n} = useTranslation();

    const [language, setLanguage] = useState(i18n.resolvedLanguage);
    const handleChange = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    return (
        <Box bgcolor={"black"} sx={{mt: 'auto'}} >
            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
            >
                {/*<Box flex={2} p={3} alignSelf={{ xs: 'center', sm: 'center', md: 'left' }}>*/}
                {/*    <Stack direction={'column'} spacing={1} textAlign={{ xs: 'center', sm: 'center', md: 'left' }}>*/}
                {/*        <Link href="/aboutus" underline="hover" color={'#FFFFFF'}>About Us</Link>*/}
                {/*        <Link href="#" underline="hover" color={'#FFFFFF'}>Contact Us</Link>*/}
                {/*        <Link href="#" underline="hover" color={'#FFFFFF'}>Affiliates</Link>*/}
                {/*        <Link href="#" underline="hover" color={'#FFFFFF'}>Resources</Link>*/}
                {/*    </Stack>*/}
                {/*</Box>*/}
                <Box flex={2} p={3} alignSelf={{ xs: 'center', sm: 'center', md: 'left' }}>
                    <Stack direction={'column'} spacing={1} textAlign={{ xs: 'center', sm: 'center', md: 'left' }}>
                        <Link href="/privacy-policy" target={"_blank"} underline="hover" color={'#FFFFFF'}>{t('signUpPage.privacyPolicy')}</Link>
                        <Link href="/terms-of-use" underline="hover" color={'#FFFFFF'}>{t('signUpPage.termsOfUse')}</Link>
                        {/*<Link href="#" underline="hover" color={'#FFFFFF'}>Support</Link>*/}
                        {/*<Link href="#" underline="hover" color={'#FFFFFF'}>FAQ</Link>*/}
                    </Stack>
                </Box>
                <Box flex={6} p={3}>
                    <Stack direction={'column'} spacing={1} alignItems={"center"}>
                        <img src="/assets/famtrail-logo-white.svg"
                             alt="Famtrail"
                             style={{maxWidth: 100}}
                        />
                        <Typography color={'#FFFFFF'} fontSize={14}>Copyright © {new Date().getFullYear()} Famtrail</Typography>
                    </Stack>
                </Box>
                <Box flex={2} p={3}>
                    <Stack direction={'column'} spacing={1} alignItems={"center"}>
                        <Typography color={'#FFFFFF'}>{t('landingPage.footer.connectWithUs')}</Typography>
                        <Stack direction={'row'} spacing={1} alignItems={"center"}>
                            <Link href="https://www.facebook.com/profile.php?id=61560038324495" target={"_blank"}>
                                <FacebookIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />
                            </Link>
                            {/*<Link href="#">*/}
                            {/*    <InstagramIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />*/}
                            {/*</Link>*/}
                            {/*<Link href="#">*/}
                            {/*    <TwitterIcon sx={{ fontSize: 40, color: '#FFFFFF' }} />*/}
                            {/*</Link>*/}
                        </Stack>
                    </Stack>
                </Box>
                <LanguageSelectMobile>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            id="language-select"
                            value={language}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Language' }}
                            input={<BootstrapInput />}
                        >
                            <MenuItem value={'en'}>English</MenuItem>
                            <MenuItem value={'bg'}>български</MenuItem>
                        </Select>
                    </FormControl>
                </LanguageSelectMobile>
            </Stack>
        </Box>
    )
}

export default Footer