import * as React from "react";
import Typography from "@mui/material/Typography";
import {Box, Button, Stack, TextField, Tooltip} from "@mui/material";
import {famtrailApi} from "../../services/FamtrailApi";
import IconButton from "@mui/material/IconButton";
import BigAddIcon from "./icons/BigAddIcon";
import BigEditIcon from "./icons/BigEditIcon";
import {handleLogError} from "../../services/Helpers";
import {useTranslation} from "react-i18next";

function ProfileBio(props) {

    const {t} = useTranslation();

    const [bioData, setBioData] = React.useState('');
    const [isEdit, setIsEdit] = React.useState(false);

    React.useEffect(() => {
        handleGetBioData(props.user, props.selectedMember, props.data);
    }, [props.data])

    const handleGetBioData = async (user, selectedMember, data) => {
        if (data === null) return;
        try {
            const response = await famtrailApi.getBio(user, selectedMember);
            const bioData = response.data.bioText;
            setBioData(bioData);
        } catch (error) {
            handleLogError(error)
        }
    }

    const handleEditBioOnClick = () => {
        setIsEdit(true);
    };

    const handleCancelBioOnClick = () => {
        setIsEdit(false);
    };

    const handleSaveBioOnClick = async (user, selectedMember) => {
        setIsEdit(false);
        const data = {bioText: bioData};
        try {
            await famtrailApi.addBio(user, selectedMember, data);
            handleGetBioData(user, selectedMember, data);
        } catch (error) {
            handleLogError(error)
        }
    };

    const handleInputChange = (value) => {
        setBioData(value);
    }

    return (
        <Box minWidth={'xs'} sx={{
            mt: 4,
            mb: 2
        }}>
            {isEdit === true &&
                <Box>
                    <TextField
                        sx={{marginTop: '10px'}}
                        id="outlined-multiline-static"
                        fullWidth
                        multiline
                        // rows={4}
                        maxRows={20}
                        defaultValue={bioData}
                        onChange = {(e) => {
                            handleInputChange(e.target.value);
                        }}
                    />
                    <Stack
                        direction="row"
                        mt={1}
                        spacing={{ xs: 2, sm: 2, md: 2 }}
                    >
                        <Button
                            type="button"
                            variant="outlined"
                            size="medium"
                            onClick={() => handleCancelBioOnClick()}
                            sx={{ width: 'sx', textTransform: 'none', boxShadow: 'none'}}
                        >
                            {t('bioTab.cancelButton')}
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            size="medium"
                            onClick={() => handleSaveBioOnClick(props.user, props.selectedMember)}
                            sx={{ width: 'sx', textTransform: 'none', boxShadow: 'none'}}
                        >
                            {t('bioTab.saveButton')}
                        </Button>
                    </Stack>
                </Box>
            }
            {isEdit === false &&
                <Box sx={{ whiteSpace: "pre-wrap" }}>
                    <Typography paragraph mt={2}>
                        {bioData}
                    </Typography>
                </Box>
            }
            <Box>
                {bioData === null && isEdit === false && props.userData.accessLevel === "EDIT" &&
                    <Box>
                        <Box flex={3} p={0} display={'flex'} justifyContent={'center'} alignSelf="center">
                            <Typography paragraph mt={2}>
                                {t('bioTab.clickPlus')}
                            </Typography>
                        </Box>
                        <Box flex={3} p={0} display={'flex'} justifyContent={'center'} alignSelf="center">
                            <Tooltip title={t('bioTab.addBiographyTooltip')}>
                                <IconButton
                                    size="large"
                                    color="inherit"
                                    onClick={() => handleEditBioOnClick()}
                                >
                                    <BigAddIcon style={{width: 40}}  />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                }
                {bioData !== null && isEdit === false && props.userData.accessLevel === "EDIT" &&
                    <Box flex={3} p={0} display={'flex'} justifyContent={'center'} alignSelf="center">
                        <Tooltip title={t('bioTab.editBiographyTooltip')}>
                            <IconButton
                                size="large"
                                color="inherit"
                                onClick={() => handleEditBioOnClick()}
                            >
                                <BigEditIcon style={{width: 40}}  />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            </Box>
        </Box>
    );
}

export default ProfileBio;