import axios from 'axios'
import { config } from '../Constants'
import { parseJwt } from './Helpers'

export const famtrailApi = {
    authenticate,
    signup,
    getMember,
    updateMember,
    getMiniTree,
    addFather,
    addMother,
    addPartner,
    addSon,
    addDaughter,
    addBio,
    getBio,
    getPhotos,
    uploadPhoto,
    setProfilePicture,
    deletePhoto,
    changePhotoTitle,
    createInvitationLink,
    getInvitationLink,
    getInvitationInfo,
    getAccessList,
    changeAccessRights,
    getNotificationsCount,
    getNotifications,
    createAccessRequest,
    getAccessRequest,
    markNotificationAsSeen,
    sendFeedback,
    getMembersForSearch,
    deleteMember,
    patchOptIn,
    patchEmailOnNotification,
    patchLanguage,
    getSettings,
    verify,
    forgotPassword,
    resetPassword,




    numberOfUsers,
    numberOfOrders,
    getUsers,
    deleteUser,
    getOrders,
    deleteOrder,
    createOrder,
    getUserMe
}

function authenticate(username, password) {
    return instance.post('/auth/login', { username, password }, {
        headers: { 'Content-type': 'application/json' }
    })
}

function signup(user) {
    return instance.post('/auth/signup', user, {
        headers: { 'Content-type': 'application/json' }
    })
}

function getMember(user, memberId) {
    return instance.get(`/api/members/${memberId}`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function updateMember(user, memberId, data) {
    return instance.put(`/api/members/${memberId}`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getMiniTree(user, memberId) {
    return instance.get(`/api/members/${memberId}/mini-tree`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function addFather(user, memberId, data) {
    return instance.post(`/api/members/${memberId}/father`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function addMother(user, memberId, data) {
    return instance.post(`/api/members/${memberId}/mother`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function addPartner(user, memberId, data) {
    return instance.post(`/api/members/${memberId}/partner`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function addSon(user, memberId, data) {
    return instance.post(`/api/members/${memberId}/son`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function addDaughter(user, memberId, data) {
    return instance.post(`/api/members/${memberId}/daughter`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function addBio(user, memberId, data) {
    return instance.post(`/api/members/${memberId}/bio`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getBio(user, memberId, data) {
    return instance.get(`/api/members/${memberId}/bio`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getPhotos(user, memberId) {
    return instance.get(`/api/members/${memberId}/photos`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function uploadPhoto(user, memberId, file) {
    const formData = new FormData();
    formData.append('file', file)
    return instance.post(`/api/photo/upload/${memberId}`, formData, {
        headers: { 'Authorization': bearerAuth(user), 'content-type': 'multipart/form-data' }
    })
}

function setProfilePicture(user, memberId, photoId) {
    return instance.put(`/api/members/${memberId}/profile-picture/${photoId}`, null, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function deletePhoto(user, photoId) {
    return instance.delete(`/api/photo/${photoId}`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function changePhotoTitle(user, photoId, data) {
    return instance.put(`/api/photo/${photoId}/change-title`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function createInvitationLink(user, memberId) {
    return instance.post(`/api/members/${memberId}/invitation-link`, null, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getInvitationLink(user, memberId) {
    return instance.get(`/api/members/${memberId}/invitation-link`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getInvitationInfo(code) {
    return instance.get(`/api/invitation/${code}`)
}

function getAccessList(user, memberId) {
    return instance.get(`/api/members/${memberId}/access-list`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function changeAccessRights(user, memberId, data) {
    return instance.patch(`/api/members/${memberId}/access`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getNotificationsCount(user) {
    return instance.get(`/api/notifications/count`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getNotifications(user) {
    return instance.get(`/api/notifications`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function createAccessRequest(user, memberId) {
    return instance.post(`/api/members/${memberId}/access-request`, null, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getAccessRequest(user, memberId) {
    return instance.get(`/api/members/${memberId}/access-request`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function markNotificationAsSeen(user, notificationId) {
    return instance.patch(`/api/notifications/${notificationId}/seen`, null, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function sendFeedback(user, data) {
    return instance.post(`/api/feedback`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getMembersForSearch(user) {
    return instance.get(`/api/members/search`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function deleteMember(user, memberId) {
    return instance.delete(`/api/members/${memberId}`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function patchOptIn(user, data) {
    return instance.patch(`/api/settings/opt-in`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function patchEmailOnNotification(user, data) {
    return instance.patch(`/api/settings/email-on-notification`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function patchLanguage(user, data) {
    return instance.patch(`/api/settings/language`, data, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getSettings(user) {
    return instance.get(`/api/settings`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function verify(data) {
    return instance.post('/auth/verify', data, {
        //headers: { 'Content-type': 'application/json' }
    })
}

function forgotPassword(data) {
    return instance.post('/auth/forgot-password', data, {
        //headers: { 'Content-type': 'application/json' }
    })
}

function resetPassword(data) {
    return instance.post('/auth/reset-password', data, {
        //headers: { 'Content-type': 'application/json' }
    })
}










function numberOfUsers() {
    return instance.get('/public/numberOfUsers')
}

function numberOfOrders() {
    return instance.get('/public/numberOfOrders')
}

function getUsers(user, username) {
    const url = username ? `/api/users/${username}` : '/api/users'
    return instance.get(url, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function deleteUser(user, username) {
    return instance.delete(`/api/users/${username}`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function getOrders(user, text) {
    const url = text ? `/api/orders?text=${text}` : '/api/orders'
    return instance.get(url, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function deleteOrder(user, orderId) {
    return instance.delete(`/api/orders/${orderId}`, {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

function createOrder(user, order) {
    return instance.post('/api/orders', order, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(user)
        }
    })
}

function getUserMe(user) {
    return instance.get('/api/users/me', {
        headers: { 'Authorization': bearerAuth(user) }
    })
}

// -- Axios

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.request.use(function (config) {
    // If token is expired, redirect user to login
    if (config.headers.Authorization) {
        const token = config.headers.Authorization.split(' ')[1]
        const data = parseJwt(token)
        if (Date.now() > data.exp * 1000) {
            window.location.href = "/login"
        }
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

// -- Helper functions

function bearerAuth(user) {
    return `Bearer ${user.accessToken}`
}