import {Avatar, Box, Button, Stack, styled, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from "react-i18next";
import {famtrailApi} from "../../services/FamtrailApi";
import {handleLogError} from "../../services/Helpers";
import {useSearchParams} from "react-router-dom";

const TitleText = styled(Box)(({theme}) => ({
    alignItems: 'center',
    marginLeft: 'auto',
    textAlign: 'center',
    gap: '5px',
    [theme.breakpoints.up("sm")]:{
        display: 'flex'
    }
}))


const Content = () => {
    const {t} = useTranslation();

    const [searchParams] = useSearchParams();

    const [invitationInfo, setInvitationInfo] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getInvitationInfo(searchParams.get("c"));
                setInvitationInfo(response.data);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    return (
        <Box bgcolor={"white"} sx={{mt: 'auto'}} >
            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
            >
                <Box flex={1} p={0} />
                <TitleText bgcolor={"white"} flex={5} p={2}>
                    <Stack
                        direction={{ xs: 'column', sm: 'column' }}
                        spacing={{ xs: 3, sm: 3, md: 4 }}
                        marginTop={{ xs: 4, sm: 4, md: 0 }}
                    >
                        <Typography
                            fontWeight={700}
                            fontSize={{ xs: 36, sm: 42, md: 58 }}
                            letterSpacing={{ xs: 0, sm: 0, md: -2 }}
                            lineHeight={{ xs: '36px', sm: '42px', md: '54px' }}
                            sx={{ color: "#3C3C3C" }}>
                            {t('invitationPage.hello')}
                            {invitationInfo != null && invitationInfo.firstName != null && invitationInfo.firstName}
                        </Typography>
                        {invitationInfo != null && invitationInfo.inviterPhoto != null &&
                            <Box alignSelf={"center"}>
                                <Avatar
                                    alt={invitationInfo.inviterFirstName}
                                    sx={{ width: 64, height: 64 }}
                                    src={invitationInfo.inviterPhoto} />
                            </Box>
                        }
                        {invitationInfo != null && invitationInfo.alreadyRegistered === true &&
                            <Typography variant="p" fontSize={20}>
                                {t('invitationPage.accountExists')}
                            </Typography>
                        }
                        {invitationInfo != null && invitationInfo.alreadyRegistered === false &&
                            <Typography variant="p" fontSize={20}>
                                {invitationInfo.inviterFirstName + " "}
                                {invitationInfo.inviterLastName}
                                {t('invitationPage.invitationText')}
                            </Typography>
                        }
                        {invitationInfo != null && invitationInfo.alreadyRegistered === true &&
                            <Button href="/signin" variant="contained" size="large" sx={{ maxWidth: "160px", alignSelf: "center", textTransform: 'none', boxShadow: 'none'}}>
                                {t('invitationPage.logIn')}
                            </Button>
                        }
                        {invitationInfo != null && invitationInfo.alreadyRegistered === false &&
                            <Button href={"/signup?c=" + searchParams.get("c")} variant="contained" size="large" sx={{ maxWidth: "160px", alignSelf: "center", textTransform: 'none', boxShadow: 'none'}}>
                                {t('landingPage.content.actionButton')}
                            </Button>
                        }
                    </Stack>
                </TitleText>
                <Box flex={5} p={2} width={360} alignSelf={{ xs: 'center', sm: 'center', md: 'left' }}>
                    <img src="/assets/images/grandfather-child.svg"
                         alt="Grandfather with child"
                         style={{maxWidth: 440}}
                    />
                </Box>
                <Box flex={1} p={0} />
            </Stack>
        </Box>
    )
}

export default Content