import React, {useState} from 'react'
import {
    Alert,
    Box,
    Button, Container,
    Grid,
    Link,
    TextField, Typography
} from "@mui/material";
import {useAuth} from "./context/AuthContext";
import {Navigate, useNavigate} from "react-router-dom";
import {famtrailApi} from '../services/FamtrailApi'
import { parseJwt, handleLogError } from '../services/Helpers'
import Logo from "./LogoBeta";
import {useTranslation} from "react-i18next";

const SignIn = () => {

    const Auth = useAuth()
    const isLoggedIn = Auth.userIsAuthenticated()

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [isError, setIsError] = useState(false)
    // const [showPassword, setShowPassword] = useState(false);
    //
    // const handleClickShowPassword = () => setShowPassword((show) => !show);
    //
    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };

    const handleInputChange = (name, value) => {
        if (name === 'email') {
            setUsername(value)
        } else if (name === 'password') {
            setPassword(value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!(username && password)) {
            setIsError(true)
            return
        }

        try {
            const response = await famtrailApi.authenticate(username.toLowerCase(), password)
            if (response.data.verificationPending === true) {
                navigate("/verification-pending");
                return;
            }
            const { accessToken } = response.data
            const data = parseJwt(accessToken)
            const authenticatedUser = { data, accessToken }

            Auth.userLogin(authenticatedUser)

            setUsername('')
            setPassword('')
            setIsError(false)
        } catch (error) {
            handleLogError(error)
            setIsError(true)
        }
    }

    if (isLoggedIn) {
        return <Navigate to={'/app'} />
    }

    return (
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Logo style={{display: "block", width: 130}} />
                    <Typography component="h1" variant="h5" mt="20px">
                        {t('signInPage.signIn')}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t('signInPage.emailAddress')}
                            name="email"
                            autoComplete="email"
                            inputProps={{ maxLength: 255 }}
                            autoFocus
                            value={username}
                            onChange = {(e) => {
                                handleInputChange(e.target.name, e.target.value);
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('signInPage.password')}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            inputProps={{ maxLength: 255 }}
                            value={password}
                            onChange = {(e) => {
                                handleInputChange(e.target.name, e.target.value);
                            }}
                        />
                        {isError && <Alert severity="error" variant="filled">{t('signInPage.error.incorrectEmailOrPassword')}</Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={{ mt: 3, mb: 2, textTransform: 'none', boxShadow: 'none'}}
                        >
                            {t('signInPage.signInButton')}
                        </Button>
                        <Grid container>
                            {/*<Grid item xs>*/}
                            {/*    <Link href="/forgot-password" underline="hover">*/}
                            {/*        {t('signInPage.forgotPassword')}*/}
                            {/*    </Link>*/}
                            {/*</Grid>*/}
                            <Grid item>
                                <Link href="/signup" underline="hover">
                                    {t('signInPage.noAccount')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
    );
}

export default SignIn