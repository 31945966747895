import React from 'react'

const BellIcon = (props) => {
    return (
        <img src={props.selected ? "/assets/icons/bell_a.svg" : "/assets/icons/bell.svg"}
             alt="Notifications"
             style={props.style}
        />
    )
}

export default BellIcon