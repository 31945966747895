import * as React from "react";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Snackbar, Stack, TextField,
    Tooltip
} from "@mui/material";
import Container from "@mui/material/Container";

import './ProfileGallery.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import {useRef} from "react";
import {famtrailApi} from "../../services/FamtrailApi";
import {handleLogError} from "../../services/Helpers";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import BigAddIcon from "./icons/BigAddIcon";
import {useTranslation} from "react-i18next";

function ProfileGallery(props) {

    const {t} = useTranslation();

    const [itemData, setItemData] = React.useState([]);
    React.useEffect(() => {
        setItemData(props.data);
    },[props.data, itemData]);

    const [galleryInstance, setGalleryInstance] = React.useState(null);

    const inputRef = useRef(null);

    const [isUploading, setUploading] = React.useState(false);
    const [photoTitleSuccessChangedOpen, setPhotoTitleSuccessChangedOpen] = React.useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [errorSnackbarMessage, setErrorSnackbarMessage] = React.useState('');
    const [currSlideData, setCurrSlideData] = React.useState(null);


    // upload button click
    const handleClick = () => {
        inputRef.current.click();
    };

    const handleFileUpload = async (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }

        // reset file input
        event.target.value = null;

        // find upload file size
        if(fileObj.size > 31457280) {
            setErrorSnackbarMessage(t('photosTab.bigFile'))
            setErrorSnackbarOpen(true);
            return;
        }

        try {
            setUploading(true);
            const response = await famtrailApi.uploadPhoto(props.user, props.selectedMember, fileObj);
            setItemData([...itemData, response.data]);
            props.handleSetSelected(props.selectedMember);
            props.handleNavbarRefresh();
        } catch (error) {
            setUploading(false);
            setErrorSnackbarMessage(t('photosTab.errorUploading'))
            setErrorSnackbarOpen(true);
            handleLogError(error);
        } finally {
            setUploading(false);
        }
    };

    const handleSetProfilePicture = async () => {
        setUseAsProfilePictureConfirmationDialogOpen(false);

        try {
            await famtrailApi.setProfilePicture(props.user, props.selectedMember, currSelectedPhoto);
            props.handleSetSelected(props.selectedMember);
            props.handleNavbarRefresh();
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    }

    const handleTitleChange = async (title) => {
        handleChangePhotoTitleConfirmationDialogClose();
        console.log(title);
        try {
            const data = {title: title};
            await famtrailApi.changePhotoTitle(props.user, currSelectedPhoto, data);
            props.handleSetSelected(props.selectedMember);
            setPhotoTitleSuccessChangedOpen(true);
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    }

    const handleMemberSuccessfullyUpdatedAlertClose = () => {
        setPhotoTitleSuccessChangedOpen(false);
    }

    const handleErrorSnackbarClose = () => {
        setErrorSnackbarOpen(false);
        setErrorSnackbarMessage('');
    }

    const handleDeletePicture = async () => {
        setDeletePictureConfirmationDialogOpen(false);
        galleryInstance.destroy();

        try {
            await famtrailApi.deletePhoto(props.user, currSelectedPhoto);
            props.handleSetSelected(props.selectedMember);
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    }

    const options = {
        bgOpacity: 1,
        preloaderDelay: 1000,
        //arrowPrevSVG: '<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 100 125" width="100" height="125"><path d="M5,50L50,5l3,3L11,50l42,42l-3,3L5,50z M92,95l3-3L53,50L95,8l-3-3L47,50L92,95z"/></svg>',
        closeTitle: 'Close',
        zoomTitle: 'Zoom',
        arrowPrevTitle: 'Previous photo',
        arrowNextTitle: 'Next',
        preload: [1, 4],
        errorMsg: 'The image cannot be loaded',
    }

    let uiElements = [];
    if (props.userData.accessLevel === "EDIT") {
        uiElements = [
            // set as profile pic UI element
            {
                name: 'profile-picture',
                ariaLabel: 'Set as profile picture',
                order: 9,
                isButton: true,
                html: '<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><rect x="12.62" y="12.44" width="6.77" height="10.85" rx="1.32" ry="1.32" style="fill:#fff; stroke:#4f4f4f; stroke-miterlimit:10; stroke-width:.75px;"/><circle cx="16" cy="16.61" r="1.87" style="fill:#fff; stroke:#4f4f4f; stroke-miterlimit:10; stroke-width:.75px;"/><path d="M17.47,23.22c.21,0,.95-.32,1.14-.3,0-.08.15-.16.15-.24,0-2.51-1.24-4.2-2.77-4.2s-2.77,1.72-2.77,4.23c0,.05.7.52.7.57.48.03,3.74-.13,4.14,0" style="fill:#fff; stroke:#4f4f4f; stroke-miterlimit:10; stroke-width:.75px;"/></svg>',
                appendTo: 'bar',
                onInit: (el, pswpInstance) => {
                    // do something on UI element's init event
                },
                onClick: (e, el, pswpInstance) => {
                    setCurrSelectedPhoto(pswpInstance.currSlide.data.pid);
                    setUseAsProfilePictureConfirmationDialogOpen(true);
                },
            },
            // delete pic UI element
            {
                name: 'delete-picture',
                ariaLabel: 'Delete picture',
                order: 8,
                isButton: true,
                html: '<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><rect x="12.68" y="15.49" width="5.99" height="7.8" rx="1.86" ry="1.86" style="fill:#fff; stroke:#4f4f4f; stroke-miterlimit:10; stroke-width:.75px;"/><rect x="12.29" y="13.1" width="6.77" height="1.76" rx=".88" ry=".88" style="fill:#fff; stroke:#4f4f4f; stroke-miterlimit:10; stroke-width:.75px;"/><line x1="14.56" y1="12.44" x2="16.8" y2="12.44" style="fill:none; stroke:#4f4f4f; stroke-linecap:round; stroke-miterlimit:10; stroke-width:.75px;"/><line x1="15.68" y1="16.74" x2="15.68" y2="21.94" style="fill:none; stroke:#4f4f4f; stroke-linecap:round; stroke-miterlimit:10; stroke-width:.75px;"/><line x1="14.21" y1="16.74" x2="14.21" y2="21.94" style="fill:none; stroke:#4f4f4f; stroke-linecap:round; stroke-miterlimit:10; stroke-width:.75px;"/><line x1="17.19" y1="16.74" x2="17.19" y2="21.94" style="fill:none; stroke:#4f4f4f; stroke-linecap:round; stroke-miterlimit:10; stroke-width:.75px;"/></svg>',
                appendTo: 'bar',
                onInit: (el, pswpInstance) => {
                    // do something on UI element's init event
                },
                onClick: (e, el, pswpInstance) => {
                    setGalleryInstance(pswpInstance);
                    setCurrSelectedPhoto(pswpInstance.currSlide.data.pid);
                    setDeletePictureConfirmationDialogOpen(true);
                },
            },
            // edit pic title UI element
            {
                name: 'edit-picture',
                ariaLabel: 'Edit photo title',
                order: 7,
                isButton: true,
                html: '<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><rect x="18.51" y="13.05" width="2.23" height="1.14" transform="translate(15.04 -9.82) rotate(44.18)" style="fill:#fff; stroke:#4f4f4f; stroke-miterlimit:10; stroke-width:.75px;"/><rect x="15.67" y="13.79" width="2.23" height="5.49" transform="translate(16.27 -7.02) rotate(44.18)" style="fill:#fff; stroke:#4f4f4f; stroke-miterlimit:10; stroke-width:.75px;"/><path d="M14.49,20.5c-.85.57-1.71.72-2.02.42-.31-.3-.16-1.14.42-1.97l.69-.71,1.6,1.56-.69.71Z" style="fill:#fff; stroke:#4f4f4f; stroke-miterlimit:10; stroke-width:.75px;"/><rect x="12.3" y="21.72" width="8.74" height="1.66" style="fill:#fff; stroke:#4f4f4f; stroke-miterlimit:10; stroke-width:.75px;"/></svg>',
                appendTo: 'bar',
                onInit: (el, pswpInstance) => {
                    // do something on UI element's init event
                },
                onClick: (e, el, pswpInstance) => {
                    setGalleryInstance(pswpInstance);
                    setCurrSelectedPhoto(pswpInstance.currSlide.data.pid);
                    setCurrSlideData(pswpInstance.currSlide.data);
                    pswpInstance.destroy();
                    setChangePhotoTitleConfirmationDialogOpen(true);
                },
            },

            //
            // add another custom UI element
            // ...
        ]
    } else {
        uiElements = []
    }



    const [currSelectedPhoto, setCurrSelectedPhoto] = React.useState('');
    const [useAsProfilePictureConfirmationDialogOpen, setUseAsProfilePictureConfirmationDialogOpen] = React.useState(false);
    const [deletePictureConfirmationDialogOpen, setDeletePictureConfirmationDialogOpen] = React.useState(false);
    const [changePhotoTitleConfirmationDialogOpen, setChangePhotoTitleConfirmationDialogOpen] = React.useState(false);
    const handleUseAsProfilePictureConfirmationDialogClose = () => {
        setUseAsProfilePictureConfirmationDialogOpen(false);
    };

    const handleDeletePictureConfirmationDialogClose = () => {
        setDeletePictureConfirmationDialogOpen(false);
    };

    const handleChangePhotoTitleConfirmationDialogClose = () => {
        setChangePhotoTitleConfirmationDialogOpen(false);
    };


    return (
        <Container maxWidth={'100vw'} sx={{width:'100%', padding:'0px!important'}}>
            <Box marginTop={3}>
                <Gallery withCaption withDownloadButton options={options} uiElements={uiElements}>
                    {itemData.map((item) => (
                        <Item
                            original={item.img}
                            thumbnail={item.thumb}
                            id={item.id}
                            caption={item.title}
                            width={item.width}
                            height={item.height}
                            key={item.id}
                        >
                            {({ ref, open }) => (
                                <img alt="Some Alt here" width={120} height={120} ref={ref} onClick={open} src={item.thumb} key={item.id}
                                     style={{cursor: 'pointer', marginRight: '1px', marginTop: '-3px', borderRadius: '5%'}} />
                            )}
                        </Item>
                    ))}
                    {itemData != null && itemData.length === 0 && props.userData.accessLevel === "EDIT" &&
                        <Box>
                            <Box flex={3} p={0} display={'flex'} justifyContent={'center'} alignSelf="center">
                                {isUploading === false &&
                                    <Typography paragraph mt={2}>
                                        {t('photosTab.clickPlusButton')}
                                    </Typography>
                                }
                            </Box>
                            <Box flex={3} p={0} display={'flex'} justifyContent={'center'} alignSelf="center">
                                {isUploading === false &&
                                    <Tooltip title={t('photosTab.addPhotoTooltip')}>
                                        <IconButton
                                            size="large"
                                            color="inherit"
                                            onClick={() => handleClick()}
                                        >
                                            <BigAddIcon style={{width: 40}}  />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {isUploading === true &&
                                    <img alt="Uploading..." width={120} height={120} src={'./photo-uploading-anim.gif'} key={'uploadingKey'}
                                         style={{cursor: 'pointer', marginRight: '1px', marginTop: '-3px'}} />
                                }
                            </Box>
                        </Box>
                    }
                    {itemData != null && itemData.length !== 0 && props.userData.accessLevel === "EDIT" &&
                        <Item id={'upload_image_id'}>
                            {() => (
                                <Tooltip title={t('photosTab.addNewPhotoTooltip')} placement="bottom">
                                    {isUploading === false &&
                                        <img alt="Add new" width={120} height={120} onClick={handleClick} src={'./add_photo.png'} key={'uploadKey'}
                                             style={{cursor: 'pointer', marginRight: '1px', marginTop: '-3px'}} />
                                    }
                                    {isUploading === true &&
                                        <img alt="Uploading..." width={120} height={120} src={'./photo-uploading-anim.gif'} key={'uploadingKey'}
                                             style={{cursor: 'pointer', marginRight: '1px', marginTop: '-3px'}} />
                                    }
                                </Tooltip>
                            )}
                        </Item>
                    }
                </Gallery>
                <input type='file' id='file' ref={inputRef} onChange={handleFileUpload}
                       // accept="image/jpeg, .jpg, .jpeg, image/png, .png, image/heic, .heic"
                       accept="image/jpeg, .jpg, .jpeg, image/png, .png"
                       style={{display: 'none'}}
                />
            </Box>
            <Dialog
                open={useAsProfilePictureConfirmationDialogOpen}
                onClose={handleUseAsProfilePictureConfirmationDialogClose}
                aria-labelledby="profile-pic-alert-dialog-title"
                aria-describedby="profile-pic-alert-dialog-description"
                sx={{zIndex: '100000 !important'}}
            >
                <DialogTitle id="profile-pic-alert-dialog-title">
                    {t('photosTab.profilePicTitle')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="profile-pic-alert-dialog-description">
                        {t('photosTab.profilePicText')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                            onClick={handleUseAsProfilePictureConfirmationDialogClose}
                            sx={{ textTransform: 'none', boxShadow: 'none'}}
                    >
                        {t('photosTab.no')}
                    </Button>
                    <Button variant="contained"
                            onClick={handleSetProfilePicture}
                            sx={{ textTransform: 'none', boxShadow: 'none'}}
                    >
                        {t('photosTab.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deletePictureConfirmationDialogOpen}
                onClose={handleDeletePictureConfirmationDialogClose}
                aria-labelledby="delete-pic-alert-dialog-title"
                aria-describedby="delete-pic-alert-dialog-description"
                sx={{zIndex: '100000 !important'}}
            >
                <DialogTitle id="delete-pic-alert-dialog-title">
                    {t('photosTab.deletePicTitle')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-pic-alert-dialog-description">
                        {t('photosTab.deletePicText')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                            onClick={handleDeletePictureConfirmationDialogClose}
                            sx={{ textTransform: 'none', boxShadow: 'none'}}
                    >
                        {t('photosTab.cancel')}
                    </Button>
                    <Button variant="contained"
                            onClick={handleDeletePicture}
                            sx={{ textTransform: 'none', boxShadow: 'none'}}
                    >
                        {t('photosTab.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={changePhotoTitleConfirmationDialogOpen}
                onClose={handleChangePhotoTitleConfirmationDialogClose}
                aria-labelledby="change-title-pic-alert-dialog-title"
                aria-describedby="change-title-pic-alert-dialog-description"
                fullWidth
                maxWidth="sm"
                sx={{zIndex: '100000 !important'}}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const title = formJson.title;
                        handleTitleChange(title);
                    },
                }}
            >
                <DialogTitle id="change-title-pic-alert-dialog-title">
                    {t('photosTab.photoTitleTitle')}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="name"
                        name="title"
                        type="text"
                        inputProps={{ maxLength: 255 }}
                        fullWidth
                        variant="outlined"
                        defaultValue={currSlideData != null ? currSlideData.caption : ''}
                    />
                </DialogContent>
                <DialogActions>
                    <Box display={'flex'} gap={1} paddingRight={'15px'} paddingBottom={1}>
                        <Button variant="outlined"
                                onClick={handleChangePhotoTitleConfirmationDialogClose}
                                sx={{ textTransform: 'none', boxShadow: 'none'}}
                        >
                            {t('photosTab.cancel')}
                        </Button>
                        <Button type="submit"
                                variant="contained"
                                sx={{ textTransform: 'none', boxShadow: 'none', paddingLeft: '10px'}}
                        >
                            {t('photosTab.save')}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={photoTitleSuccessChangedOpen}
                    autoHideDuration={3000}
                    onClose={handleMemberSuccessfullyUpdatedAlertClose}
                    message={t('photosTab.photoTitleUpdated')}
                />
            </Stack>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                    <Alert
                        onClose={handleErrorSnackbarClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {errorSnackbarMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </Container>
    );
}

export default ProfileGallery;