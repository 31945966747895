import React from 'react'
import { Box, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";

const VerificationPending = () => {
    const {t} = useTranslation();

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5" mt="20px">
                    {t('verificationPending.title')}
                </Typography>
                <Box textAlign={"center"}>
                    <Typography mt="10px">
                        {t('verificationPending.text')}
                    </Typography>
                </Box>
            </Box>
        </Container>
    )
}

export default VerificationPending