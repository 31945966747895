import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import PrivateNavbar from "./PrivateNavbar";
import Sidebar from "./Sidebar";
import HomeContent from "./HomeContent";
import TreeContent from "./TreeContent";
import ProfileContent from "./ProfileContent";
import {useAuth} from "../context/AuthContext";
import FeedbackContent from "./FeedbackContent";
import HelpContent from "./HelpContent";

const drawerWidth = 240;

function HomePage(props) {

    const Auth = useAuth();
    const user = Auth.getUser();

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedMember, setSelectedMember] = React.useState(null);
    const [navbarKey, setNavbarKey] = React.useState(Math.random);

    // used when notification is clicked
    const handleSetSelected = (id) => {
        setSelectedMember(id);
        setSelectedIndex(0);
    }

    const handleNavbarRefresh = () => {
        setNavbarKey(Math.random);
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleNavigationChange = (event, index) => {
        setSelectedMember(null);
        setSelectedIndex(index);
    };

    const displayContent = (index) => {
        switch (index) {
            case 0:
                return <ProfileContent key={selectedMember} handleNavbarRefresh={handleNavbarRefresh} selectedMember={selectedMember != null ? selectedMember : user.data.member_id} />;
            case 1:
                return <FeedbackContent user={user} />;
            // case 3:
            //     return <HelpContent />;
            default:
                return <HomeContent />;
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <PrivateNavbar key={navbarKey} handleDrawerToggle={handleDrawerToggle} handleSetSelectedParent={handleSetSelected} />
            <Sidebar
                props={props}
                handleDrawerToggle={handleDrawerToggle}
                handleNavigationChange={handleNavigationChange}
                selectedIndex={selectedIndex}
                drawerWidth={drawerWidth}
                mobileOpen={mobileOpen} />
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` }, marginLeft: { xs: "0", md: `${drawerWidth}px`} }}
            >
                <Toolbar />
                {displayContent(selectedIndex)}
            </Box>
        </Box>
    );
}

export default HomePage;