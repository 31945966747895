import React, {useEffect, useRef, useState} from 'react'
import {
    AppBar,
    Box,
    MenuItem,
    Toolbar,
    Typography,
    Tooltip,
    Avatar,
    Menu,
    Divider,
    Badge,
    Autocomplete,
    TextField,
    InputAdornment,
    Container,
    Button,
    Switch,
    Stack,
    styled, FormControl, Select
} from '@mui/material'
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PrivateLogo from "./PrivateLogoBeta";
import PrivateLogoMini from "./PrivateLogoMiniBeta";
import {useAuth} from "../context/AuthContext";
// import MessageIcon from "./icons/MessageIcon";
import BellIcon from "./icons/BellIcon";
import SearchIcon from "./icons/SearchIcon";
import {famtrailApi} from "../../services/FamtrailApi";
import {handleLogError} from "../../services/Helpers";
import DefaultAvatar from "./icons/DefaultAvatar";
import {usePageVisibility} from "../../usePageVisibility";
import Drawer from "@mui/material/Drawer";
import NotificationBox from "./NotificationBox";
import LogOutIcon from "./icons/LogOutIcon";
import SettingsIcon from "./icons/SettingsIcon";
import {useTranslation} from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

const PrivateNavbar = ({handleSetSelectedParent, handleDrawerToggle}) => {

    const { userLogout } = useAuth();

    // the id of the selected member
    const Auth = useAuth();
    const user = Auth.getUser();

    const {i18n} = useTranslation();

    const [language, setLanguage] = useState(i18n.resolvedLanguage);
    const handleLanguageChange = async (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
        try {
            const data = {language: event.target.value};
            await famtrailApi.patchLanguage(user, data);
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    };

    const {t} = useTranslation();

    const [userData, setUserData] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getMember(user, user.data.member_id);
                setUserData(response.data);
            } catch (error) {
                if (error.response.status === 401) {
                    userLogout();
                }
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    const [allNotificationsCount, setAllNotificationsCount] = React.useState(0);
    const [unseenNotificationsCount, setUnseenNotificationsCount] = React.useState(0);
    const [notificationsData, setNotificationsData] = React.useState([]);
    const [isNotificationsLoading, setNotificationsLoading] = React.useState(true);
    const isPageVisible = usePageVisibility();
    const timerIdRef = useRef(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getNotificationsCount(user);
                setAllNotificationsCount(response.data.all);
                setUnseenNotificationsCount(response.data.unseen);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();

        const startPolling = () => {
            // Polling every 60 seconds
            timerIdRef.current = setInterval(fetchData, 60000);
        };

        const stopPolling = () => {
            clearInterval(timerIdRef.current);
        };

        if (isPageVisible) {
            startPolling();
        } else {
            stopPolling();
        }

        return () => {
            stopPolling();
        };
    }, [isPageVisible])

    const [familyMembers, setFamilyMembers] = React.useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getMembersForSearch(user);
                setFamilyMembers(response.data);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [notificationsDrawerOpen, setNotificationsDrawerOpen] = React.useState(false);
    const [settingsDrawerOpen, setSettingsDrawerOpen] = React.useState(false);
    const [searchDrawerOpen, setSearchDrawerOpen] = React.useState(false);

    const [switchOptedIn, setSwitchOptedIn] = React.useState(true);
    const [switchEmailNotifications, setSwitchEmailNotifications] = React.useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await famtrailApi.getSettings(user);
                setSwitchOptedIn(response.data.optIn);
                setSwitchEmailNotifications(response.data.emailOnNotification);
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    const handleOptedInSwitchChange = async (event) => {
        setSwitchOptedIn(event.target.checked);
        try {
            const data = {optIn: event.target.checked};
            await famtrailApi.patchOptIn(user, data);
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    };

    const handleSwitchEmailNotificationsChange = async (event) => {
        setSwitchEmailNotifications(event.target.checked);
        try {
            const data = {emailOnNotification: event.target.checked};
            await famtrailApi.patchEmailOnNotification(user, data);
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    };

    const handleSearchValueChanged = (newValue) => {
        const obj = familyMembers.find(o => o.name === newValue);
        if (obj != null) {
            handleSetSelected(obj.id);
        }
    }

    const closeSearchDrawer = () => {
        setSearchDrawerOpen(false);
    }

    const closeNotificationsDrawer = () => {
        setNotificationsDrawerOpen(false);
    }

    const closeSettingsDrawer = () => {
        setSettingsDrawerOpen(false);
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const reloadNotifications = async() => {
        const notificationsResponse = await famtrailApi.getNotifications(user);
        setNotificationsData(notificationsResponse.data);
        const response = await famtrailApi.getNotificationsCount(user);
        setAllNotificationsCount(response.data.all);
        setUnseenNotificationsCount(response.data.unseen);
    }

    const handleOpenNotificationsDrawer = async (event) => {
        setNotificationsDrawerOpen(true);
        const notificationsResponse = await famtrailApi.getNotifications(user);
        setNotificationsData(notificationsResponse.data);
        setNotificationsLoading(false);
    };

    const handleSetSelected = (id) => {
        handleSetSelectedParent(id);
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogOut = () => {
        setAnchorElUser(null);
        userLogout();
    };

    const handleSettingClick = () => {
        setAnchorElUser(null);
        setSettingsDrawerOpen(true);
    };


    const searchBarDrawer = (
        <Box
            role="presentation"
            // onClick={() => setActionBarOpen(false)}
            // onKeyDown={() => setActionBarOpen(false)}
        >
            <Box
                sx={{ flexGrow: 0, margin: '10px', alignItems: 'right', maxWidth: 'sx', height: 'auto' }}
            >
                <Autocomplete
                    freeSolo
                    id="search-mobile"
                    onChange={(event, newValue) => {
                        handleSearchValueChanged(newValue);
                    }}
                    disableClearable
                    options={familyMembers.map((option) => option.name)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{width: 20, marginLeft: '10px'}} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            </Box>
        </Box>
    );

    const notificationsDrawer = (
        <Box
            role="presentation"
            // onClick={() => setActionBarManageMemberOpen(false)}
            // onKeyDown={() => setActionBarManageMemberOpen(false)}
        >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        minWidth: '275px',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography variant='h6' mb={2}>
                            {t('navbar.notifications')}
                        </Typography>

                        {isNotificationsLoading &&
                            <Box mb={3}>
                                <CircularProgress />
                            </Box>
                        }

                        {notificationsData.length === 0 && isNotificationsLoading === false &&
                            <Typography mb={3}>
                                {t('navbar.noNotifications')}
                            </Typography>
                        }

                        <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{ mt: 0, mb: 2, textTransform: 'none', boxShadow: 'none'}}
                            onClick={() => setNotificationsDrawerOpen(false)}
                        >
                            {t('navbar.buttonClose')}
                        </Button>

                        {notificationsData.map((item) => (
                            <NotificationBox
                                key={item.id}
                                id={item.id}
                                seen={item.seen}
                                type={item.type}
                                initiatorMemberId={item.initiatorMemberId}
                                initiatorFirstName={item.initiatorFirstName}
                                initiatorLastName={item.initiatorLastName}
                                initiatorInitails={item.initiatorInitials}
                                initiatorGender={item.initiatorGender}
                                initiatorProfilePicture={item.initiatorPhoto}
                                targetMemberId={item.targetMemberId}
                                targetFirstName={item.targetFirstName}
                                targetLastName={item.targetLastName}
                                targetInitails={item.targetInitials}
                                targetGender={item.targetGender}
                                targetProfilePicture={item.targetPhoto}
                                createdOn={item.createdOn}
                                photoPath={item.photoPath}
                                user={user}
                                initiatorToTargetAccessLevel={item.initiatorToTargetAccessLevel}
                                handleSetSelected={handleSetSelected}
                                reloadNotifications={reloadNotifications}
                            />
                        ))}

                    </Box>
                </Box>
            </Container>
        </Box>
    );

    const LanguageSelect = styled(Box)(({theme}) => ({
        display: 'flex',
        alignItems: 'right',
        marginLeft: 'auto',
        minWidth: 120,
        gap: '20px'
    }))

    const settingsDrawer = (
        <Box
            role="presentation"
            // onClick={() => setActionBarManageMemberOpen(false)}
            // onKeyDown={() => setActionBarManageMemberOpen(false)}
        >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        minWidth: '275px',
                        flexDirection: 'column'
                    }}
                >
                    <Box>
                        <Typography variant='h6' mb={2}>
                            {t('settings.settings')}
                        </Typography>

                        <Stack
                            direction={'row'}
                            spacing={2}
                            alignItems="center"
                        >
                            <Typography>
                                {t('settings.language')}
                            </Typography>
                            <LanguageSelect>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <Select
                                        id="language-select"
                                        value={language}
                                        onChange={handleLanguageChange}
                                        inputProps={{ 'aria-label': 'Language' }}
                                    >
                                        <MenuItem value={'en'}>English</MenuItem>
                                        <MenuItem value={'bg'}>български</MenuItem>
                                    </Select>
                                </FormControl>
                            </LanguageSelect>
                        </Stack>
                        {/*<Stack*/}
                        {/*    direction={'row'}*/}
                        {/*    spacing={2}*/}
                        {/*    alignItems="center"*/}
                        {/*    mt={2}*/}
                        {/*>*/}
                        {/*    <Switch*/}
                        {/*        checked={switchOptedIn}*/}
                        {/*        onChange={handleOptedInSwitchChange}*/}
                        {/*        inputProps={{ 'aria-label': 'email-optedin' }}*/}
                        {/*    />*/}
                        {/*    <Typography>*/}
                        {/*        {t('settings.newsAndTips')}*/}
                        {/*    </Typography>*/}
                        {/*</Stack>*/}
                        {/*<Stack*/}
                        {/*    direction={'row'}*/}
                        {/*    spacing={2}*/}
                        {/*    alignItems="center"*/}
                        {/*    display={'flex'}*/}
                        {/*>*/}
                        {/*    <Switch*/}
                        {/*        checked={switchEmailNotifications}*/}
                        {/*        onChange={handleSwitchEmailNotificationsChange}*/}
                        {/*        inputProps={{ 'aria-label': 'notification-email' }}*/}
                        {/*    />*/}
                        {/*    <Typography>*/}
                        {/*        {t('settings.emailsForNotifications')}*/}
                        {/*    </Typography>*/}
                        {/*</Stack>*/}

                        <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{ mt: 4, textTransform: 'none', boxShadow: 'none'}}
                            onClick={() => setSettingsDrawerOpen(false)}
                        >
                            {t('navbar.buttonClose')}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    );

    return (
        <AppBar
            position="fixed"
            sx={{boxShadow: 'none'}}
        >
            <Toolbar sx={{display: "flex", backgroundColor: "white", color: "#0F0F0F"}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Box display={{ xs: 'none', md: 'block' }}>
                    <PrivateLogo style={{display: "block", width: 120, paddingLeft: '7px'}}  />
                </Box>
                <Box display={{ xs: 'block', md: 'none' }}>
                    <PrivateLogoMini style={{display: "block", width: 40}}  />
                </Box>
                <Box
                    display={{ xs: 'none', md: 'block' }}
                    sx={{ flexGrow: 0, alignItems: 'right', marginLeft: 'auto', minWidth: '500px' }}
                >
                    <Autocomplete
                        freeSolo
                        id="search"
                        onChange={(event, newValue) => {
                            handleSearchValueChanged(newValue);
                            //setSearchValue(newValue);
                        }}
                        disableClearable
                        options={familyMembers.map((option) => option.name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{width: 20, marginLeft: '10px'}} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>
                <Box
                    display={{ xs: 'block', md: 'none' }}
                    sx={{ flexGrow: 0, alignItems: 'right', marginLeft: 'auto' }}
                    onClick={() => setSearchDrawerOpen(true)}
                >
                    <SearchIcon style={{width: 20, marginLeft: '10px'}}
                    />
                </Box>
                <Box sx={{ flexGrow: 0, alignItems: 'right', marginLeft: 'auto' }}>
                    {/*<IconButton size="large" aria-label="show 4 new mails" color="inherit">*/}
                    {/*    <Badge badgeContent={4} color="error">*/}
                    {/*        <MessageIcon style={{width: 20}}  />*/}
                    {/*    </Badge>*/}
                    {/*</IconButton>*/}
                    <Tooltip title={t('navbar.notifications')}>
                        <IconButton
                            size="large"
                            aria-label="show notifications"
                            color="inherit"
                            sx={{marginRight: '20px'}}
                            onClick={handleOpenNotificationsDrawer}
                        >
                            <Badge badgeContent={unseenNotificationsCount} color="error">
                                <BellIcon style={{width: 20}}  />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={userData != null && userData.firstName + " " + userData.lastName}>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            {userData != null && userData.profilePicture != null &&
                                <Avatar
                                    alt={userData.firstName}
                                    // sx={{ width: 64, height: 64 }}
                                    src={userData.profilePicture} />
                            }
                            {userData != null && userData.profilePicture == null &&
                                <Avatar sx={{bgcolor: '#FFFFFF'}} >
                                    <DefaultAvatar />
                                </Avatar>
                            }
                            {userData == null &&
                                <Avatar sx={{bgcolor: '#FFFFFF'}} >
                                    <DefaultAvatar />
                                </Avatar>
                            }
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-user"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <MenuItem key="settings" onClick={handleSettingClick}>
                            <SettingsIcon style={{width: 20, marginRight: '10px'}} />
                            <Typography textAlign="center">{t('navbar.settings')}</Typography>
                        </MenuItem>
                        <MenuItem key="logout" onClick={handleLogOut}>
                            <LogOutIcon style={{width: 20, marginRight: '10px'}} />
                            <Typography textAlign="center">{t('navbar.signOut')}</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
            <Drawer
                variant="temporary"
                anchor={"right"}
                open={notificationsDrawerOpen}
                onClose={closeNotificationsDrawer}
            >
                {notificationsDrawer}
            </Drawer>
            <Drawer
                variant="temporary"
                anchor={"top"}
                open={searchDrawerOpen}
                onClose={closeSearchDrawer}
            >
                {searchBarDrawer}
            </Drawer>
            <Drawer
                variant="temporary"
                anchor={"right"}
                open={settingsDrawerOpen}
                onClose={closeSettingsDrawer}
            >
                {settingsDrawer}
            </Drawer>
            <Divider orientation="horizontal" flexItem color="#F0F0F0" />
        </AppBar>
    )
}

export default PrivateNavbar