import React from 'react'

const DeleteIcon = (props) => {
    return (
        <img src={props.selected ? "/assets/icons/delete.svg" : "/assets/icons/delete.svg"}
             alt="Delete"
             style={props.style}
        />
    )
}

export default DeleteIcon