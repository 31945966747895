import React from 'react'
import Navbar from "./landingpage/Navbar";
import Footer from "./landingpage/Footer";
import {Box, Typography} from "@mui/material";

const AboutUsPage = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Navbar />
            <Typography variant="h1">About Us</Typography>
            <Footer />
        </Box>
    )
}

export default AboutUsPage