import React from 'react'

const AddMotherIcon = (props) => {
    return (
        <img src={props.selected ? "/assets/icons/add-mother.svg" : "/assets/icons/add-mother.svg"}
             alt="add mother"
             style={props.style}
        />
    )
}

export default AddMotherIcon