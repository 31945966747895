import {Box, Button, Link, Stack, styled, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from "react-i18next";

const TitleText = styled(Box)(({theme}) => ({
    alignItems: 'center',
    marginLeft: 'auto',
    textAlign: 'center',
    gap: '5px',
    [theme.breakpoints.up("sm")]:{
        display: 'flex'
    }
}))

const Content = () => {
    const {t} = useTranslation();

    return (
        <Box bgcolor={"white"} sx={{mt: 'auto'}} >
            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
            >
                <Box flex={1} p={0} />
                <TitleText bgcolor={"white"} flex={5} p={2}>
                    <Stack
                        direction={{ xs: 'column', sm: 'column' }}
                        spacing={{ xs: 3, sm: 3, md: 4 }}
                        marginTop={{ xs: 4, sm: 4, md: 0 }}
                    >
                        <Typography
                            fontWeight={700}
                            fontSize={{ xs: 36, sm: 42, md: 58 }}
                            letterSpacing={{ xs: 0, sm: 0, md: -2 }}
                            lineHeight={{ xs: '36px', sm: '42px', md: '54px' }}
                            sx={{ color: "#3C3C3C" }}>
                            {t('landingPage.content.title')}
                        </Typography>
                        <Typography variant="p" fontSize={20}>
                            {t('landingPage.content.description')}
                        </Typography>
                        <Box display={{ xs: '', sm: 'none', md: 'none' }}>
                            <Typography>
                                <Link href="/signin" underline="hover">
                                    {t('landingPage.navbar.signIn')}
                                </Link>
                                {" "} {t('landingPage.navbar.or')}
                            </Typography>
                        </Box>
                        <Button href="/signup" variant="contained" size="large" sx={{ maxWidth: "160px", alignSelf: "center", textTransform: 'none', boxShadow: 'none'}}>
                            {t('landingPage.content.actionButton')}
                        </Button>
                    </Stack>
                </TitleText>
                <Box flex={5} p={2} width={360} alignSelf={{ xs: 'center', sm: 'center', md: 'left' }}>
                    <img src="/assets/images/grandfather-child.svg"
                         alt="Grandfather with child"
                         style={{maxWidth: 440}}
                    />
                </Box>
                <Box flex={1} p={0} />
            </Stack>
        </Box>
    )
}

export default Content