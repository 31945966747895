import React from 'react'

const InviteIcon = (props) => {
    return (
        <img src={props.selected ? "/assets/icons/invite.svg" : "/assets/icons/invite.svg"}
             alt="Invite to Famtrail"
             style={props.style}
        />
    )
}

export default InviteIcon