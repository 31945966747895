import React, {useState} from 'react'
import {
    Alert,
    Box,
    Button, Container,
    Grid,
    Link,
    TextField, Typography
} from "@mui/material";
import {useAuth} from "./context/AuthContext";
import {Navigate, useNavigate} from "react-router-dom";
import {famtrailApi} from '../services/FamtrailApi'
import { parseJwt, handleLogError } from '../services/Helpers'
import Logo from "./LogoBeta";
import {useTranslation} from "react-i18next";

const ForgotPassword = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [username, setUsername] = useState("")
    const [isError, setIsError] = useState(false)

    const handleInputChange = (name, value) => {
        if (name === 'email') {
            setUsername(value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!username) {
            setIsError(true)
            return
        }

        try {
            const data = {email: username};
            await famtrailApi.forgotPassword(data);

            setUsername('')
            setIsError(false)
        } catch (error) {
            handleLogError(error)
            setIsError(true)
            return
        }
        navigate("/password-reset-sent");
    }

    return (
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" mt="20px">
                        {t('forgotPassword.title')}
                    </Typography>
                    <Typography mt={4} mb={1}>
                        {t('forgotPassword.text')}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t('signInPage.emailAddress')}
                            name="email"
                            autoComplete="email"
                            inputProps={{ maxLength: 255 }}
                            autoFocus
                            value={username}
                            onChange = {(e) => {
                                handleInputChange(e.target.name, e.target.value);
                            }}
                        />
                        {isError && <Alert severity="error" variant="filled">{'Incorrect email address'}</Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={{ mt: 3, mb: 2, textTransform: 'none', boxShadow: 'none'}}
                        >
                            {t('forgotPassword.submitButton')}
                        </Button>
                    </Box>
                </Box>
            </Container>
    );
}

export default ForgotPassword