import React from 'react'

const BigAddIcon = (props) => {
    return (
        <img src={props.selected ? "/assets/icons/big-add.svg" : "/assets/icons/big-add.svg"}
             alt="Add"
             style={props.style}
        />
    )
}

export default BigAddIcon