

export function getLocaleDateString(currentLanguage) {
    const formats = {
        "af": "YYYY/MM/DD",
        "af-ZA": "YYYY/MM/DD",
        "am": "DD/MM/YYYY",
        "am-ET": "DD/MM/YYYY",
        "ar": "DD/MM/YYYY",
        "ar-AE": "DD/MM/YYYY",
        "ar-BH": "DD/MM/YYYY",
        "ar-DZ": "DD-MM-YYYY",
        "ar-EG": "DD/MM/YYYY",
        "ar-IQ": "DD/MM/YYYY",
        "ar-JO": "DD/MM/YYYY",
        "ar-KW": "DD/MM/YYYY",
        "ar-LB": "DD/MM/YYYY",
        "ar-LY": "DD/MM/YYYY",
        "ar-MA": "DD-MM-YYYY",
        "ar-OM": "DD/MM/YYYY",
        "ar-QA": "DD/MM/YYYY",
        "ar-SA": "DD/MM/YYYY",
        "ar-SY": "DD/MM/YYYY",
        "ar-TN": "DD-MM-YYYY",
        "ar-YE": "DD/MM/YYYY",
        "arn": "DD-MM-YYYY",
        "arn-CL": "DD-MM-YYYY",
        "as": "DD-MM-YYYY",
        "as-IN": "DD-MM-YYYY",
        "az": "DD.MM.YYYY",
        "az-Cyrl-AZ": "DD.MM.YYYY",
        "az-Latn-AZ": "DD.MM.YYYY",
        "ba": "DD.MM.YYYY",
        "ba-RU": "DD.MM.YYYY",
        "be": "DD.MM.YYYY",
        "be-BY": "DD.MM.YYYY",
        "bg": "DD.MM.YYYY",
        "bg-BG": "DD.M.YYYY",
        "bn": "DD-MM-YYYY",
        "bn-BD": "DD-MM-YYYY",
        "bn-IN": "DD-MM-YYYY",
        "bo": "YYYY/MM/DD",
        "bo-CN": "YYYY/MM/DD",
        "br": "DD/MM/YYYY",
        "br-FR": "DD/MM/YYYY",
        "bs": "DD.MM.YYYY",
        "bs-Cyrl-BA": "DD.MM.YYYY",
        "bs-Latn-BA": "DD.MM.YYYY",
        "ca": "DD/MM/YYYY",
        "ca-ES": "DD/MM/YYYY",
        "co": "DD/MM/YYYY",
        "co-FR": "DD/MM/YYYY",
        "cs": "DD.MM.YYYY",
        "cs-CZ": "DD.MM.YYYY",
        "cy": "DD/MM/YYYY",
        "cy-GB": "DD/MM/YYYY",
        "da": "DD-MM-YYYY",
        "da-DK": "DD-MM-YYYY",
        "de": "DD.MM.YYYY",
        "de-AT": "DD.MM.YYYY",
        "de-CH": "DD.MM.YYYY",
        "de-DE": "DD.MM.YYYY",
        "de-LI": "DD.MM.YYYY",
        "de-LU": "DD.MM.YYYY",
        "dsb": "DD.MM.YYYY",
        "dsb-DE": "DD.MM.YYYY",
        "dv": "DD/MM/YYYY",
        "dv-MV": "DD/MM/YYYY",
        "el": "DD/MM/YYYY",
        "el-GR": "DD/MM/YYYY",
        "en": "MM/DD/YYYY",
        "en-029": "MM/DD/YYYY",
        "en-AU": "DD/MM/YYYY",
        "en-BZ": "DD/MM/YYYY",
        "en-CA": "DD/MM/YYYY",
        "en-GB": "DD/MM/YYYY",
        "en-IE": "DD/MM/YYYY",
        "en-IN": "DD-MM-YYYY",
        "en-JM": "DD/MM/YYYY",
        "en-MY": "DD/MM/YYYY",
        "en-NZ": "DD/MM/YYYY",
        "en-PH": "MM/DD/YYYY",
        "en-SG": "DD/MM/YYYY",
        "en-TT": "DD/MM/YYYY",
        "en-US": "MM/DD/YYYY",
        "en-ZA": "YYYY/MM/DD",
        "en-ZW": "MM/DD/YYYY",
        "es": "DD/MM/YYYY",
        "es-AR": "DD/MM/YYYY",
        "es-BO": "DD/MM/YYYY",
        "es-CL": "DD-MM-YYYY",
        "es-CO": "DD/MM/YYYY",
        "es-CR": "DD/MM/YYYY",
        "es-DO": "DD/MM/YYYY",
        "es-EC": "DD/MM/YYYY",
        "es-ES": "DD/MM/YYYY",
        "es-GT": "DD/MM/YYYY",
        "es-HN": "DD/MM/YYYY",
        "es-MX": "DD/MM/YYYY",
        "es-NI": "DD/MM/YYYY",
        "es-PA": "MM/DD/YYYY",
        "es-PE": "DD/MM/YYYY",
        "es-PR": "DD/MM/YYYY",
        "es-PY": "DD/MM/YYYY",
        "es-SV": "DD/MM/YYYY",
        "es-US": "MM/DD/YYYY",
        "es-UY": "DD/MM/YYYY",
        "es-VE": "DD/MM/YYYY",
        "et": "DD.MM.YYYY",
        "et-EE": "DD.MM.YYYY",
        "eu": "YYYY/MM/DD",
        "eu-ES": "YYYY/MM/DD",
        "fa": "MM/DD/YYYY",
        "fa-IR": "MM/DD/YYYY",
        "fi": "DD.MM.YYYY",
        "fi-FI": "DD.MM.YYYY",
        "fil": "MM/DD/YYYY",
        "fil-PH": "MM/DD/YYYY",
        "fo": "DD-MM-YYYY",
        "fo-FO": "DD-MM-YYYY",
        "fr": "DD/MM/YYYY",
        "fr-BE": "DD/MM/YYYY",
        "fr-CA": "YYYY-MM-DD",
        "fr-CH": "DD.MM.YYYY",
        "fr-FR": "DD/MM/YYYY",
        "fr-LU": "DD/MM/YYYY",
        "fr-MC": "DD/MM/YYYY",
        "fy": "DD-MM-YYYY",
        "fy-NL": "DD-MM-YYYY",
        "ga": "DD/MM/YYYY",
        "ga-IE": "DD/MM/YYYY",
        "gd": "DD/MM/YYYY",
        "gd-GB": "DD/MM/YYYY",
        "gl": "DD/MM/YYYY",
        "gl-ES": "DD/MM/YYYY",
        "gsw": "DD/MM/YYYY",
        "gsw-FR": "DD/MM/YYYY",
        "gu": "DD-MM-YYYY",
        "gu-IN": "DD-MM-YYYY",
        "ha": "DD/MM/YYYY",
        "ha-Latn-NG": "DD/MM/YYYY",
        "he": "DD/MM/YYYY",
        "he-IL": "DD/MM/YYYY",
        "hi": "DD-MM-YYYY",
        "hi-IN": "DD-MM-YYYY",
        "hr": "DD.MM.YYYY.",
        "hr-BA": "DD.MM.YYYY.",
        "hr-HR": "DD.MM.YYYY",
        "hsb": "DD.MM.YYYY",
        "hsb-DE": "DD.MM.YYYY",
        "hu": "YYYY.MM.DD.",
        "hu-HU": "YYYY.MM.DD.",
        "hy": "DD.MM.YYYY",
        "hy-AM": "DD.MM.YYYY",
        "id": "DD/MM/YYYY",
        "id-ID": "DD/MM/YYYY",
        "ig": "DD/MM/YYYY",
        "ig-NG": "DD/MM/YYYY",
        "ii": "YYYY/MM/DD",
        "ii-CN": "YYYY/MM/DD",
        "is": "DD.MM.YYYY",
        "is-IS": "DD.MM.YYYY",
        "it": "DD/MM/YYYY",
        "it-CH": "DD.MM.YYYY",
        "it-IT": "DD/MM/YYYY",
        "iu": "DD/MM/YYYY",
        "iu-Cans-CA": "DD/MM/YYYY",
        "iu-Latn-CA": "DD/MM/YYYY",
        "ja": "YYYY/MM/DD",
        "ja-JP": "YYYY/MM/DD",
        "ka": "DD.MM.YYYY",
        "ka-GE": "DD.MM.YYYY",
        "kk": "DD.MM.YYYY",
        "kk-KZ": "DD.MM.YYYY",
        "kl": "DD-MM-YYYY",
        "kl-GL": "DD-MM-YYYY",
        "km": "YYYY-MM-DD",
        "km-KH": "YYYY-MM-DD",
        "kn": "DD-MM-YYYY",
        "kn-IN": "DD-MM-YYYY",
        "ko": "YYYY.MM.DD",
        "ko-KR": "YYYY.MM.DD",
        "kok": "DD-MM-YYYY",
        "kok-IN": "DD-MM-YYYY",
        "ky": "DD.MM.YYYY",
        "ky-KG": "DD.MM.YYYY",
        "lb": "DD/MM/YYYY",
        "lb-LU": "DD/MM/YYYY",
        "lo": "DD/MM/YYYY",
        "lo-LA": "DD/MM/YYYY",
        "lt": "YYYY.MM.DD",
        "lt-LT": "YYYY.MM.DD",
        "lv": "YYYY.MM.DD.",
        "lv-LV": "YYYY.MM.DD.",
        "mi": "DD/MM/YYYY",
        "mi-NZ": "DD/MM/YYYY",
        "mk": "DD.MM.YYYY",
        "mk-MK": "DD.MM.YYYY",
        "ml": "DD-MM-YYYY",
        "ml-IN": "DD-MM-YYYY",
        "mn": "YYYY.MM.DD",
        "mn-MN": "YYYY.MM.DD",
        "mn-Mong-CN": "YYYY/MM/DD",
        "moh": "MM/DD/YYYY",
        "moh-CA": "MM/DD/YYYY",
        "mr": "DD-MM-YYYY",
        "mr-IN": "DD-MM-YYYY",
        "ms": "DD/MM/YYYY",
        "ms-BN": "DD/MM/YYYY",
        "ms-MY": "DD/MM/YYYY",
        "mt": "DD/MM/YYYY",
        "mt-MT": "DD/MM/YYYY",
        "nb": "DD.MM.YYYY",
        "nb-NO": "DD.MM.YYYY",
        "ne": "MM/DD/YYYY",
        "ne-NP": "MM/DD/YYYY",
        "nl": "DD-MM-YYYY",
        "nl-BE": "DD/MM/YYYY",
        "nl-NL": "DD-MM-YYYY",
        "nn": "DD.MM.YYYY",
        "nn-NO": "DD.MM.YYYY",
        "nso": "YYYY/MM/DD",
        "nso-ZA": "YYYY/MM/DD",
        "oc": "DD/MM/YYYY",
        "oc-FR": "DD/MM/YYYY",
        "or": "DD-MM-YYYY",
        "or-IN": "DD-MM-YYYY",
        "pa": "DD-MM-YYYY",
        "pa-IN": "DD-MM-YYYY",
        "pl": "DD.MM.YYYY",
        "pl-PL": "DD.MM.YYYY",
        "prs": "DD/MM/YYYY",
        "prs-AF": "DD/MM/YYYY",
        "ps": "DD/MM/YYYY",
        "ps-AF": "DD/MM/YYYY",
        "pt": "DD/MM/YYYY",
        "pt-BR": "DD/MM/YYYY",
        "pt-PT": "DD-MM-YYYY",
        "qut": "DD/MM/YYYY",
        "qut-GT": "DD/MM/YYYY",
        "quz": "DD/MM/YYYY",
        "quz-BO": "DD/MM/YYYY",
        "quz-EC": "DD/MM/YYYY",
        "quz-PE": "DD/MM/YYYY",
        "rm": "DD/MM/YYYY",
        "rm-CH": "DD/MM/YYYY",
        "ro": "DD.MM.YYYY",
        "ro-RO": "DD.MM.YYYY",
        "ru": "DD.MM.YYYY",
        "ru-RU": "DD.MM.YYYY",
        "rw": "MM/DD/YYYY",
        "rw-RW": "MM/DD/YYYY",
        "sa": "DD-MM-YYYY",
        "sa-IN": "DD-MM-YYYY",
        "sah": "MM.DD.YYYY",
        "sah-RU": "MM.DD.YYYY",
        "se": "DD.MM.YYYY",
        "se-FI": "DD.MM.YYYY",
        "se-NO": "DD.MM.YYYY",
        "se-SE": "YYYY-MM-DD",
        "si": "YYYY-MM-DD",
        "si-LK": "YYYY-MM-DD",
        "sk": "DD.MM.YYYY",
        "sk-SK": "DD.MM.YYYY",
        "sl": "DD.MM.YYYY",
        "sl-SI": "DD.MM.YYYY",
        "sma": "DD.MM.YYYY",
        "sma-NO": "DD.MM.YYYY",
        "sma-SE": "YYYY-MM-DD",
        "smj": "DD.MM.YYYY",
        "smj-NO": "DD.MM.YYYY",
        "smj-SE": "YYYY-MM-DD",
        "smn": "DD.MM.YYYY",
        "smn-FI": "DD.MM.YYYY",
        "sms": "DD.MM.YYYY",
        "sms-FI": "DD.MM.YYYY",
        "sq": "YYYY-MM-DD",
        "sq-AL": "YYYY-MM-DD",
        "sr": "DD.MM.YYYY",
        "sr-Cyrl-BA": "DD.MM.YYYY",
        "sr-Cyrl-CS": "DD.MM.YYYY",
        "sr-Cyrl-ME": "DD.MM.YYYY",
        "sr-Cyrl-RS": "DD.MM.YYYY",
        "sr-Latn-BA": "DD.MM.YYYY",
        "sr-Latn-CS": "DD.MM.YYYY",
        "sr-Latn-ME": "DD.MM.YYYY",
        "sr-Latn-RS": "DD.MM.YYYY",
        "sv": "DD.MM.YYYY",
        "sv-FI": "DD.MM.YYYY",
        "sv-SE": "YYYY-MM-DD",
        "sw": "MM/DD/YYYY",
        "sw-KE": "MM/DD/YYYY",
        "syr": "DD/MM/YYYY",
        "syr-SY": "DD/MM/YYYY",
        "ta": "DD-MM-YYYY",
        "ta-IN": "DD-MM-YYYY",
        "te": "DD-MM-YYYY",
        "te-IN": "DD-MM-YYYY",
        "tg": "DD.MM.YYYY",
        "tg-Cyrl-TJ": "DD.MM.YYYY",
        "th": "DD/MM/YYYY",
        "th-TH": "DD/MM/YYYY",
        "tk": "DD.MM.YYYY",
        "tk-TM": "DD.MM.YYYY",
        "tn": "YYYY/MM/DD",
        "tn-ZA": "YYYY/MM/DD",
        "tr": "DD.MM.YYYY",
        "tr-TR": "DD.MM.YYYY",
        "tt": "DD.MM.YYYY",
        "tt-RU": "DD.MM.YYYY",
        "tzm": "DD-MM-YYYY",
        "tzm-Latn-DZ": "DD-MM-YYYY",
        "ug": "YYYY-MM-DD",
        "ug-CN": "YYYY-MM-DD",
        "uk": "DD.MM.YYYY",
        "uk-UA": "DD.MM.YYYY",
        "ur": "DD/MM/YYYY",
        "ur-PK": "DD/MM/YYYY",
        "uz": "DD.MM.YYYY",
        "uz-Cyrl-UZ": "DD.MM.YYYY",
        "uz-Latn-UZ": "DD/MM/YYYY",
        "vi": "DD/MM/YYYY",
        "vi-VN": "DD/MM/YYYY",
        "wo": "DD/MM/YYYY",
        "wo-SN": "DD/MM/YYYY",
        "xh": "YYYY/MM/DD",
        "xh-ZA": "YYYY/MM/DD",
        "yo": "DD/MM/YYYY",
        "yo-NG": "DD/MM/YYYY",
        "zh": "YYYY/MM/DD",
        "zh-CN": "YYYY/MM/DD",
        "zh-HK": "DD/MM/YYYY",
        "zh-MO": "DD/MM/YYYY",
        "zh-SG": "DD/MM/YYYY",
        "zh-TW": "YYYY/MM/DD",
        "zu": "YYYY/MM/DD",
        "zu-ZA": "YYYY/MM/DD",
    };

    return formats[currentLanguage] || "MM/DD/YYYY";
}