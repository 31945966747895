import * as React from "react";
import {Circle, Group, Layer, Rect, Stage, Text, Line} from "react-konva";

function MiniTree(props) {

    const COLOR_MALE = "#2681A3";
    const COLOR_FEMALE = "#D8416F";
    const COLOR_SELECTED = "#F09E1F";
    const PRIMARY_CIRCLE_RADIUS = 24;
    const SMALL_CIRCLE_RADIUS = 15;
    const HORIZONTAL_DISTANCE_PAIR = 48;
    const HORIZONTAL_DISTANCE_SMALL = 16;
    const VERTICAL_DISTANCE = 66;
    const VERTICAL_DISTANCE_SMALL = 18;

    const [width, setWidth] = React.useState(window.innerWidth < 600 ? window.innerWidth - 50 : (window.innerWidth < 900 ? window.innerWidth - 50 : (window.innerWidth < 1536 ? window.innerWidth - 292 : window.innerWidth - 1160)));
    const height = props.height;
    const centerX = Math.ceil(width/2);
    const centerY = height/2;

    const [miniTreeData, setMiniTreeData] = React.useState(null);
    const [isLoading, setLoading] = React.useState(true);

    // Calculate canvas dimensions. They change between devices and on resize
    React.useEffect(() => {
        function handleResize() {
            setWidth(
            window.innerWidth < 600 ? window.innerWidth - 50 : (window.innerWidth < 900 ? window.innerWidth - 50 : (window.innerWidth < 1536 ? window.innerWidth - 292 : window.innerWidth - 1160))
            )
        }
        window.addEventListener('resize', handleResize)
        return _ => {window.removeEventListener('resize', handleResize)}
    })

    React.useEffect(() => {
        setMiniTreeData(props.data);
        parseData(miniTreeData);
    },[props.data, miniTreeData]);

    // global offset
    const [globalOffsetX, setGlobalOffsetX] = React.useState(0);
    const [offsetY, setOffsetY] = React.useState(0);

    const handleDrag = (e) => {
        setGlobalOffsetX((e.target.x() * -1) - 1000);
        //setOffsetY(e.target.y() * -1);
    };

    const parseData = (data) => {
        if (data != null) {
            setSelected(data.selectedmember);
            setFather(data.father);
            setMother(data.mother);
            setPartner(data.partner);
            setChildren(data.children);
            setSiblings(data.siblings);
            setLoading(false);
        }

    }

    const [selected, setSelected] = React.useState(
        {
            id: "1",
            initials: "",
            gender: "MALE"
        }
    );
    const [father, setFather] = React.useState(null);
    const [mother, setMother] = React.useState(null);
    const [partner, setPartner] = React.useState(null);
    const [children, setChildren] = React.useState(null);
    const [siblings, setSiblings] = React.useState(null);

    const handleMemberClick = (e) => {
        setLoading(true);
        const container = e.target.getStage().container();
        container.style.cursor = "default";
        props.handleSetSelected(e.target.id());
    }

    const drawLoading = () => {
        return (
            <Group>
                <Rect x={5} y={5} width={width-8} height={height-8} draggable="false" opacity={0.3} fill="white" />
                <Text  x={10} y={10} text={'loading...'} fontSize={14} />
            </Group>
        )
    };

    const drawMember = (key, globalX, globalY, offsetX, color, selected, initials) => {
        return (
            <Group key={key} onClick={handleMemberClick} onTap={handleMemberClick}
                   onMouseEnter={e => {
                       const container = e.target.getStage().container();
                       container.style.cursor = "pointer";
                   }}
                   onMouseLeave={e => {
                       const container = e.target.getStage().container();
                       container.style.cursor = "default";
                   }}
            >
                <Circle id={key} offsetX={offsetX + globalOffsetX} offsetY={offsetY} x={globalX} y={globalY} radius={PRIMARY_CIRCLE_RADIUS} fill={selected ? COLOR_SELECTED : color} />
                <Text id={key} offsetX={offsetX + globalOffsetX} offsetY={offsetY} x={globalX - PRIMARY_CIRCLE_RADIUS} y={globalY - 9} width={PRIMARY_CIRCLE_RADIUS * 2} text={initials} fill="white" fontSize={22} align="center" />
            </Group>
        );
    };

    const drawSibling = (key, globalX, globalY, offsetX, color, selected, initials) => {
        return (
            <Group key={key} onClick={handleMemberClick} onTap={handleMemberClick}
                   onMouseEnter={e => {
                       const container = e.target.getStage().container();
                       container.style.cursor = "pointer";
                   }}
                   onMouseLeave={e => {
                       const container = e.target.getStage().container();
                       container.style.cursor = "default";
                   }}
            >
                <Circle id={key} offsetX={offsetX + globalOffsetX} offsetY={offsetY} x={globalX} y={globalY} radius={SMALL_CIRCLE_RADIUS} fill={selected ? COLOR_SELECTED : color} />
                <Text id={key} offsetX={offsetX + globalOffsetX} offsetY={offsetY} x={globalX - SMALL_CIRCLE_RADIUS} y={globalY - 6} width={SMALL_CIRCLE_RADIUS * 2} text={initials} fill="white" fontSize={14} align="center" />
            </Group>
        );
    };

    return (
        <Stage width={width} height={height}>
            <Layer>

                {/*border rect*/}
                <Rect x={0} y={0} width={2} height={height} fill={'white'}/>
                <Rect x={width-2} y={0} width={2} height={height} fill={'white'}/>
                <Rect x={1} y={1} width={width-2} height={height-2}
                      cornerRadius={[5, 5, 5, 5]} strokeWidth={1} stroke="#D0D0D0"/>

                {/*this is a scrollable transparent rectangle drown on top of the canvas*/}
                <Rect x={-1000} y={-1000} width={3000} height={2000} draggable="true" opacity={0.0} fill="gray" onDragMove={handleDrag} />

                {/*draws selected member*/}
                {selected != null && drawMember(selected.id, centerX, centerY, 0, null, true, selected.initials)}

                {/*both mother and father are provided*/}
                {father != null && mother != null &&
                    drawMember(
                        father.id,
                        centerX - HORIZONTAL_DISTANCE_PAIR ,
                        centerY - VERTICAL_DISTANCE,
                        0,
                        father.gender === "MALE" ? COLOR_MALE : COLOR_FEMALE,
                        false,
                        father.initials
                    )
                }
                {father != null && mother != null &&
                    drawMember(
                        mother.id,
                        centerX + HORIZONTAL_DISTANCE_PAIR ,
                        centerY - VERTICAL_DISTANCE,
                        0,
                        mother.gender === "MALE" ? COLOR_MALE : COLOR_FEMALE,
                        false,
                        mother.initials
                    )
                }
                {father != null && mother != null &&
                    <Line
                        points={[
                            centerX - HORIZONTAL_DISTANCE_PAIR + PRIMARY_CIRCLE_RADIUS - globalOffsetX,
                            centerY - VERTICAL_DISTANCE,
                            centerX + HORIZONTAL_DISTANCE_PAIR - PRIMARY_CIRCLE_RADIUS - globalOffsetX,
                            centerY - VERTICAL_DISTANCE
                        ]}
                        stroke={'#262626'} strokeWidth={1}
                    />
                }
                {father != null && mother != null &&
                    <Line
                        points={[
                            centerX - globalOffsetX,
                            centerY - VERTICAL_DISTANCE,
                            centerX - globalOffsetX,
                            centerY - PRIMARY_CIRCLE_RADIUS
                        ]}
                        stroke={'#262626'} strokeWidth={1}
                    />
                }

                {/*only father provided*/}
                {father != null && mother == null &&
                    drawMember(
                        father.id,
                        centerX,
                        centerY - VERTICAL_DISTANCE,
                        0,
                        father.gender === "MALE" ? COLOR_MALE : COLOR_FEMALE,
                        false,
                        father.initials
                    )
                }
                {father != null && mother == null &&
                    <Line
                        points={[
                            centerX - globalOffsetX,
                            centerY - VERTICAL_DISTANCE + PRIMARY_CIRCLE_RADIUS,
                            centerX - globalOffsetX,
                            centerY - PRIMARY_CIRCLE_RADIUS
                        ]}
                        stroke={'#262626'} strokeWidth={1}
                    />
                }

                {/*only mother provided*/}
                {father == null && mother != null &&
                    drawMember(
                        mother.id,
                        centerX,
                        centerY - VERTICAL_DISTANCE,
                        0,
                        mother.gender === "MALE" ? COLOR_MALE : COLOR_FEMALE,
                        false,
                        mother.initials
                    )
                }
                {father == null && mother != null &&
                    <Line
                        points={[
                            centerX - globalOffsetX,
                            centerY - VERTICAL_DISTANCE + PRIMARY_CIRCLE_RADIUS,
                            centerX - globalOffsetX,
                            centerY - PRIMARY_CIRCLE_RADIUS
                        ]}
                        stroke={'#262626'} strokeWidth={1}
                    />
                }

                {/*partner provided*/}
                {partner != null &&
                    drawMember(
                        partner.id,
                        centerX + HORIZONTAL_DISTANCE_PAIR * 2 ,
                        centerY,
                        0,
                        partner.gender === "MALE" ? COLOR_MALE : COLOR_FEMALE,
                        false,
                        partner.initials
                    )
                }
                {partner != null &&
                    <Line
                        points={[
                            centerX + PRIMARY_CIRCLE_RADIUS - globalOffsetX,
                            centerY,
                            centerX + HORIZONTAL_DISTANCE_PAIR * 2 - PRIMARY_CIRCLE_RADIUS - globalOffsetX,
                            centerY
                        ]}
                        stroke={'#262626'} strokeWidth={1}
                    />
                }

                {/*children and no partner*/}
                {children != null && partner == null &&
                    children.map((child, index ) => {
                        return drawMember(
                            child.id,
                            centerX + ((index * 2) * PRIMARY_CIRCLE_RADIUS + index * HORIZONTAL_DISTANCE_PAIR),
                            centerY + VERTICAL_DISTANCE,
                            (children.length - 1) * PRIMARY_CIRCLE_RADIUS + ((children.length - 1) * 0.5) * HORIZONTAL_DISTANCE_PAIR,
                            child.gender === "MALE" ? COLOR_MALE : COLOR_FEMALE,
                            false,
                            child.initials
                        )
                    })
                }
                {children != null && partner == null &&
                    <Line
                        points={[
                            centerX - globalOffsetX,
                            centerY + PRIMARY_CIRCLE_RADIUS,
                            centerX - globalOffsetX,
                            centerY + PRIMARY_CIRCLE_RADIUS + VERTICAL_DISTANCE_SMALL/2,
                        ]}
                        stroke={'#262626'} strokeWidth={1}
                    />
                }
                {children != null && partner == null &&
                    children.map((child, index ) => {
                        return (
                            <Line key={child.id}
                                points={[
                                    centerX + ((index * 2) * PRIMARY_CIRCLE_RADIUS + index * HORIZONTAL_DISTANCE_PAIR) - globalOffsetX,
                                    centerY + PRIMARY_CIRCLE_RADIUS + VERTICAL_DISTANCE_SMALL/2,
                                    centerX + ((index * 2) * PRIMARY_CIRCLE_RADIUS + index * HORIZONTAL_DISTANCE_PAIR) - globalOffsetX,
                                    centerY + PRIMARY_CIRCLE_RADIUS + VERTICAL_DISTANCE_SMALL,
                                ]}
                                offsetX={(children.length - 1) * PRIMARY_CIRCLE_RADIUS + ((children.length - 1) * 0.5) * HORIZONTAL_DISTANCE_PAIR}
                                stroke={'#262626'} strokeWidth={1}
                            />
                        )
                    })
                }
                {children != null && partner == null &&
                    children.map((child, index ) => {
                        return (
                            <Line key={child.id}
                                points={[
                                    centerX - globalOffsetX,
                                    centerY + PRIMARY_CIRCLE_RADIUS + VERTICAL_DISTANCE_SMALL/2,
                                    centerX + ((index * 2) * PRIMARY_CIRCLE_RADIUS + index * HORIZONTAL_DISTANCE_PAIR) - globalOffsetX,
                                    centerY + PRIMARY_CIRCLE_RADIUS + VERTICAL_DISTANCE_SMALL/2,
                                ]}
                                offsetX={(children.length - 1) * PRIMARY_CIRCLE_RADIUS + ((children.length - 1) * 0.5) * HORIZONTAL_DISTANCE_PAIR}
                                stroke={'#262626'} strokeWidth={1}
                            />
                        )
                    })
                }

                {/*children with partner*/}
                {children != null && partner != null &&
                    children.map((child, index ) => {
                        return drawMember(
                            child.id,
                            centerX + ((index * 2) * PRIMARY_CIRCLE_RADIUS + index * HORIZONTAL_DISTANCE_PAIR),
                            centerY + VERTICAL_DISTANCE,
                            ((children.length - 1) * PRIMARY_CIRCLE_RADIUS + ((children.length - 1) * 0.5) * HORIZONTAL_DISTANCE_PAIR) - HORIZONTAL_DISTANCE_PAIR,
                            child.gender === "MALE" ? COLOR_MALE : COLOR_FEMALE,
                            false,
                            child.initials
                        )
                    })
                }
                {children != null && partner != null &&
                    <Line
                        points={[
                            centerX + PRIMARY_CIRCLE_RADIUS + HORIZONTAL_DISTANCE_PAIR/2 - globalOffsetX,
                            centerY,
                            centerX + PRIMARY_CIRCLE_RADIUS + HORIZONTAL_DISTANCE_PAIR/2 - globalOffsetX,
                            centerY + PRIMARY_CIRCLE_RADIUS + VERTICAL_DISTANCE_SMALL/2,
                        ]}
                        stroke={'#262626'} strokeWidth={1}
                    />
                }
                {children != null && partner != null &&
                    children.map((child, index ) => {
                        return (
                            <Line key={child.id}
                                points={[
                                    centerX + ((index * 2) * PRIMARY_CIRCLE_RADIUS + index * HORIZONTAL_DISTANCE_PAIR) - globalOffsetX,
                                    centerY + PRIMARY_CIRCLE_RADIUS + VERTICAL_DISTANCE_SMALL/2,
                                    centerX + ((index * 2) * PRIMARY_CIRCLE_RADIUS + index * HORIZONTAL_DISTANCE_PAIR) - globalOffsetX,
                                    centerY + PRIMARY_CIRCLE_RADIUS + VERTICAL_DISTANCE_SMALL,
                                ]}
                                offsetX={((children.length - 1) * PRIMARY_CIRCLE_RADIUS + ((children.length - 1) * 0.5) * HORIZONTAL_DISTANCE_PAIR) - HORIZONTAL_DISTANCE_PAIR}
                                stroke={'#262626'} strokeWidth={1}
                            />
                        )
                    })
                }
                {children != null && partner != null &&
                    children.map((child, index ) => {
                        return (
                            <Line key={child.id}
                                points={[
                                    centerX - globalOffsetX,
                                    centerY + PRIMARY_CIRCLE_RADIUS + VERTICAL_DISTANCE_SMALL/2,
                                    centerX + ((index * 2) * PRIMARY_CIRCLE_RADIUS + index * HORIZONTAL_DISTANCE_PAIR) - globalOffsetX,
                                    centerY + PRIMARY_CIRCLE_RADIUS + VERTICAL_DISTANCE_SMALL/2,
                                ]}
                                offsetX={((children.length - 1) * PRIMARY_CIRCLE_RADIUS + ((children.length - 1) * 0.5) * HORIZONTAL_DISTANCE_PAIR) - HORIZONTAL_DISTANCE_PAIR}
                                stroke={'#262626'} strokeWidth={1}
                            />
                        )
                    })
                }

                {/*siblings*/}
                {siblings != null &&
                    siblings.map((sibling, index ) => {
                        return drawSibling(
                            sibling.id,
                            centerX + ((index * 2) * SMALL_CIRCLE_RADIUS+ index * HORIZONTAL_DISTANCE_SMALL),
                            centerY,
                            siblings.length * HORIZONTAL_DISTANCE_SMALL + siblings.length * 2 * SMALL_CIRCLE_RADIUS + HORIZONTAL_DISTANCE_SMALL/2,
                            sibling.gender === "MALE" ? COLOR_MALE : COLOR_FEMALE,
                            false,
                            sibling.initials
                        )
                    })
                }
                {siblings != null &&
                    siblings.map((sibling, index ) => {
                        return (
                            <Line key={sibling.id}
                                  points={[
                                      centerX + ((index * 2) * SMALL_CIRCLE_RADIUS + index * HORIZONTAL_DISTANCE_SMALL) - globalOffsetX,
                                      centerY - PRIMARY_CIRCLE_RADIUS - VERTICAL_DISTANCE_SMALL/2,
                                      centerX + ((index * 2) * SMALL_CIRCLE_RADIUS + index * HORIZONTAL_DISTANCE_SMALL) - globalOffsetX,
                                      centerY - SMALL_CIRCLE_RADIUS,
                                  ]}
                                  offsetX={siblings.length * HORIZONTAL_DISTANCE_SMALL + siblings.length * 2 * SMALL_CIRCLE_RADIUS + HORIZONTAL_DISTANCE_SMALL/2}
                                  stroke={'#262626'} strokeWidth={1}
                            />
                        )
                    })
                }
                {siblings != null &&
                    siblings.map((sibling, index ) => {
                        return (
                            <Line key={sibling.id}
                                  points={[
                                      centerX - globalOffsetX,
                                      centerY - PRIMARY_CIRCLE_RADIUS - VERTICAL_DISTANCE_SMALL/2,
                                      centerX + siblings.length * HORIZONTAL_DISTANCE_SMALL + siblings.length * 2 * SMALL_CIRCLE_RADIUS + HORIZONTAL_DISTANCE_SMALL/2 - globalOffsetX,
                                      centerY - PRIMARY_CIRCLE_RADIUS - VERTICAL_DISTANCE_SMALL/2,
                                  ]}
                                  offsetX={siblings.length * HORIZONTAL_DISTANCE_SMALL + siblings.length * 2 * SMALL_CIRCLE_RADIUS + HORIZONTAL_DISTANCE_SMALL/2}
                                  stroke={'#262626'} strokeWidth={1}
                            />
                        )
                    })
                }

                {isLoading === true && drawLoading()}

            </Layer>
        </Stage>
    );
}

export default MiniTree;