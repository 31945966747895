import React from 'react'
import Navbar from "./landingpage/Navbar";
import Footer from "./landingpage/Footer";
import {Box, Typography} from "@mui/material";

const TermsAndConditionsPage = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Navbar />
            <Box padding={3}>
                <Typography variant="h4" mb={2}>Famtrail Terms and Conditions</Typography>
                <Typography variant="h6" mt={2}>Welcome to Famtrail</Typography>
                <Typography>
                    Famtrail is an online service that allows members to build their family trees and upload family
                    photos. The Service is owned and operated by Famtrail (hereafter "Famtrail", 'we', 'our' or 'us').
                    'You' means an adult user, over the age of 13, of the Service (in some jurisdictions the minimum
                    age may be higher, see section "Underage Users" below). Any use of the Famtrail website
                    (famtrail.com or famtrail.* on other top-level domains), and mobile applications for iOS and Android
                    (collectively, the "Website"), and the Service is subject to compliance with the Terms and Conditions
                    in this document. Please read them carefully, because by using the Website you agree to be bound by
                    them and to enter into an agreement with us based on them (this "Agreement" or "Terms"), whether or
                    not you register as a member in Famtrail ("Member").<br/>
                    This Agreement applies to any use of the Service. This Agreement incorporates by reference additional
                    policies and guidelines, including the Privacy Policy, which explains how we collect and use your
                    personal data. Please read all of these documents carefully to understand your rights and
                    responsibilities when using the Service.<br/>
                    If you do not agree with any provision in this Agreement, do not use the Service and the Website.
                    We reserve the right to modify these Terms and Conditions from time to time, in our sole discretion,
                    and such modification shall be effective immediately upon its posting on the Website. You agree to
                    be bound to any changes to this Agreement if you continue to use the Service after any modification
                    is posted.
                </Typography>
                <Typography variant="h6" mt={2}>Our content standards</Typography>
                <Typography>
                    This section explains our standards for user-provided content on the Website, the steps you can take
                    to report content that you believe is illegal or in breach of our standards and the steps we take if
                    we are made aware of such content.<br/>
                    Famtrail allows you, as a Member, to post content into Famtrail Website, and to control and limit
                    access to such content by other users using the "Profile access settings" for each individual profile
                    you create. Please select carefully the information you post to the Website and refrain from posting
                    any illegal or unsuitable content, as detailed below. We will not edit or monitor user-provided content,
                    but we reserve the right to remove any user-provided content which comes to our attention and which,
                    in our sole discretion, breaches this Agreement or any law or regulation.<br/>
                    You shall refrain from posting to the Website, or sending or making available through it, the
                    following content, which is prohibited:<br/>
                    • Photographs, videos and other content which include nudity, sexuality, pornography, indecency,
                    vulgarity, violence or any offensive subject matter.<br/>
                    • Any content which may violate the privacy or breach the confidentiality of other people,
                    including your family members.<br/>
                    • Any personal information, including photos or email addresses, of living individuals (other than
                    your own), if posted without their consent.<br/>
                    • Any information relating to minors or underage children, which identifies them, their personal
                    details or contact information, without the consent of their parent or guardian.<br/>
                    • Any content which doesn’t belong to you or which you do not have a right to use.<br/>
                    • Any content that infringes the intellectual property or other rights of others, including
                    copyrights and trademarks. You should not reproduce content that belongs to others, including
                    photographs, without permission of its owner.<br/>
                    • Any content which is obscene, indecent, defamatory, derogatory, fraudulent, abusive, hateful,
                    false, misleading, inaccurate, malicious, offensive, harassing, threatening, or could incite racial,
                    religious or ethnic hatred.<br/>
                    • Any content that could cause anyone alarm, annoyance, anxiety, embarrassment, offense or distress.<br/>
                    • Any content that could prejudice any active legal proceedings of which you are aware; is unlawful
                    or promotes unlawful activity.<br/>
                    • Any computer virus, trojan or other malicious code.<br/>
                    • Any content that violates our Privacy Policy.<br/>
                    • Any content that is in any other way unlawful.<br/>
                    • Any content that we reasonably deem to be inappropriate<br/>
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Reporting content</Typography>
                <Typography>
                    If you believe that any content available through the Website is unlawful or otherwise breaches our
                    standards described above ('Improper Content'), please report it to info@famtrail.com along with
                    your name (unless it’s exempt under applicable law).<br/>
                    When you report Improper Content, then by submitting your report you confirm your honest belief
                    that the information and allegations in the report are accurate.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Our content moderation</Typography>
                <Typography>
                    In response to a report about Improper Content, we may take any of the following actions:<br/>
                    • remove content that we reasonably believe breaches our standards;<br/>
                    • suspend or terminate any person’s access to the Website or any part of it;<br/>
                    • take no action, If we reasonably believe the content does not breach our standards<br/>
                    We will notify you of the outcome of our review. If we decide to remove content we may, where
                    relevant, also notify the person who uploaded that content, or any person whose access to the Website
                    we suspend or terminate, as applicable.<br/>
                    If you don’t agree with our moderation decision, you can appeal the decision, by responding to the
                    email informing you of our decision.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Disclosures</Typography>
                <Typography>
                    We may need to disclose information about a person posting illegal content, if required by law.
                </Typography>
                <Typography variant="h6" mt={2}>Your use of the Website or Service</Typography>
                <Typography>
                    When using the Website or Service, you must comply with our content standards (see above), and you
                    must not:<br/>
                    • Impersonate anyone else, or use a false name.<br/>
                    • Hold yourself out as an employee or representative of Famtrail or its affiliates.<br/>
                    • Stalk or harass other users without their consent or after any has been withdrawn explicitly or implicitly.<br/>
                    • Send commercial emails, spam emails, unsolicited bulk emails or any other inappropriate email.<br/>
                    • Do anything that might damage our name or reputation.<br/>
                    • Restrict or inhibit any other person from using the Website.<br/>
                    • Exploit for commercial purposes any portion of the Website.<br/>
                    • Perform any activity that requires any form of payment from other people.<br/>
                    • Advertise or offer products or services.<br/>
                    • Post links to any websites outside the Famtrail Website.<br/>
                    • Use someone else’s credit card or payment method without their permission.<br/>
                    • Conduct or participate in a legal or forensic investigation.<br/>
                    You must not copy or store electronically any part of the Website or its contents, or make available,
                    distribute, or sell any part of the Website or its contents, or systematically download content and
                    data from or through the Website to create or populate another database. Scraping, crawling or
                    harvesting of any pages and any content from the Website is strictly prohibited. Running macros or
                    scripts to automate activities on the Website, such as photo upload is strictly prohibited. Violation
                    of any of these prohibitions may result in the immediate termination of your membership and may result
                    in legal action against you.<br/>
                    Reselling any Famtrail products is strictly prohibited.<br/>
                    Any activity on the Website, that is brought to our attention and is in violation of applicable law,
                    as determined in our sole discretion, may be brought to the attention of the proper authorities.
                </Typography>
                <Typography variant="h6" mt={2}>Guidelines for Famtrail members</Typography>
                <Typography>
                    The Famtrail site is a place where members share content with other members. This may create conflicts
                    between members. The following guidelines apply to all Famtrail members, including you:<br/>
                    • Members must obtain permission from living family members before posting information about them,
                    and if they still do so without permission, it is the responsibility of the family tree's owner –
                    the Member who created the family tree.<br/>
                    • If we receive a complaint about information posted on a family site about a person or close
                    relative of a person, against this person's wish, we reserve the right to delete that information
                    even if the owner of the family tree wants to keep the information there. In case of a dispute with
                    the Member who owns the tree, we are allowed to ban this Member and remove them from the Website at
                    our discretion.<br/>
                    • The right of a person to request not to be listed in a family tree is stronger than the right of
                    the owner of the family tree to list that person in it.<br/>
                    • If you encounter personal information on the Website that was entered by another member that you
                    want removed, email a request to info@famtrail.com. We will try to process your request within
                    one week. We may attempt to first resolve the dispute amicably by contacting the member who entered
                    the information or is responsible for it, and give them time to respond. We will cooperate with you
                    to make sure that the information you want to remove is eventually removed.<br/>
                    • Copying information or photos from other family sites or from external websites without permission
                    of their owners is prohibited and members who do this may be banned from the Website at our discretion.<br/>
                    • Inviting other people to be members in your family tree, or approving their membership requests,
                    will give them access to the family tree and they might copy parts of it. If that happens, it is the
                    responsibility of the member who made the invitation or approved the membership request, and not ours.
                    If an invitation or membership request approval were made in error, the family site owner can remove
                    that member from the family tree to revoke their access.<br/>
                </Typography>
                <Typography variant="h6" mt={2}>Copyrights</Typography>
                <Typography>
                    By posting content on the Website, you grant us a non-exclusive, royalty-free, worldwide, license
                    to host, display, process, transfer and distribute such content. All content on the Website, except
                    for user-submitted content is owned by us or by third parties who granted us a limited license in
                    respect of such content (in the cases where such license was necessary). Content posted on family
                    trees is owned by the person who created the content, is under the control of the respective family
                    tree manager, and is hosted by us under this Agreement.<br/>
                    The trademarks, trade names, service marks, logos, and other source-identifying features are
                    proprietary to their respective owners, and our use thereof does not imply any affiliation with or
                    endorsement by such owners.<br/>
                    Famtrail is protected by copyright, international conventions, and other applicable copyright laws.<br/>
                    You may not modify the code powering our Website, our Service and our software in any way.
                    You will not undertake or authorize any reproduction, modification, creation of derivative works,
                    reverse engineering, decompiling, disassembling or hacking of any of parts of our Website, Service or software.
                </Typography>
                <Typography variant="h6" mt={2}>Digital Millennium Copyright Act ("DMCA")</Typography>
                <Typography>
                    It is our policy to respond to notices of alleged copyright infringement that comply with the Digital
                    Millennium Copyright Act of 1998 ("DMCA"). The DMCA provides remedies for copyright owners who believe
                    that material appearing over the Internet infringes their rights under United States copyright law.
                    To learn more about the DMCA, see <a href={"https://www.copyright.gov/legislation/dmca.pdf"}>www.copyright.gov/legislation/dmca.pdf</a><br/>
                    If you are a copyright owner or an agent thereof, and you believe in good faith that any content
                    hosted on the Website infringes your copyrights, then you may submit a notification pursuant by email
                    to: info@famtrail.com<br/>
                    Notices must meet the then-current statutory requirements imposed by the DMCA, and should include the
                    following information:<br/>
                    • A physical or electronic signature of a person authorized to act on behalf of the owner of an
                    exclusive right that is allegedly infringed by the material(s).<br/>
                    • Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted
                    works at the Website are covered by a single notification, a representative list of such works.<br/>
                    • Identification of the material that is claimed to be infringing or to be the subject of infringing
                    activity and that is to be removed or access to which is to be disabled, and information reasonably
                    sufficient to permit Famtrail to locate the material (providing URLs is the best way to help us
                    locate content quickly).<br/>
                    • Contact information to allow Famtrail to contact the complaining party, such as email address,
                    mailing address and telephone number.<br/>
                    • A statement that the complaining party has a good faith belief that use of the material in the
                    manner complained of is not authorized by the copyright owner, its agent, or the law.<br/>
                    • A statement that the information in the notification is accurate, and under penalty of perjury,
                    that the complaining party is authorized to act on behalf of the owner of an exclusive right that is
                    allegedly infringed.<br/>
                    If you are unsure whether you hold rights to a particular work or if the material you are reporting
                    is in fact infringing, please consult an attorney or another adviser, as Famtrail cannot provide legal
                    advice. Note that there can be penalties for false claims under the DMCA.
                </Typography>
                <Typography variant="h6" mt={2}>Marketing of our services</Typography>
                <Typography>
                    By signing up to the Website, you agree that we may use your contact information to offer you
                    Famtrail products or services which may complement the product or service you originally signed up
                    to use. Such promotional offers will be made via email.<br/>
                    If you do not want to receive marketing emails, you may opt out at any time by using the
                    unsubscribe link listed in the email or by setting your Email Preferences in the Website.
                </Typography>
                <Typography variant="h6" mt={2}>Disclaimers, Limitation on Liability, and Indemnity</Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Disclaimers</Typography>
                <Typography>
                    a. To the maximum extent permitted by applicable law, in no event shall Famtrail, its officers,
                    representatives, employees, licensors and affiliates be responsible for any incorrect or inaccurate
                    information in connection with the Service, whether caused by Website members, users, our corporate
                    partners, advertisers or by any of the equipment or programming utilized in the operation of the
                    Website or the Service.<br/>
                    b. Famtrail and its licensors and affiliates are not responsible for the conduct, whether online or
                    offline, of any website member or user. To the maximum extent permitted by applicable law, in no
                    event shall we be responsible for any loss or damage, including lost data, lost information,
                    personal injury or death, or privacy implication resulting from anyone’s use of the Website or the
                    Service, any information posted on the Website or transmitted to members or other users, or any
                    interactions between members or other users of the Website, whether online or offline.<br/>
                    c. The information, products, and services included within the Service and the Website may include
                    inaccuracies or typographical errors.<br/>
                    d. Famtrail assumes no responsibility for any error, omission, interruption, deletion, defect, delay,
                    communication line failure, theft or destruction or unauthorized access or alteration of user or member
                    information. Famtrail is not responsible for any problems or technical malfunction of any
                    communication networks, online computer systems, servers, computer equipment, software, e-mail systems,
                    technical problems, traffic congestion on the internet, or any combination thereof. Famtrail and its
                    licensors and affiliates are not responsible for any injury or damage to any computer equipment
                    belonging to any Website member or user, or any other person related to or resulting from use of the
                    Website, viewing, or downloading any materials from the Website, or otherwise in connection with the
                    Service.<br/>
                    e. The Website and the Service are provided "as-is" and "as available" and, except as otherwise
                    prohibited by applicable law, Famtrail expressly disclaims any warranty of any kind, expressed or
                    implied, including, without limitation, any warranty of merchantability, fitness for a particular
                    purpose, title and non-infringement and satisfactory quality, or that the Website will be available
                    at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected;
                    or that the Website is free of viruses or other harmful components. We do not guarantee any specific
                    results from use of the Service. The Website may contain links to third-party websites that are not
                    owned or controlled by us. We have no control over, and assume no responsibility for, the content,
                    policies, or practices of any third-party websites or services. You expressly relieve us from any
                    and all liability arising from your use of any third-party website or services. We do not warrant,
                    endorse, guarantee, or assume responsibility for any specific course of action, resources, tests,
                    physician or other healthcare providers, drugs, medical devices or other procedures or opinions,
                    or any product or service advertised or offered by a third party through the Service or any
                    hyperlinked website, or featured in any banner or other advertising, or other information that may be
                    mentioned on the Website. We will not be a party to or in any way monitor any transaction between you
                    and third-party providers of products or services. We and our third-party suppliers make no
                    representations concerning the suitability, reliability or accuracy of the content provided by the
                    Website for any purpose. In no case will we or our third-party providers be liable for any direct,
                    indirect, punitive, special or other damages including, without limitation, lost profits, loss of
                    data or any other damage in contract, tort, equity or any other legal theory, even if advised of the
                    possibility thereof.<br/>
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Limitation on Liability</Typography>
                <Typography>
                    In no event shall Famtrail or its third-party suppliers be liable to you or any third party for any
                    indirect, consequential, exemplary, incidental, special or punitive damages, including damages
                    arising from your use of the Service or inability to use the Service or any portion of it, even if
                    we have been advised of the possibility of such damages. Notwithstanding anything to the contrary
                    herein, our (or our third-party suppliers') liability to you or any third party for any cause
                    whatsoever and regardless of the form of the action, will at all times be limited to the amount you
                    paid to us for the Service during the term of membership (if any).<br/>
                    We assume no liability resulting from any (i) errors, mistakes, or inaccuracies of content;
                    (ii) personal injury or property damage, of any nature whatsoever, resulting from your access to
                    and use of the Service; (iii) any unauthorized access to or use of our servers and/or any and all
                    information stored therein; (iv) any interruption or cessation of transmission to or from the Service;
                    (v) any bugs, viruses, trojan horses, or the like, which may be transmitted through the Service;
                    and/or (vi) any loss or damage of any kind incurred as a result of your use of the Service, whether
                    based on warranty, contract, tort, or any other legal theory. The foregoing limitations of liability
                    shall apply to the fullest extent permitted by law in the applicable jurisdiction.<br/>
                    Because some states/jurisdictions do not allow the exclusion or limitation of liability for
                    consequential or incidental damages, this limitation may not apply in part to you. IF YOU ARE
                    DISSATISFIED WITH ANY PORTION OF THIS SERVICE, OR WITH ANY OF THE TERMS OF THIS AGREEMENT, YOUR SOLE
                    AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Indemnity</Typography>
                <Typography>
                    You agree to indemnify and hold Famtrail, its subsidiaries, employees, directors, licensors, managers,
                    affiliates and any third party acting on our behalf, and their respective officers, and employees,
                    harmless from any loss, liability, claim, or demand, including reasonable attorneys' fees, made by
                    any third party due to or arising out of your use of or access to the Service, your violation of
                    this Agreement and/or any breach of your representations set forth above or if any information that
                    you post on the Website or otherwise submit to us or through the Service causes us to be liable
                    to any third party.
                </Typography>
                <Typography variant="h6" mt={2}>Additional Member Terms</Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Registration and Security</Typography>
                <Typography>
                    A. As part of the registration process, you will submit your email address and full name, and select
                    a password. You are required to pick a password for the Website that is unique, i.e. that you are
                    not using on any other service. You must provide us with your real full name and email address.
                    Failure to do so constitutes a breach of this Agreement. You must not (i) submit a name or email
                    address of another person with the intent to impersonate that person; (ii) use the name or email
                    address of any person other than yourself without their authorization; or (iii) use a name or email
                    address that we, in our sole discretion, deem improper or offensive.<br/>
                    B. You must be 13 years or older to use the Service, unless the laws of your location or place or
                    residence require a higher minimum age (see section "Underage Users" below). Registering to the
                    Website shall be deemed as confirmation that you are over the Minimum Age. If you are over the
                    Minimum Age but under the age of 18, you will be requested to submit written consent from a parent
                    or legal guardian to use the Service (for more information, see section "Minors" below).<br/>
                    C. You are responsible for maintaining the confidentiality of your password and for all activity
                    on the Website made using your password. Giving your password to others for accessing to the Website
                    is strictly prohibited and shall constitute a breach of this Agreement. We will not require you to
                    reveal your password to any of our representatives. You shall notify us by email at
                    info@famtrail.com of any suspected or known unauthorized use of your account, or any suspected or
                    known breach of security. Any fraudulent, abusive, or otherwise illegal activity may be grounds
                    for termination of your membership, at our sole discretion, and you may be reported to the
                    appropriate law enforcement agencies.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Modifications to this Agreement and the Service</Typography>
                <Typography>
                    We retain the right, at our sole discretion, to modify this Agreement or the Service at any time.
                    Changes in Service will be posted on the Website. If any portion of this Agreement or any change of
                    this Agreement or of the Service is unacceptable to you or will cause you to no longer be in
                    compliance with this Agreement, you should discontinue use of the Service. Continued use of the
                    Service now or following changes in this Agreement means that you have accepted and are bound by
                    the changes. It is important that you check the current version of the Agreement from time to time
                    and ensure you are updated as to any changes.<br/>
                    Whenever this Agreement is modified in substance, the label last modified will be displayed
                    prominently in the bottom of the page.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Underage Users</Typography>
                <Typography>
                    We require individuals to be at least 13 years old before they can create an account on Famtrail,
                    but in some jurisdictions, the minimum age limit is higher (the "Minimum Age").<br/>
                    In observance of laws in Spain, Israel, Canada and South Korea, we require individuals living there
                    to be at least 14 years old before they can create an account and use the Service, and in California
                    at least 16 years old. To report an account registered for an underage child to us, email the details
                    to us at info@famtrail.com. We will promptly delete the account of any child under the Minimum Age
                    that is reported to us.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Minors</Typography>
                <Typography>
                    If you are above the Minimum Age but under the age of 18, you can use the Service, provided that you
                    obtained the consent of your parent or guardian for the use of the Service and
                    agree, in full, to the terms of this Agreement, either on your own or through such agreement being
                    provided by and through your parent or guardian. Minors must not use the Service without Parental
                    Consent until they reach the age of 18 or above.
                </Typography>
                <Typography variant="h6" mt={2}>Class Action Waiver and Arbitration</Typography>
                <Typography>
                    You agree that, by entering into this Agreement, each of you and us waives the right to participate
                    in a class action claim.
                </Typography>
                <Typography variant="h6" mt={2}>Entire Agreement</Typography>
                <Typography>
                    This Agreement, together with the <a href={"/privacy-policy"}>Privacy Policy</a> and any other legal
                    notices or terms published by Famtrail on the Website (as applicable), shall constitute the entire
                    agreement between you and Famtrail concerning the Website and the Service. Our Failure to exercise
                    or enforce any right or provision of this Agreement and/or the Informed Consent, or portion thereof,
                    shall not be deemed as a waiver of such right or provision. The section titles in this Agreement are
                    for convenience only and have no legal or contractual effect. This Agreement and the Informed Consent
                    operate to the fullest extent permissible by law. If any provision of this Agreement or the Informed
                    Consent is unlawful, void or unenforceable, that provision is deemed severable and does not affect
                    the validity and enforceability of any remaining provisions or portions. This Agreement, and any
                    rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned
                    by us without restriction. Any attempted transfer or assignment in violation hereof shall be void.
                </Typography>
                <Typography variant="h6" mt={2}>Digital Services Act</Typography>
                <Typography>
                    For the purpose of Article 24(2) of the European Digital Services Act ("DSA"), during the last six
                    months, the average monthly active recipients of our Service in the European Union amounted to a few
                    thousand. This amount is significantly lower than the threshold of 45 million average monthly active
                    users required to classify the service as a "Very Large Online Platform" (VLOP) under Article 24(2)
                    of the DSA.<br/>
                    Famtrail has appointed Mr. Filip Jelev as its representative in the EU in connection with the DSA.
                    He can be emailed at filip@famtrail.com
                </Typography>
                <Typography>
                    <br/><br/><br/>
                    Last modified: May 30, 2024
                </Typography>
            </Box>
            <Footer />
        </Box>
    )
}

export default TermsAndConditionsPage