import React from 'react'

const LogOutIcon = (props) => {
    return (
        <img src={props.selected ? "/assets/icons/logout-icon.svg" : "/assets/icons/logout-icon.svg"}
             alt="Sign out"
             style={props.style}
        />
    )
}

export default LogOutIcon