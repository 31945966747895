import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Logo from "./LogoBeta";
import {useAuth} from "./context/AuthContext";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {famtrailApi} from "../services/FamtrailApi";
import {handleLogError, parseJwt} from "../services/Helpers";
import {Alert, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function SignUp() {

    const Auth = useAuth()
    const isLoggedIn = Auth.userIsAuthenticated()

    const allowRegister = true;

    const {i18n} = useTranslation();

    const [language, setLanguage] = useState(i18n.resolvedLanguage);

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [gender, setGender] = useState('')
    // const [betaCode, setBetaCode] = useState('')
    const [optIn, setOptIn] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [searchParams] = useSearchParams();

    const [invitationInfo, setInvitationInfo] = React.useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                if (searchParams.get("c") != null) {
                    const response = await famtrailApi.getInvitationInfo(searchParams.get("c"));
                    setInvitationInfo(response.data);
                }
            } catch (error) {
                handleLogError(error)
            } finally {

            }
        }
        fetchData();
    }, [])

    const handleInputChange = (name, value) => {
        if (name === 'firstName') {
            setFirstName(value)
        } else if (name === 'lastName') {
            setLastName(value)
        } else if (name === 'email') {
            setEmail(value)
        } else if (name === 'password') {
            setPassword(value)
        } else if (name === 'gender') {
            setGender(value)
        // } else if (name === 'betaCode') {
        //     setBetaCode(value)
        }
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function isValidPassword(pass) {
        return pass.length >= 8;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (searchParams.get("c") != null) {
            if (!(email && password)) {
                setIsError(true)
                setErrorMessage(t('signUpPage.error.allFieldsRequired'))
                return
            }
        } else {
            if (!(firstName && lastName && email && password && gender)) {
                setIsError(true)
                setErrorMessage(t('signUpPage.error.allFieldsRequired'))
                return
            }
        }

        if (!isValidEmail(email)) {
            setIsError(true)
            setErrorMessage(t('signUpPage.error.invalidEmail'))
            return
        }

        if (!isValidPassword(password)) {
            setIsError(true)
            setErrorMessage(t('signUpPage.error.invalidPassword'))
            return
        }

        setEmail(email.toLowerCase());

        let user = null;
        if (searchParams.get("c") != null) {
            user = {firstName: "temp", lastName: "temp", email, password, optIn, gender: "male", code: searchParams.get("c"), language: language}
        } else {
            user = {firstName, lastName, email, password, optIn, gender, code: null, language: language/*, betaCode: betaCode*/}
        }

        try {
            const response = await famtrailApi.signup(user)
            const { accessToken } = response.data
            const data = parseJwt(accessToken)
            const authenticatedUser = { data, accessToken }

            Auth.userLogin(authenticatedUser)

            setFirstName('')
            setLastName('')
            setPassword('')
            setEmail('')
            setGender('')
            // setBetaCode('')
            setOptIn(false)
            setIsError(false)
            setErrorMessage('')

            navigate("/app");
        } catch (error) {
            handleLogError(error)
            if (error.response && error.response.data) {
                const errorData = error.response.data
                let errorMessage = 'Invalid fields'
                if (errorData.status === 409) {
                    errorMessage = t('signUpPage.error.accountExists') //errorData.message
                } else if (errorData.status === 400) {
                    //errorMessage = t('signUpPage.error.invalidBetaCode')
                    errorMessage = errorData.errors[0].defaultMessage
                }
                setIsError(true)
                setErrorMessage(errorMessage)
            }
        }
    }

    if (isLoggedIn) {
        return <Navigate to={'/app'} />
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Logo style={{display: "block", width: 130}} />
                <Typography component="h1" variant="h5" mt="20px">
                    {t('signUpPage.signUp')}
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        {/*{invitationInfo == null &&*/}
                        {/*    <Grid item xs={12}>*/}
                        {/*        <Alert severity="warning" variant="filled">{t('signUpPage.newAccountRequiresInvitation')}</Alert>*/}
                        {/*    </Grid>*/}
                        {/*}*/}
                        {invitationInfo == null &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label={t('signUpPage.firstName')}
                                    autoFocus
                                    inputProps={{ maxLength: 255 }}
                                    value={firstName}
                                    onChange = {(e) => {
                                        handleInputChange(e.target.name, e.target.value);
                                    }}
                                />
                            </Grid>
                        }
                        {invitationInfo == null &&
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label={t('signUpPage.lastName')}
                                    name="lastName"
                                    autoComplete="family-name"
                                    inputProps={{ maxLength: 255 }}
                                    value={lastName}
                                    onChange = {(e) => {
                                        handleInputChange(e.target.name, e.target.value);
                                    }}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label={t('signUpPage.emailAddress')}
                                name="email"
                                autoComplete="email"
                                inputProps={{ maxLength: 255 }}
                                value={email}
                                onChange = {(e) => {
                                    handleInputChange(e.target.name, e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label={t('signUpPage.password')}
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                inputProps={{ maxLength: 255 }}
                                value={password}
                                onChange = {(e) => {
                                    handleInputChange(e.target.name, e.target.value);
                                }}
                            />
                        </Grid>
                        {invitationInfo == null &&
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="gender-select-label" required>{t('signUpPage.gender')}</InputLabel>
                                    <Select
                                        labelId="gender-select-label"
                                        id="gender-select"
                                        name="gender"
                                        value={gender}
                                        label={t('signUpPage.gender')}
                                        onChange = {(e) => {
                                            handleInputChange(e.target.name, e.target.value);
                                        }}
                                    >
                                        <MenuItem value={'male'}>{t('signUpPage.gender_male')}</MenuItem>
                                        <MenuItem value={'female'}>{t('signUpPage.gender_female')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {isError && <Alert severity="error" variant="filled">{errorMessage}</Alert>}
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        disabled={!allowRegister}
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 3, mb: 2, textTransform: 'none', boxShadow: 'none' }}
                    >
                        {t('signUpPage.signUpButton')}
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Typography>
                                {t('signUpPage.agreeText')} <Link href="/terms-of-use" target={"_blank"} underline="hover">{t('signUpPage.termsOfUse')}</Link>
                                {t('signUpPage.pleaseRead')} <Link href="/privacy-policy" target={"_blank"} underline="hover">{t('signUpPage.privacyPolicy')}</Link>.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end" mt="20px" mb="100px">
                        <Grid item>
                            <Link href="/signin" underline="hover">
                                {t('signUpPage.haveAccount')}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}