import React from 'react'

const FeedbackIcon = (props) => {
    return (
        <img src={props.selected ? "/assets/icons/feedback_a.svg" : "/assets/icons/feedback.svg"}
             alt="Feedback"
             style={props.style}
        />
    )
}

export default FeedbackIcon