import React from 'react'
import {Link} from "@mui/material";

const LogoBeta = (props) => {
    return (
        <Link href="/">
            <img src="/assets/famtrail-logo-full-beta.svg"
                 alt="Famtrail"
                 style={props.style}
            />
        </Link>
    )
}

export default LogoBeta