import React, {useEffect} from 'react'
import {Box, Button, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useSearchParams} from "react-router-dom";
import {famtrailApi} from "../services/FamtrailApi";
import {handleLogError} from "../services/Helpers";
import {useTranslation} from "react-i18next";

const PasswordChanged = () => {
    const {t} = useTranslation();

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5" mt="20px">
                    {t('passwordChanged.title')}
                </Typography>
                <Box textAlign={"center"}>
                    <Typography mt="10px">
                        {t('passwordChanged.text')}
                    </Typography>
                </Box>
                <Button href="/signin" variant="contained" size="large" sx={{ maxWidth: "160px", alignSelf: "center", textTransform: 'none', boxShadow: 'none', marginTop: '20px'}}>
                    {t('passwordChanged.signInButton')}
                </Button>
            </Box>

        </Container>
    )
}

export default PasswordChanged