import React from 'react'

const SettingsIcon = (props) => {
    return (
        <img src={props.selected ? "/assets/icons/settings-icon.svg" : "/assets/icons/settings-icon.svg"}
             alt="Settings"
             style={props.style}
        />
    )
}

export default SettingsIcon