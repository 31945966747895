import React from 'react';
import {Suspense} from "react";
import LandingPage from "./components/LandingPage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./components/private/HomePage";
import AboutUsPage from "./components/AboutUsPage";
import SignIn from "./components/SignIn";
import {AuthProvider} from "./components/context/AuthContext";
import PrivateRoute from "./PrivateRoute";
import SignUp from "./components/SignUp";
import PageLoader from "./components/PageLoader";
import InvitationPage from "./components/InvitationPage";
import VerificationMailSent from "./components/VerificationMailSent";
import MailVerified from "./components/MailVerified";
import VerificationPending from "./components/VerificationPending";
import ForgotPassword from "./components/ForgotPassword";
import PasswordResetSent from "./components/PasswordResetSent";
import PasswordReset from "./components/PasswordReset";
import PasswordChanged from "./components/PasswordChanged";
import CookiePolicyPage from "./components/CookiePolicyPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import TermsAndConditionsPage from "./components/TermsAndConditionsPage";

function App() {

    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route exact path='/' element={<LandingPage />} />
                    <Route exact path='/aboutus' element={<AboutUsPage />} />
                    <Route exact path='/cookie-policy' element={<CookiePolicyPage />} />
                    <Route exact path='/privacy-policy' element={<PrivacyPolicyPage />} />
                    <Route exact path='/terms-of-use' element={<TermsAndConditionsPage />} />
                    <Route exact path='/email-verification-sent' element={<VerificationMailSent />} />
                    <Route exact path='/verify' element={<MailVerified />} />
                    <Route exact path='/verification-pending' element={<VerificationPending />} />
                    <Route exact path='/forgot-password' element={<ForgotPassword />} />
                    <Route exact path='/password-reset-sent' element={<PasswordResetSent />} />
                    <Route exact path='/password-reset' element={<PasswordReset />} />
                    <Route exact path='/password-changed' element={<PasswordChanged />} />
                    <Route path='/signin' element={<SignIn />} />
                    <Route path='/signup' element={<SignUp />} />
                    <Route path='/inv' element={<InvitationPage />} />
                    <Route path="/app" element={<PrivateRoute><HomePage /></PrivateRoute>} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default function WrappedApp() {
    return (
        <Suspense fallback={<PageLoader />}>
            <App />
        </Suspense>
    )
};