import React from 'react'
import Navbar from "./landingpage/Navbar";
import Footer from "./landingpage/Footer";
import {Box, Typography} from "@mui/material";

const CookiePolicyPage = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Navbar />
            <Box padding={3}>
                <Typography variant="h4" mb={2}>Famtrail Cookie Policy</Typography>
                <Typography>
                    This Cookie Policy explains what cookies and similar technologies are, the different types of
                    cookies that we use, how we use them when you visit our Website, and how you can control our use
                    of cookies. Any capitalized term used and not otherwise defined below has the meaning assigned
                    to it in our <a href={"/privacy-policy"}>Privacy Policy</a> or <a href={"/terms-of-use"}>Terms and Conditions</a>.
                </Typography>
                <Typography variant="h6" mt={2}>What are cookies?</Typography>
                <Typography>
                    Cookies are small data files containing a unique identifier that are placed on your browsing
                    device (such as a computer or mobile device) or stored on the web server when you visit some
                    websites, including our website.
                </Typography>
                <Typography variant="h6" mt={2}>Which similar technologies are used?</Typography>
                <Typography>
                    Similar automated means of data collection technologies, include Pixels (which are also known
                    as web beacons or action tags), SDKs (which are used on mobile apps) and local browser storage
                    technologies.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Pixels</Typography>
                <Typography>
                    A Pixel is a tiny image that may be found within web pages and emails, that allow us to gather
                    information about visitors on our website, how they browse, what type of ads they click on,
                    which email they arrived from, etc. Pixels can also allow us and third parties to place cookies
                    on your browser.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>SDKs</Typography>
                <Typography>
                    A Software Development Kit is a downloadable software package used in mobile applications.
                    A typical SDK may contain APIs which are pre-defined pieces of code that help you perform common
                    programming tasks on the platform or other tools that help us do things like debug, build, run,
                    and test our mobile apps.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Local Storage</Typography>
                <Typography>
                    Local storage enables a website or application to store information locally on your device(s).
                    Local storage may be used to improve your experience, for example, by remembering your preferences,
                    and speeding up site functionality.
                </Typography>
                <Typography variant="h6" mt={2}>What are cookies and similar technologies used for?</Typography>
                <Typography>
                    Cookies and similar technologies enable us to differentiate between you and other visitors, and
                    store information about your visit, which can both make it easier to visit the website again and
                    make the website more useful to you. This information is notably used to improve the functionality
                    of the services provided on the Website; for data analysis purpose; to detect and defend against
                    fraudulent, abusive, or unlawful activity; to avoid displaying certain messages to you more than
                    once; to save your login details so you won’t need to re-enter them each time you wish to log in,
                    or to remember the display language you previously selected so you won't need to select it each
                    time you visit the Website. Cookies and similar technologies are also used to make the advertising
                    you see online more relevant to your interests. Some cookies are associated with your account and
                    personal information (as defined in our <a href={"/privacy-policy"}>Privacy Policy</a>), while others are not.
                    Cookies and similar
                    technologies collect, retain, and share with third parties online personal identifiers and your
                    browsing activity on our website. "Online personal identifiers" that may be shared with
                    third-parties include data that may identify you and include, for example, device fingerprints,
                    MAC addresses, advertising identifiers, and click ID. For example, when you arrive at our Website
                    from an ad you have seen on another website, we may share back the click ID with the website you
                    came from. In certain instances, an IP address may also constitute an "online personal identifier."
                </Typography>
                <Typography variant="h6" mt={2}>What are the different types of cookies?</Typography>
                <Typography>
                    Famtrail uses both "first-party cookies" and "third-party cookies". First-party cookies are cookies
                    that are placed directly by Famtrail. Third-party cookies are placed by our service providers or
                    business partners on our website, and are used for a variety of purposes, including site analytics,
                    advertising, and social media features. When the cookies are deployed, the third parties will use
                    the information collected through the cookies subject to their own privacy policies. See the list
                    below for details about the use of third-party cookies and similar technologies on our website.
                    Further, Famtrail uses both "sessions cookies" and "persistent cookies". Session cookies are only
                    stored on your browser during a single browsing session to the site and are automatically deleted
                    when you close your browser. Persistent cookies remain on your device until they are set to expire
                    or until you or your browser delete the cookies.
                </Typography>
                <Typography variant="h6" mt={2}>What types of cookies are used on our website and why?</Typography>
                <Typography>
                    We uses the following categories of cookies on our website. Note that some of the below cookies
                    may not be active in some states or for some users.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Mandatory cookies</Typography>
                <Typography>
                    enable the website to function and display properly and enable you to move around this site and
                    use its features. They are also used to prevent security risks, set your privacy preferences, etc.
                    Without these cookies, services that are necessary for you to be able to use our website, such as
                    ensuring that you stay logged on cannot be provided.
                </Typography>
                <Typography fontWeight="fontWeightBold" mt={2}>Analytics cookies</Typography>
                <Typography>
                    which collect information on how you and other users interact with the website. They allow us to
                    count visits and traffic sources to measure and improve our site's performance. These cookies are
                    used to improve our website performance, understand what interests our users, and measure how
                    effective our content is.
                </Typography>
                <Typography variant="h6" mt={2}>How to manage cookies via your browser settings?</Typography>
                <Typography>
                    You may also set your browser to notify you when you receive a cookie. Many web browsers also
                    allow you to block cookies. To do this, follow the instructions provided by your browser (usually
                    located within the "Help", "Tools" or "Edit" settings). Please note that if you set your browser
                    to disable cookies entirely you will not be able to access secure areas of the website and may
                    experience some loss of function in the services we provide.
                </Typography>
                <Typography variant="h6" mt={2}>Will this Cookie Policy be amended?</Typography>
                <Typography>
                    We reserve the right to amend this Cookie Policy from time to time. If we decide to modify our
                    Cookie Policy, we will issue an updated version of this Cookie Policy with an updated date legend,
                    and if the changes are material, we will also notify you via email or by other appropriate means.
                    <br/><br/><br/>
                    Last modified: May 30, 2024
                </Typography>
            </Box>
            <Footer />
        </Box>
    )
}

export default CookiePolicyPage