import React from 'react'

const TreeIcon = (props) => {
    return (
        <img src={props.selected ? "/assets/icons/tree_a.svg" : "/assets/icons/tree.svg"}
             alt="Family tree"
             style={props.style}
        />
    )
}

export default TreeIcon