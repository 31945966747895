import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette:{
        primary:{
            main: "#26A399",
            light: "#93D0CB",
            dark: "#006E66",
            black: "#0F0F0F",
            subtle: "#eef8f7"
        }
    }
})