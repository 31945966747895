import React from 'react'
import {Avatar, Box, Card, FormControl, MenuItem, Select, Stack, Typography} from "@mui/material";
import {famtrailApi} from "../../services/FamtrailApi";
import {handleLogError} from "../../services/Helpers";
import {useTranslation} from "react-i18next";

const AccessSettingBox = (props) => {

    const {t} = useTranslation();

    const handleChange = async (event) => {
        const data = {accessLevel: event.target.value, userMemberId: props.userMemberId};
        try {
            await famtrailApi.changeAccessRights(props.user, props.memberId, data);
            props.handleSetSelected(props.memberId);
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    };

    return (
        <Box sx={{ minWidth: 275 }}>

            <Card variant="outlined" style={{marginTop: "15px", padding: "10px"}}>
                <Stack
                    direction={'column'}
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                >
                    {props.profilePicture == null &&
                        <Avatar
                            sx={{
                                width: 64,
                                height: 64,
                                ...(props.gender === 'MALE' && {
                                    bgcolor: '#2681A3',
                                }),
                                ...(props.gender !== 'MALE' && {
                                    bgcolor: '#D8416F',
                                })
                            }}
                        >
                            {props.initials}
                        </Avatar>
                    }
                    {props.profilePicture != null &&
                        <Avatar
                            sx={{ width: 64, height: 64 }}
                            src={props.profilePicture}
                        />
                    }
                    <Typography>{props.firstName + " " + props.lastName}</Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            id="access-level-select"
                            defaultValue={props.accessLevel ?? ""}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Access level' }}
                            autoWidth
                        >
                            <MenuItem value={'PRIVATE'}>{t('accessSettingsBox.private')}</MenuItem>
                            <MenuItem value={'VIEW'}>{t('accessSettingsBox.view')}</MenuItem>
                            <MenuItem value={'EDIT'}>{t('accessSettingsBox.edit')}</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </Card>
        </Box>
    )
}

export default AccessSettingBox