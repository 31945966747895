import React from 'react'

const SearchIcon = (props) => {
    return (
        <img src="/assets/icons/search.svg"
             alt="Search"
             style={props.style}
        />
    )
}

export default SearchIcon