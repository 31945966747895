import React from 'react'
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "./icons/HomeIcon";
import ListItemText from "@mui/material/ListItemText";
import TreeIcon from "./icons/TreeIcon";
import FamilyIcon from "./icons/FamilyIcon";
import MessageIcon from "./icons/MessageIcon";
import ArchiveIcon from "./icons/ArchiveIcon";
import ProfileIcon from "./icons/ProfileIcon";
import FeedbackIcon from "./icons/FeedbackIcon";
import HelpIcon from "./icons/HelpIcon";
import {useTranslation} from "react-i18next";

const Sidebar = ({props, handleDrawerToggle, handleNavigationChange, selectedIndex, drawerWidth, mobileOpen}) => {

    const {t} = useTranslation();
    const { window } = props;

    const drawer = (
        <div>
            {!mobileOpen && <Toolbar />}
            <List sx={{marginTop: '10px', paddingLeft: '10px'}}>
                {/*<ListItem key="Home page" disablePadding>*/}
                {/*    <ListItemButton*/}
                {/*        selected={selectedIndex === 0}*/}
                {/*        onClick={(event) => handleNavigationChange(event, 0)}*/}
                {/*        sx={{borderRadius : '7px'}}*/}
                {/*    >*/}
                {/*        <ListItemIcon>*/}
                {/*            <HomeIcon style={{width: 20, marginLeft: '5px'}} selected={selectedIndex === 0} />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary={t('sidebar.homePage')} />*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}
                <ListItem key="Family tree" disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 0}
                        onClick={(event) => handleNavigationChange(event, 0)}
                        sx={{borderRadius : '7px'}}
                    >
                        <ListItemIcon>
                            <TreeIcon style={{width: 20, marginLeft: '5px'}} selected={selectedIndex === 0} />
                        </ListItemIcon>
                        <ListItemText primary={t('sidebar.familyTree')} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Feedback" disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 1}
                        onClick={(event) => handleNavigationChange(event, 1)}
                        sx={{borderRadius : '7px'}}
                    >
                        <ListItemIcon>
                            <FeedbackIcon style={{width: 20, marginLeft: '5px'}} selected={selectedIndex === 1} />
                        </ListItemIcon>
                        <ListItemText primary={t('sidebar.feedback')} />
                    </ListItemButton>
                </ListItem>
                {/*<ListItem key="Help" disablePadding>*/}
                {/*    <ListItemButton*/}
                {/*        selected={selectedIndex === 3}*/}
                {/*        onClick={(event) => handleNavigationChange(event, 3)}*/}
                {/*        sx={{borderRadius : '7px'}}*/}
                {/*    >*/}
                {/*        <ListItemIcon>*/}
                {/*            <HelpIcon style={{width: 20, marginLeft: '5px'}} selected={selectedIndex === 3} />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary="Help" />*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}
                {/*<ListItem key="Family tree" disablePadding>*/}
                {/*    <ListItemButton*/}
                {/*        selected={selectedIndex === 1}*/}
                {/*        onClick={(event) => handleNavigationChange(event, 1)}*/}
                {/*        sx={{borderRadius : '7px'}}*/}
                {/*    >*/}
                {/*        <ListItemIcon>*/}
                {/*            <TreeIcon style={{width: 20, marginLeft: '5px'}} selected={selectedIndex === 1} />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary="Family tree" />*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}
                {/*<ListItem key="My family" disablePadding>*/}
                {/*    <ListItemButton*/}
                {/*        selected={selectedIndex === 2}*/}
                {/*        onClick={(event) => handleNavigationChange(event, 2)}*/}
                {/*        sx={{borderRadius : '7px'}}*/}
                {/*    >*/}
                {/*        <ListItemIcon>*/}
                {/*            <FamilyIcon style={{width: 20, marginLeft: '5px'}} selected={selectedIndex === 2} />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary="My family" />*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}
                {/*<ListItem key="Messages" disablePadding>*/}
                {/*    <ListItemButton*/}
                {/*        selected={selectedIndex === 3}*/}
                {/*        onClick={(event) => handleNavigationChange(event, 3)}*/}
                {/*        sx={{borderRadius : '7px'}}*/}
                {/*    >*/}
                {/*        <ListItemIcon>*/}
                {/*            <MessageIcon style={{width: 20, marginLeft: '5px'}} selected={selectedIndex === 3} />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary="Messages" />*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}
                {/*<ListItem key="Archive" disablePadding>*/}
                {/*    <ListItemButton*/}
                {/*        selected={selectedIndex === 4}*/}
                {/*        onClick={(event) => handleNavigationChange(event, 4)}*/}
                {/*        sx={{borderRadius : '7px'}}*/}
                {/*    >*/}
                {/*        <ListItemIcon>*/}
                {/*            <ArchiveIcon style={{width: 20, marginLeft: '5px'}} selected={selectedIndex === 4} />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary="Archive" />*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box
            component="nav"
            position="fixed"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="navigation"

        >
            <Drawer // mobile version
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer // standard version
                variant="permanent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, border: 'none' }
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    )
}

export default Sidebar