import {
    AppBar,
    Box,
    MenuItem,
    Toolbar,
    Typography,
    styled,
    FormControl, Select, Link
} from '@mui/material'
import React, {useState} from 'react'
import Logo from "../LogoBeta";
import {useTranslation} from "react-i18next";

const StyledToolbar = styled(Toolbar)({
    display: "flex",
    backgroundColor: "white",
    justifyContent: "space-between",
    color: "#0F0F0F"
})

const LanguageSelect = styled(Box)(({theme}) => ({
    display: 'none',
    alignItems: 'right',
    marginLeft: 'auto',
    minWidth: 120,
    gap: '20px',
    [theme.breakpoints.up("sm")]:{
        display: 'flex'
    }
}))

const LogInSignUpBox = styled(Box)(({theme}) => ({
    display: 'none',
    alignItems: 'center',
    paddingLeft: '40px',
    gap: '5px',
    [theme.breakpoints.up("sm")]:{
        display: 'flex'
    }
}))



const Navbar = () => {

    const {t, i18n} = useTranslation();

    const [language, setLanguage] = useState(i18n.resolvedLanguage);
    const handleChange = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    return (
    <AppBar position='static' sx={{boxShadow: 'none'}}>
        <StyledToolbar>
            <Logo style={{display: "block", width: 130}}  />
            <LanguageSelect>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                        id="language-select"
                        value={language}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'Language' }}
                    >
                        <MenuItem value={'en'}>English</MenuItem>
                        <MenuItem value={'bg'}>български</MenuItem>
                    </Select>
                </FormControl>
            </LanguageSelect>
            <LogInSignUpBox>
                <Link href="/signin" underline="hover">
                    {t('landingPage.navbar.signIn')}
                </Link>
                <Typography>{t('landingPage.navbar.or')}</Typography>
                <Link href="/signup" underline="hover">
                    {t('landingPage.navbar.signUp')}
                </Link>
            </LogInSignUpBox>
        </StyledToolbar>
    </AppBar>
    )
}

export default Navbar