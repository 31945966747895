import React from 'react'
import { Box, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";

const VerificationMailSent = () => {
    const {t} = useTranslation();

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box mt="50px">
                    <img src="/assets/images/reading-mail.svg"
                         alt="Read your mail"
                         width="275" />
                </Box>
                <Typography component="h1" variant="h5" mt="40px">
                    {t('verificationMail.title')}
                </Typography>
                <Box textAlign={"center"}>
                    <Typography mt="10px">
                        {t('verificationMail.text')}
                    </Typography>
                </Box>
            </Box>
        </Container>
    )
}

export default VerificationMailSent