import React from 'react'

const BigViewIcon = (props) => {
    return (
        <img src={props.selected ? "/assets/icons/big-view.svg" : "/assets/icons/big-view.svg"}
             alt="Edit"
             style={props.style}
        />
    )
}

export default BigViewIcon