import React from 'react'
import {
    Avatar,
    Box,
    Card,
    CardActionArea, Icon,
    Stack, Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import { ReactComponent as UnlockedIcon } from "./unlocked-button-icon.svg";
import {famtrailApi} from "../../services/FamtrailApi";
import {handleLogError} from "../../services/Helpers";
import {useTranslation} from "react-i18next";

const NotificationBox = (props) => {

    const theme = useTheme();

    const {t} = useTranslation();

    const calculateTime = (createdOn) => {
        const today = new Date();
        const created = new Date(createdOn);
        const diffMs = (today - created); // milliseconds between now & created
        const diffMins = Math.floor((diffMs/1000)/60);
        const diffHours = Math.floor(diffMins/60);
        const diffDays = Math.floor(diffHours/24);
        const diffMonths = Math.floor(diffDays/30);
        const diffYears = Math.floor(diffMonths/12);
        if (diffYears > 0) {
            if (diffYears === 1) return 1 + t('notificationBox.yearAgo');
            return diffYears + t('notificationBox.yearsAgo');
        } else if (diffMonths > 0) {
            if (diffMonths === 1) return 1 + t('notificationBox.monthAgo');
            return diffMonths + t('notificationBox.monthsAgo');
        } else if (diffDays > 0) {
            if (diffDays === 1) return 1 + t('notificationBox.dayAgo');
            return diffDays + t('notificationBox.daysAgo');
        } else if (diffHours > 0) {
            if (diffHours === 1) return 1 + t('notificationBox.hourAgo');
            return diffHours + t('notificationBox.hoursAgo');
        } else {
            if (diffMins === 1) return 1 + t('notificationBox.minuteAgo');
            return diffMins + t('notificationBox.minutesAgo');
        }
    }

    const [accessGrantedClicked, setAccessGrantedClicked] = React.useState(false);

    const handleCardClickLeft = (event) => {
        props.handleSetSelected(props.initiatorMemberId);
    };

    const handleCardClick = async (event) => {
        try {
            if (props.seen === false) await famtrailApi.markNotificationAsSeen(props.user, props.id);
            props.reloadNotifications();
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    };

    const handleCardClickRight = (event) => {
        props.handleSetSelected(props.targetMemberId);
    };

    const handleGrantAccess= async (event) => {
        setAccessGrantedClicked(true);
        const data = {accessLevel: 'VIEW', userMemberId: props.initiatorMemberId};
        try {
            await famtrailApi.changeAccessRights(props.user, props.targetMemberId, data);
        } catch (error) {
            handleLogError(error);
        } finally {

        }
    };

    return (
        <Box sx={{ minWidth: 275 }}>

            <Card
                variant="outlined"
                onClick={handleCardClick}
                style={{backgroundColor: props.seen === false ? theme.palette.primary.subtle : 'none', marginTop: "-1px"}}
            >
                <CardActionArea style={{marginTop: "0px", padding: "10px"}}>
                    <Stack
                        direction={'row'}
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {props.initiatorProfilePicture == null &&
                            <Avatar
                                sx={{
                                    width: 64,
                                    height: 64,
                                    ...(props.initiatorGender === 'MALE' && {
                                        bgcolor: '#2681A3',
                                    }),
                                    ...(props.initiatorGender !== 'MALE' && {
                                        bgcolor: '#D8416F',
                                    })
                                }}
                                onClick={handleCardClickLeft}
                            >
                                {props.initiatorInitails}
                            </Avatar>
                        }
                        {props.initiatorProfilePicture != null &&
                            <Avatar
                                sx={{ width: 64, height: 64 }}
                                src={props.initiatorProfilePicture}
                                onClick={handleCardClickLeft}
                            />
                        }
                        <Box>
                            {props.type === 'BIOGRAPHY_CHANGED' &&
                                <Typography>
                                    {
                                        props.initiatorFirstName + " " +
                                        props.initiatorLastName + " " +
                                        t('notificationBox.BIOGRAPHY_CHANGED') + " " +
                                        props.targetFirstName + " " +
                                        props.targetLastName
                                    }
                                </Typography>
                            }
                            {props.type === 'ACCESS_REQUESTED' &&
                                <Box>
                                    <Typography>
                                        {
                                            props.initiatorFirstName + " " +
                                            props.initiatorLastName + " " +
                                            t('notificationBox.ACCESS_REQUESTED') + " " +
                                            props.targetFirstName + " " +
                                            props.targetLastName
                                        }
                                    </Typography>
                                    {props.initiatorToTargetAccessLevel === 'PRIVATE' && accessGrantedClicked === false &&
                                        <Box mt={2} mb={2} display={"flex"} alignItems={"center"} justifyContent="center">
                                            <Tooltip title={t('notificationBox.grantAccess')}>
                                                <Icon component={UnlockedIcon}
                                                      sx={{transform: "scale(3)"}}
                                                      onClick={handleGrantAccess} />
                                            </Tooltip>
                                        </Box>
                                    }
                                </Box>
                            }
                            {props.type === 'ACCESS_GRANTED_EDIT' &&
                                <Typography>
                                    {
                                        props.initiatorFirstName + " " +
                                        props.initiatorLastName + " " +
                                        t('notificationBox.ACCESS_GRANTED_EDIT') + " " +
                                        props.targetFirstName + " " +
                                        props.targetLastName
                                    }
                                </Typography>
                            }
                            {props.type === 'ACCESS_GRANTED_VIEW' &&
                                <Typography>
                                    {
                                        props.initiatorFirstName + " " +
                                        props.initiatorLastName + " " +
                                        t('notificationBox.ACCESS_GRANTED_VIEW') + " " +
                                        props.targetFirstName + " " +
                                        props.targetLastName
                                    }
                                </Typography>
                            }
                            {props.type === 'ACCESS_REVOKED' &&
                                <Typography>
                                    {
                                        props.initiatorFirstName + " " +
                                        props.initiatorLastName + " " +
                                        t('notificationBox.ACCESS_REVOKED') + " " +
                                        props.targetFirstName + " " +
                                        props.targetLastName
                                    }
                                </Typography>
                            }
                            {props.type === 'USER_JOINED' &&
                                <Typography>
                                    {
                                        props.initiatorFirstName + " " +
                                        props.initiatorLastName + " " +
                                        t('notificationBox.USER_JOINED') + " " +
                                        props.targetFirstName + " " +
                                        props.targetLastName
                                    }
                                </Typography>
                            }
                            {props.type === 'MEMBER_ADDED' &&
                                <Typography>
                                    {
                                        props.initiatorFirstName + " " +
                                        props.initiatorLastName + " " +
                                        t('notificationBox.MEMBER_ADDED') + " " +
                                        props.targetFirstName + " " +
                                        props.targetLastName
                                    }
                                </Typography>
                            }
                            {props.type === 'PHOTO_ADDED' &&
                                <Typography>
                                    {
                                        props.initiatorFirstName + " " +
                                        props.initiatorLastName + " " +
                                        t('notificationBox.PHOTO_ADDED') + " " +
                                        props.targetFirstName + " " +
                                        props.targetLastName
                                    }
                                </Typography>
                            }
                            <Typography variant="body2" color="text.secondary" mt={1}>
                                {calculateTime(props.createdOn)}
                            </Typography>
                        </Box>
                        {props.photoPath == null && props.targetProfilePicture == null &&
                            <Avatar
                                sx={{
                                    width: 64,
                                    height: 64,
                                    ...(props.targetGender === 'MALE' && {
                                        bgcolor: '#2681A3',
                                    }),
                                    ...(props.targetGender !== 'MALE' && {
                                        bgcolor: '#D8416F',
                                    })
                                }}
                                onClick={handleCardClickRight}
                            >
                                {props.targetInitails}
                            </Avatar>
                        }
                        {props.photoPath == null && props.targetProfilePicture != null &&
                            <Avatar
                                sx={{ width: 64, height: 64 }}
                                src={props.targetProfilePicture}
                                onClick={handleCardClickRight}
                            />
                        }
                        {props.photoPath != null &&
                            <img width={64} height={64}
                                // sx={{ width: 64, height: 64 }}
                                style={{borderRadius: 5}}
                                src={props.photoPath}
                                onClick={handleCardClickRight}
                            />
                        }
                    </Stack>
                </CardActionArea>
            </Card>
        </Box>
    )
}

export default NotificationBox