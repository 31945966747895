import * as React from "react";
import {Box, Divider} from "@mui/material";
import {Stage, Layer, Rect, Circle, Text, Group} from 'react-konva';
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Drawer from "@mui/material/Drawer";
import AddFatherIcon from "./icons/AddFatherIcon";
import AddMotherIcon from "./icons/AddMotherIcon";

function TreeContent(props) {

    const COLOR_MALE = "#26A399";
    const COLOR_FEMALE = "#A33696";
    const COLOR_SELECTED = "#F09E1F";
    const COLOR_SELECTED_BOX = "#FFF6F2";

    // the family tree object
    let tree = {
        members: [
            {
                id: "1",
                selected: true,
                firstName: "Filip",
                lastName: "Jelev",
                middleName: null,
                initials: "FJ",
                gender: "MALE",
                birthDate: "2020-01-08",
                deathDate: null,
                parents: [
                    "2"
                ],
                children: null
            },
            {
                id: "2",
                selected: false,
                firstName: "Nikolay",
                lastName: "Stoyanov",
                middleName: null,
                initials: "NS",
                gender: "MALE",
                birthDate: "1952-06-10",
                deathDate: null,
                parents: null,
                children: [
                    "1"
                ]
            }
        ]
    };

    // Calculate canvas dimensions. They change between devices and on resize
    const [dimensions, setDimensions] = React.useState({
        height: window.innerWidth < 900 ? (window.innerWidth < 600 ? window.innerHeight - 110 : window.innerHeight - 120) : window.innerHeight - 130,
        width: window.innerWidth < 900 ? (window.innerWidth < 600 ? window.innerWidth - 50 : window.innerWidth - 55) : window.innerWidth - 300
    })

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerWidth < 900 ? (window.innerWidth < 600 ? window.innerHeight - 110 : window.innerHeight - 120) : window.innerHeight - 130,
                width: window.innerWidth < 900 ? (window.innerWidth < 600 ? window.innerWidth - 50 : window.innerWidth - 55) : window.innerWidth - 300
            })
        }
        window.addEventListener('resize', handleResize)
        return _ => {window.removeEventListener('resize', handleResize)}
    })

    // global offset
    const [offsetX, setOffsetX] = React.useState(0);
    const [offsetY, setOffsetY] = React.useState(0);

    // action button is hovered
    const [hovered, setHovered] = React.useState(false);

    // this is the menu that pops up from bottom and allows to add/edit
    const [actionBarOpen, setActionBarOpen] = React.useState(false);

    // tree states
    //const [selected, setSelected] = React.useState("1");


    // dragging applies to everything
    const handleDrag = (e) => {
        setOffsetX(e.target.x() * -1);
        setOffsetY(e.target.y() * -1);
    };

    const drawControls = () => {

    };

    const drawMembers = () => {
        // draw selected member in the middle of the canvas first
        const selectedMember = tree.members.find(x => x.selected === true);
        const selectedMemberX = dimensions.width/2 - 96;
        const selectedMemberY = dimensions.height/2 - 50;
        return drawMember(
            selectedMember.id,
            selectedMemberX,
            selectedMemberY,
            selectedMember.gender === "MALE" ? COLOR_MALE : COLOR_FEMALE,
            true,
            selectedMember.initials,
            selectedMember.firstName,
            selectedMember.birthDate
        )
        // let x = 0;
        // let y = 0;
        // let iter = -1;
        // return tree.members.map((member) => {
        //         // Positioning logic ////////////////////////
        //         iter++;
        //         return drawMember(
        //             member.id,
        //             x + (iter * 200),
        //             y,
        //             member.selected === true ? COLOR_SELECTED: member.gender === "MALE" ? COLOR_MALE : COLOR_FEMALE,
        //             false,
        //             member.initials,
        //             member.firstName,
        //             member.birthDate
        //         )
        //     }
        // );
    };

    const drawMember = (key, globalX, globalY, color, selected, initials, name, birthDate) => {
        return (
            <Group key={key}>
                <Rect offsetX={offsetX} offsetY={offsetY} width={192} height={92} x={globalX} y={globalY + 48}
                      cornerRadius={[0, 0, 5, 5]} strokeWidth={1} stroke="#0F0F0F" fill={selected ? COLOR_SELECTED_BOX : "white"}/>
                <Rect offsetX={offsetX} offsetY={offsetY} width={192} height={7} x={globalX} y={globalY + 42}
                      cornerRadius={[5, 5, 0, 0]} strokeWidth={1} stroke={selected ? COLOR_SELECTED : color} fill={selected ? COLOR_SELECTED : color}/>
                <Circle offsetX={offsetX} offsetY={offsetY} x={globalX + 96} y={globalY + 35} radius={35} fill="white" />
                <Circle offsetX={offsetX} offsetY={offsetY} x={globalX + 96} y={globalY + 35} radius={31} fill={selected ? COLOR_SELECTED : color} />
                <Text offsetX={offsetX} offsetY={offsetY} x={globalX} y={globalY + 26} width={192} text={initials} fill={selected ? COLOR_SELECTED_BOX : "white"} fontSize={24} align="center" />
                <Text offsetX={offsetX} offsetY={offsetY} x={globalX} y={globalY + 73} width={192} text={name} fontSize={14} align="center" />
                <Text offsetX={offsetX} offsetY={offsetY} x={globalX} y={globalY + 95} width={192} text={birthDate + " - "} fontSize={10} align="center" />
            </Group>
        );
    };

    const drawActionButton = () => {
        const selectedMemberX = dimensions.width/2 - 96;
        const selectedMemberY = dimensions.height/2 - 50;
        return (
            <Group onClick={handleActionButtonClick} onTap={handleActionButtonClick} onMouseOver={handleActionButtonHover} onMouseLeave={handleActionButtonUnhover} >
                <Circle offsetX={offsetX} offsetY={offsetY} x={selectedMemberX + 96} y={selectedMemberY + 121} radius={16} fill={ hovered === true ? "#F5F5F5" : "white"} />
                <Circle offsetX={offsetX} offsetY={offsetY} x={selectedMemberX + 90} y={selectedMemberY + 121} radius={2} fill="#0F0F0F" />
                <Circle offsetX={offsetX} offsetY={offsetY} x={selectedMemberX + 96} y={selectedMemberY + 121} radius={2} fill="#0F0F0F" />
                <Circle offsetX={offsetX} offsetY={offsetY} x={selectedMemberX + 102} y={selectedMemberY + 121} radius={2} fill="#0F0F0F" />
            </Group>
        );
    }

    const handleActionButtonHover = (e) => {
        setHovered(true);
    }

    const handleActionButtonUnhover = (e) => {
        setHovered(false);
    }

    const handleActionButtonClick = (e) => {
        setActionBarOpen(true);
        console.log("Clicked!")
    }

    const closeActionBar = () => {
        setActionBarOpen(false);
    }

    const actionBarList = (
        <Box
            role="presentation"
            onClick={() => setActionBarOpen(true)}
            onKeyDown={() => setActionBarOpen(true)}
        >
            <List>
                <ListItem key="add-father" disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <AddFatherIcon style={{height: 20}}  />
                        </ListItemIcon>
                        <ListItemText primary="Add father" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="add-mother" disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <AddMotherIcon style={{height: 20}}  />
                        </ListItemIcon>
                        <ListItemText primary="Add mother" />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box
             sx={{
                 display: 'flex',
                 flexDirection: 'column',
                 minHeight: 'calc(100vh - 120px)',
                 color: 'white'
             }}
        >
            <Stage width={dimensions.width} height={dimensions.height}>
                <Layer>
                    {drawMembers()}
                    {/*this is a scrollable transparent rectangle drown on top of the canvas*/}
                    <Rect width={dimensions.width} height={dimensions.height} opacity={0.0} draggable="true" fill="gray" onDragMove={handleDrag} />
                    {drawActionButton(false)}
                    {drawControls()}
                </Layer>
            </Stage>
            <Drawer
                variant="temporary"
                anchor={"bottom"}
                open={actionBarOpen}
                onClose={closeActionBar}
            >
                {actionBarList}
            </Drawer>
        </Box>
    );
}

export default TreeContent;