import React from 'react'

const EditIcon = (props) => {
    return (
        <img src={"/assets/icons/edit.svg"}
             alt="edit"
             style={props.style}
        />
    )
}

export default EditIcon