import React from 'react'
import Navbar from "./landingpage/Navbar";
import Content from "./landingpage/Content";
import Footer from "./landingpage/Footer";
import {Box} from "@mui/material";
import CookieConsent from "./CookieConsent";

const LandingPage = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Navbar />
            <Content />
            <Footer />
            <CookieConsent />
        </Box>
    )
}

export default LandingPage