const prod = {
    url: {
        API_BASE_URL: 'https://api.famtrail.com',
    }
}

const dev = {
    url: {
        API_BASE_URL: 'http://localhost:8888'
    }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod